import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import "../general.css";
import "./data-insight-details-modal.component.css";

import resultPageIdReferenceData from "../resultPageIdReference";
import clinicianIdReference from "../clinicianIdReference";

const DataInsightDetailsModal = (props) => {
  const {
    insightObj,
    insightsFilter,
    showDataInsightDetailsModal,
    setShowDataInsightDetailsModal,
    selectedDataInsightRespondentsTotal,
    selectedDataInsightSortedItems,
    selectedDataInsightTopItems,
    blurBackground,
    insightsRationale,
    filteredInsightsRationale,
    setFilteredInsightsRationale,
    // The below 4 props will likely be removed once we implement a back-end + database
    insightsRatings,
    setInsightsRatings,
    rationaleRatings,
    setRationaleRatings,
  } = props;

  // **************************
  // ***DETAILS MODAL SCROLL STATE
  // **************************
  const [detailsModalScrollTop, setDetailsModalScrollTop] = useState(true);

  // EFFECT
  // if the detailsModalScrollTop state is true, then
  // scroll the data-insight-details-modal window to the top
  useEffect(() => {
    const modalWindow = document.querySelector(
      ".data-insight-details-modal-container"
    );
    if (detailsModalScrollTop === true) modalWindow.scrollTo(0, 0);
  });

  // EFFECT
  // if the showDataInsightDetailsModal state is false
  // then reset the detailsModalScrollTop state to true
  useEffect(() => {
    if (showDataInsightDetailsModal === false) setDetailsModalScrollTop(true);
  }, [showDataInsightDetailsModal]);

  // EFFECT
  // blur the background when this component mounts only if
  // the showDataInsightDetailsModal property in props is true
  useEffect(() => {
    if (showDataInsightDetailsModal === true) {
      blurBackground("blurOn");
    }
  }, [showDataInsightDetailsModal, blurBackground]);

  // HANDLER Function for menu close button
  const handleModalCloseBtn = () => {
    setShowDataInsightDetailsModal(false);
    blurBackground("blurOff");
  };

  // HANDLER FUNCTION for ratings-btn clicks
  const handleInsightRatingBtnClick = (e, btnClicked) => {
    // select the clicked ratings btn
    const ratingBtn = e.target.closest(
      ".data-insight-details-modal-ratings-btn"
    );
    // toggle the clicked-ratings-btn-class
    // use setTimeout to animate a 'jump' when it is clicked
    ratingBtn.classList.toggle("details-modal-clicked-ratings-btn");
    setTimeout(() => {
      ratingBtn.classList.toggle("details-modal-clicked-ratings-btn");
    }, 200);
    // ***UPDATING STATE***
    // create a shallow copy of the previous insightsRatings obj and
    // update the property with the key of the insightId that is associated with
    // the insight of the data-insight-card component the user is currently interacting
    // with
    setInsightsRatings((prevInsightsRatings) => {
      const newInsightsRatings = { ...prevInsightsRatings };
      newInsightsRatings[insightObj.insightId] = btnClicked;
      return newInsightsRatings;
    });
    // PLACEHOLDER FOR API ENDPOINT to add to record in database
    // of total 'likes' for this insight, and from which specialty and
    // other high-level identifiers
  };

  // INSIGHTS RATIONALE FUNCTIONS

  // HANDLER FUNCTION
  // this function is called when a user clicks on 1 of the options
  // in the drop-down menu in rationale section. The rationale at the
  // data-insight-details-modeul component level can only have 1 active
  // filter at any given time
  const handleRationaleFilterMenuClick = (e) => {
    if (e.target.value.toLowerCase() === "all") {
      setFilteredInsightsRationale(insightsRationale);
    }
    if (e.target.value.toLowerCase() !== "all") {
      const newFilteredInsightsRationale = insightsRationale.filter(
        (rationaleItem) => {
          return rationaleItem.item === e.target.value ? rationaleItem : null;
        }
      );
      setDetailsModalScrollTop(false);
      setFilteredInsightsRationale(newFilteredInsightsRationale);
    }
  };

  // HANDLER FUNCTION for ratings-btn clicks in INSIGHTS RATIONALE SECTION
  const handleRationaleRatingBtnClick = (
    e,
    insightId,
    itemId,
    clinicianId,
    btnClicked
  ) => {
    // select the clicked ratings btn
    const ratingBtn = e.target.closest(
      ".data-insight-details-modal-rationale-ratings-btn"
    );
    // toggle the clicked-ratings-btn-class
    // use setTimeout to animate a 'jump' when it is clicked
    ratingBtn.classList.toggle("details-modal-rationale-clicked-ratings-btn");
    setTimeout(() => {
      ratingBtn.classList.toggle("details-modal-rationale-clicked-ratings-btn");
    }, 200);
    // ***UPDATING STATE***
    // create a shallow copy of the previous insightsRatings obj and
    // update the property with the key of the insightId that is associated with
    // the insight of the data-insight-card component the user is currently interacting
    // with
    setRationaleRatings((prevRationaleRatings) => {
      const newRationaleRatings = { ...prevRationaleRatings };
      newRationaleRatings[insightId][itemId + clinicianId] = btnClicked;
      return newRationaleRatings;
    });
    // finally set detailsModalScrollToTop to false so that this component does not scroll
    // to top upon re-rendering after the user clicks the rationale ratings btn
    setDetailsModalScrollTop(false);
    // PLACEHOLDER FOR API ENDPOINT to add to record in database
    // of total 'likes' for this rationale, and from which specialty and
    // other high-level identifiers
  };

  return showDataInsightDetailsModal === true ? (
    <div className="data-insight-details-modal-container">
      <button
        className="data-insight-details-modal-close-btn"
        type="button"
        onClick={handleModalCloseBtn}
      >
        <img
          className="data-insight-details-modal-close-btn-icon"
          src={require("../img/close.png")}
          alt="modal close button"
        />
      </button>
      <div className="data-insight-details-modal-ratings-container">
        <button
          className="data-insight-details-modal-ratings-btn details-modal-helpful-btn"
          type="button"
          onClick={(e) => {
            handleInsightRatingBtnClick(e, "helpful");
          }}
        >
          <img
            className="data-insight-details-modal-ratings-icon details-modal-helpful-icon"
            src={require("../img/helpful-icon.png")}
            alt="helpful rating icon"
          ></img>
          <p
            className={
              insightsRatings[insightObj.insightId] === "helpful"
                ? "data-insight-details-modal-ratings-label details-modal-clicked-label"
                : "data-insight-details-modal-ratings-label"
            }
          >
            Helpful!
          </p>
        </button>
        <button
          className="data-insight-details-modal-ratings-btn details-modal-not-helpful-btn"
          type="button"
          onClick={(e) => {
            handleInsightRatingBtnClick(e, "notHelpful");
          }}
        >
          <img
            className="data-insight-details-modal-ratings-icon details-modal-not-helpful-icon"
            src={require("../img/not-helpful-icon.png")}
            alt="not helpful rating icon"
          ></img>
          <p
            className={
              insightsRatings[insightObj.insightId] === "notHelpful"
                ? "data-insight-details-modal-ratings-label details-modal-clicked-label"
                : "data-insight-details-modal-ratings-label"
            }
          >
            Not helpful
          </p>
        </button>
      </div>
      <h1 className="data-insight-details-modal-title">
        {insightObj.insightName}
      </h1>
      <div className="data-insight-details-modal-summary">
        Your peers {insightObj.insight.insightIntro}{" "}
        <span className="data-insight-details-modal-summary-top-items">
          {selectedDataInsightTopItems.map((item, index) => {
            if (
              index !== selectedDataInsightTopItems.length - 1 &&
              selectedDataInsightTopItems.length > 2
            )
              return `${item}, `;
            if (
              index !== selectedDataInsightTopItems.length - 1 &&
              selectedDataInsightTopItems.length <= 2
            )
              return `${item} `;
            return `and ${item}`;
          })}
        </span>{" "}
        {insightObj.insight.insightEnding}
      </div>
      <div className="data-insight-details-modal-horizontal-line"></div>
      <div className="data-insight-details-modal-data-container">
        <p className="data-insight-details-modal-data-description">
          {insightObj.dataDescription}
          <span className="data-insight-details-modal-data-description-respondent-count-highlight">
            {" "}
            {"(n="}
            {selectedDataInsightRespondentsTotal}
            {")"}
          </span>
        </p>
        <div className="data-insight-details-modal-data-visual-container">
          {insightObj.dataPresentation === "circles" ? (
            selectedDataInsightSortedItems.map((dataItem, index) => {
              const roundedTotalRespondentRating = Math.floor(
                dataItem.calculatedDataPointAvg
              );
              const fullCirclesArray = [];
              for (let i = 0; i < roundedTotalRespondentRating; i++) {
                fullCirclesArray.push("circle");
              }
              const emptyCirclesArray = [];
              for (let i = 0; i < 10 - roundedTotalRespondentRating; i++) {
                emptyCirclesArray.push("circle");
              }
              return (
                <div
                  key={dataItem.item + index}
                  className="data-insight-details-modal-data-visual-row-circles"
                >
                  <Link
                    className="data-insight-details-modal-data-label-circles"
                    to={``}
                  >
                    {resultPageIdReferenceData[dataItem.item]}
                  </Link>
                  <div
                    key={dataItem.item + index}
                    className="data-insight-details-modal-data-visualization-circles"
                  >
                    {fullCirclesArray.map((circle, circleIndex) => (
                      <div
                        key={dataItem.item + circleIndex}
                        className="data-insight-details-modal-data-visualization-circle-full"
                      ></div>
                    ))}
                    {emptyCirclesArray.map((circle, circleIndex) => (
                      <div
                        key={dataItem.item + circleIndex}
                        className="data-insight-details-modal-data-visualization-circle-empty"
                      ></div>
                    ))}
                  </div>
                  <div
                    key={dataItem + index}
                    className="data-insight-details-modal-data-visualization-number-label"
                  >
                    {/* {dataItem.calculatedDataPointAvg} / 10 */}
                    {/* TEMPORARY FOR MVP */}
                    {dataItem.calculatedDataPointAvg * 10}
                    {"%"}
                  </div>
                </div>
              );
            })
          ) : (
            <div className="data-insight-details-modal-data-number">
              {insightObj.dataNumber}
            </div>
          )}
        </div>
      </div>
      <div className="data-insight-details-modal-survey-question-container">
        <h2 className="data-insight-details-modal-survey-question-header data-insight-details-modal-subhead">
          Survey Question
        </h2>
        <div className="data-insight-details-modal-survey-intro">
          {insightObj.surveyQuestion.intro}
        </div>
        <div className="data-insight-details-modal-survey-question">
          {insightObj.surveyQuestion.question}
        </div>
      </div>
      <div className="data-insight-details-modal-rationale-container">
        <h2 className="data-insight-details-modal-rationale-header data-insight-details-modal-subhead">
          Rationale
        </h2>
        <select
          className="data-insight-details-modal-rationale-drop-down-menu"
          onChange={handleRationaleFilterMenuClick}
          defaultValue={insightsFilter.drugOrDiseaseId}
        >
          <option key={"all"} value={"all"}>
            ALL
          </option>
          {selectedDataInsightSortedItems
            .slice()
            .sort((a, b) =>
              resultPageIdReferenceData[a.item].localeCompare(
                resultPageIdReferenceData[b.item]
              )
            )
            .map((dataItem, index) => {
              return (
                <option key={index} value={dataItem.item}>
                  {resultPageIdReferenceData[dataItem.item]}
                </option>
              );
            })}
        </select>
        <ul className="data-insights-details-modal-rationale-list-container">
          {filteredInsightsRationale.map((rationale, index) => {
            return (
              <li
                className="data-insights-details-modal-rationale-list-item"
                key={index}
              >
                <div className="data-insights-details-modal-rationale-item-container">
                  <div className="data-insights-details-modal-rationale-intro-container">
                    <div className="data-insights-details-modal-rationale-clinician-profile-container">
                      <figure className="data-insights-detail-modal-rationale-clinician-profile-pic-container">
                        <img
                          className="data-insights-detail-modal-rationale-clinician-profile-pic"
                          src={require("../img/doctor-profile.png")}
                          alt="doctor profile"
                        ></img>
                      </figure>
                      <div className="data-insights-detail-modal-rationale-clinician-name-container">
                        <h3 className="data-insights-detail-modal-rationale-clinician-name">
                          {
                            clinicianIdReference[rationale.clinicianId]
                              .firstName
                          }{" "}
                          {clinicianIdReference[rationale.clinicianId].lastName}
                          ,{" "}
                          {
                            clinicianIdReference[rationale.clinicianId]
                              .credentials
                          }
                        </h3>
                        <p className="data-insights-detail-modal-rationale-clinician-specialty">
                          {
                            clinicianIdReference[rationale.clinicianId]
                              .specialty
                          }
                        </p>
                        <p className="data-insights-detail-modal-rationale-clinician-institution">
                          {
                            clinicianIdReference[rationale.clinicianId]
                              .institution
                          }
                        </p>
                      </div>
                    </div>
                    <div className="data-insight-details-modal-rationale-ratings-container">
                      <button
                        className="data-insight-details-modal-rationale-ratings-btn details-modal-rationale-helpful-btn"
                        type="button"
                        onClick={(e) => {
                          handleRationaleRatingBtnClick(
                            e,
                            insightObj.insightId,
                            rationale.item,
                            rationale.clinicianId,
                            "helpful"
                          );
                        }}
                      >
                        <img
                          className="data-insight-details-modal-rationale-ratings-icon details-modal-rationale-helpful-icon"
                          src={require("../img/helpful-icon.png")}
                          alt="helpful rating icon"
                        ></img>
                        <p
                          className={
                            rationaleRatings[insightObj.insightId][
                              rationale.item + rationale.clinicianId
                            ] === "helpful"
                              ? "data-insight-details-modal-rationale-ratings-label details-modal-rationale-clicked-label"
                              : "data-insight-details-modal-rationale-ratings-label"
                          }
                        >
                          Helpful!
                        </p>
                      </button>
                      <button
                        className="data-insight-details-modal-rationale-ratings-btn details-modal-rationale-not-helpful-btn"
                        type="button"
                        onClick={(e) => {
                          handleRationaleRatingBtnClick(
                            e,
                            insightObj.insightId,
                            rationale.item,
                            rationale.clinicianId,
                            "notHelpful"
                          );
                        }}
                      >
                        <img
                          className="data-insight-details-modal-rationale-ratings-icon details-modal-rationale-not-helpful-icon"
                          src={require("../img/not-helpful-icon.png")}
                          alt="not helpful rating icon"
                        ></img>
                        <p
                          className={
                            rationaleRatings[insightObj.insightId][
                              rationale.item + rationale.clinicianId
                            ] === "notHelpful"
                              ? "data-insight-details-modal-rationale-ratings-label details-modal-rationale-clicked-label"
                              : "data-insight-details-modal-rationale-ratings-label"
                          }
                        >
                          Not helpful
                        </p>
                      </button>
                    </div>
                  </div>
                  <div className="data-insights-details-modal-rationale-selection-and-text-container">
                    <p className="data-insights-details-modal-rationale-selection">
                      {clinicianIdReference[rationale.clinicianId].firstName}{" "}
                      selected{" "}
                      <span className="rationale-selection-highlight">
                        {resultPageIdReferenceData[rationale.item]}
                      </span>
                    </p>
                    <h4 className="data-insights-details-modal-rationale-brief-intro">
                      My main rationale is...
                    </h4>
                    <p className="data-insights-details-modal-rationale-brief-text">
                      {rationale.rationaleBrief}
                    </p>
                    <h4 className="data-insights-details-modal-rationale-full-intro">
                      Additional notes:
                    </h4>
                    <p className="data-insights-details-modal-rationale-full-text">
                      {rationale.rationaleFull}
                    </p>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  ) : (
    <div className="data-insight-details-modal-container modal-hidden"></div>
  );
};

export default DataInsightDetailsModal;
