import drugAndDiseaseDataObj from "./drugAndDiseaseData";

const getResultPageIdReferenceData = () => {
  const resultPageIdReferenceList = {};
  const drugAndDiseaseObj = { ...drugAndDiseaseDataObj };
  const resultPageIdArray = Object.keys(drugAndDiseaseObj);

  resultPageIdArray.forEach((pageId) => {
    resultPageIdReferenceList[pageId] = `${
      drugAndDiseaseDataObj[pageId].type === "disease"
        ? `${
            drugAndDiseaseDataObj[pageId].diseaseSeverity
              ? `${drugAndDiseaseDataObj[pageId].diseaseSeverity} ${drugAndDiseaseDataObj[pageId].diseaseName}`
              : `${drugAndDiseaseDataObj[pageId].diseaseName}`
          }`
        : `${
            drugAndDiseaseDataObj[pageId].brandName
              ? `${drugAndDiseaseDataObj[pageId].brandName} (${drugAndDiseaseDataObj[pageId].genericName})`
              : `${drugAndDiseaseDataObj[pageId].genericName}`
          }`
    }`;
  });
  return resultPageIdReferenceList;
};

const resultPageIdReferenceData = getResultPageIdReferenceData();

export default resultPageIdReferenceData;
