import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import resultPageFullNameReferenceListData from "../resultPageNameReference";

import "./global-nav-search-bar.component.css";
import "../general.css";

const GlobalNavSearchBar = (props) => {
  // destructure props
  const { setScrollWaitList, setShowWaitListDemoBtn } = props;
  // ******************************
  // *** SEARCH RESULTS PREVIEW STATE***
  // ******************************
  // This state determines whether the search bar is showing the search results
  // preview window below the search bar in global nav section
  const [
    showGlobalNavSearchResultsPreview,
    setShowGlobalNavSearchResultsPreview,
  ] = useState(false);
  // EFFECT
  // dependency: showGlobalNavSearchResultsPreview state
  // based on the current showGlobalNavSearchResultsPreview state, it will either
  // show or hide the global-nav-search-results-preview window using 'global-nav-hidden' class

  // COMMENTING OUT - TEMPORARY FOR MVP
  // useEffect(() => {
  //   const globalNavSearchResultsPreview = document.querySelector(
  //     ".global-nav-search-results-preview"
  //   );
  //   const searchResultsPreview = document.querySelector(
  //     ".search-results-preview"
  //   );
  //   if (showGlobalNavSearchResultsPreview === false) {
  //     globalNavSearchResultsPreview.classList.add("global-nav-hidden");
  //   }
  //   if (showGlobalNavSearchResultsPreview === true) {
  //     globalNavSearchResultsPreview.classList.remove("global-nav-hidden");
  //   }
  //   // if the search-results-preview element is presesnt,
  //   // we also want to hide the search-results-preview window whenever we are
  //   // clicking into or away from the global-nav-search-results-preview
  //   if (searchResultsPreview) searchResultsPreview.classList.add("hidden");
  // }, [showGlobalNavSearchResultsPreview]);

  // conditionally render this component based on whether the
  // searchTerm state in App.js is empty

  // if the searchTerm is empty (user has not inputted anything yet) or
  // the user has deleted the search term entry entirely from the search bar,
  // then render a search input that,
  // return props.searchTerm === "" ? (
  //   // cannot be submitted using default form functionality
  //   <form
  //     onSubmit={(e) => {
  //       e.preventDefault();
  //     }}
  //     className={
  //       props.globalNavSearchBarShown === true
  //         ? "global-nav-search-bar-form"
  //         : "global-nav-search-bar-form global-nav-hidden"
  //     }
  //   >
  //     <input
  //       // when user focuses on the search input, then toggle the global-nav-search-results-preview
  //       // window so that it is shown (see function in this component above)
  //       onFocusCapture={() => setShowGlobalNavSearchResultsPreview(true)}
  //       // and when user 'removes' focus from the search input, toggle the search-results-preview
  //       // window back to hidden
  //       // use onBlur vs. onBlurCapture because of the event bubbling sequence
  //       // allows us to keep the search results preview window visible long enough
  //       // if user wants to click on one of the preview links
  //       onBlur={() => setShowGlobalNavSearchResultsPreview(false)}
  //       // onChange function provided by parent component
  //       onChange={props.updateSearchTerm}
  //       type="search"
  //       // conditional class for this particular search input - provided by
  //       // the parent component
  //       className="global-nav-search-input"
  //       size="30"
  //       placeholder="Enter a disease or drug"
  //       value={props.searchTerm}
  //       aria-label="Search for a disease state or drug"
  //     />
  //     <button className="global-nav-search-btn" type="button">
  //       Search
  //     </button>
  //     {/* and contains no drug/disease links in the search-results-preview
  //     window */}
  //     <div className="global-nav-search-results-preview global-nav-search-results-preview-position"></div>
  //   </form>
  // ) : (
  //   // alternatively, if there is a searchTerm and it is NOT empty, then render
  //   // a normal form with search input
  //   <form
  //     onSubmit={(e) => {
  //       e.preventDefault();
  //     }}
  //     className={
  //       props.globalNavSearchBarShown === true
  //         ? "global-nav-search-bar-form"
  //         : "global-nav-search-bar-form global-nav-hidden"
  //     }
  //   >
  //     <input
  //       onFocusCapture={() => setShowGlobalNavSearchResultsPreview(true)}
  //       onBlur={() => setShowGlobalNavSearchResultsPreview(false)}
  //       onChange={props.updateSearchTerm}
  //       type="search"
  //       className="global-nav-search-input"
  //       placeholder="Enter a disease or drug"
  //       value={props.searchTerm}
  //       aria-label="Search for a disease state or drug"
  //     />
  //     <Link
  //       className="global-nav-search-btn"
  //       to={`/demo/search-results-page`}
  //       onClick={() => {
  //         setShowGlobalNavSearchResultsPreview(false);
  //       }}
  //     >
  //       Search
  //       {/* <button
  //         onClick={() => {
  //           setShowGlobalNavSearchResultsPreview(false);
  //         }}
  //         className="global-nav-search-btn"
  //         type="submit"
  //       >
  //         Search
  //       </button> */}
  //     </Link>
  //     {props.searchResults.length ? (
  //       <div className="global-nav-search-results-preview global-nav-search-results-preview-position">
  //         {props.searchResults.map((result) => {
  //           return (
  //             <Link
  //               // this onFocus function keeps the searchResultsPreview window open just
  //               // long enough so that when you remove focus from the search input to
  //               // click on this link, the preview window will not disappear before you click the link
  //               onFocus={() => setShowGlobalNavSearchResultsPreview(true)}
  //               onClick={() => {
  //                 setShowGlobalNavSearchResultsPreview(false);
  //               }}
  //               key={resultPageFullNameReferenceListData[result]}
  //               className="global-nav-search-result-preview-item"
  //               // utilize the resultPageFullNameReferenceListData to look up respective
  //               // resultPageId to route to the result-page-component with the correct param
  //               to={`/demo/${resultPageFullNameReferenceListData[result]}`}
  //             >
  //               {result}
  //             </Link>
  //           );
  //         })}
  //       </div>
  //     ) : (
  //       <div className="global-nav-search-results-preview global-nav-search-results-preview-position">
  //         <p className="global-nav-search-results-preview-empty-text">
  //           Sorry, there are no matches for that search
  //         </p>
  //       </div>
  //     )}
  //   </form>
  // );
  // TEMPORARY WAIT LIST BUTTON FOR MVP
  return (
    <div className="global-nav-wait-list-btn-container">
      <Link
        className="global-nav-wait-list-btn"
        to={"/"}
        onClick={() => {
          // ensure the 'back to demo' btn appears in the wait list form
          setShowWaitListDemoBtn(true);
          // make sure the user is scrolled to the wait list section
          // of the landing page
          setScrollWaitList(true);
        }}
      >
        Join Waitlist
      </Link>
    </div>
  );
};

export default GlobalNavSearchBar;
