import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import "../general.css";
import "./about-data.component.css";

const AboutData = (props) => {
  const { setGlobalNavSearchBarShown } = props;
  useEffect(() => {
    setGlobalNavSearchBarShown(true);
  });
  return (
    <section className="section-more-about-data">
      <h1 className="more-about-heading-primary">More about our data</h1>
      <div className="container">
        <div className="grid grid--2-cols grid--center-v more-about-data-main-content">
          <img
            src={require("../img/data-icon.png")}
            className="more-about-main-icon"
            alt="icon with data graph"
          />
          <div className="more-about-data-text">
            With fast-changing treatment landscapes, we know it's helpful to
            understand how patients are being treated and how therapies are
            being used in the real world.
            <br />
            <br />
            We combine several data sources to give you insight into the drug
            therapy decisions of your peers and to provide additional background
            on those therapies.
          </div>
        </div>
      </div>
      <h2 className="more-about-heading-secondary">
        Our platform generates insights from:
      </h2>
      <div className="container grid grid--5-cols grid--1-row grid--center-v grid--center-h more-about-data-sources-container-1">
        <div className="data-group data-group-claims">
          <img
            src={require("../img/claims-data-icon.png")}
            className="data-group-icon"
            alt="claims data icon"
          />
          <p className="data-group-title">Claims Data</p>
        </div>
        <div className="data-group data-group-surveys">
          <img
            src={require("../img/survey-icon.png")}
            className="data-group-icon"
            alt="clinician surveys icon"
          />
          <p className="data-group-title">Clinician Surveys</p>
        </div>
        <div className="data-group data-group-profiles">
          <img
            src={require("../img/patient-profile-icon.png")}
            className="data-group-icon"
            alt="clinician-submitted cases icon"
          />
          <p className="data-group-title">Clinician-submitted Cases</p>
        </div>
      </div>
      <div className="container grid grid--4-cols grid--1-row grid--center-v grid--center-h more-about-data-sources-container-2">
        <div className="data-group data-group-labeling">
          <img
            src={require("../img/fda-labeling-icon.png")}
            className="data-group-icon"
            alt="FDA Labeling icon"
          />
          <p className="data-group-title">FDA Labeling</p>
        </div>
        <div className="data-group data-group-pubs">
          <img
            src={require("../img/clinical-trial-icon.png")}
            className="data-group-icon"
            alt="Clinical Trial Publications icon"
          />
          <p className="data-group-title">Clinical Trial Publications</p>
        </div>
      </div>
      <div className="back-to-home-page-btn-container">
        <Link className="back-to-home-page-btn" to="">
          Back to Home Page
        </Link>
      </div>
      <div className="flaticon-attribution">
        <a
          href="https://www.flaticon.com/free-icons/dashboard"
          className="flaticon-attribution-link"
          title="dashboard icons"
          target="_blank"
          rel="noopener noreferrer"
        >
          Dashboard icons created by Eucalyp - Flaticon
        </a>
        <a
          href="https://icons8.com/icon/NuL3hJoIQlEN/health"
          target="_blank"
          rel="noopener noreferrer"
        >
          Health
        </a>
        icon by{" "}
        <a href="https://icons8.com" target="_blank" rel="noopener noreferrer">
          Icons8
        </a>
      </div>
    </section>
  );
};

export default AboutData;
