import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import resultPageFullNameReferenceListData from "../resultPageNameReference";

import "./search-bar.component.css";
import "../general.css";

const SearchBar = (props) => {
  const { setSearchTerm } = props;

  // ******************************
  // *** SEARCH RESULTS PREVIEW STATE***
  // ******************************
  // This state determines whether the search bar is showing the search results
  // preview window below the search bar
  const [showSearchResultsPreview, setShowSearchResultsPreview] =
    useState(false);

  // FUNCTION
  // expected inputs: none
  // expected output/effect: toggles the showSearchResultsPreview state
  // to either true or false depending on the previous state
  // it is toggled by the onFocusCapture and onBlurCapture function of
  // search bar input below
  const updateShowSearchResultsPreview = () => {
    // setShowSearchResultsPreview(
    //   showSearchResultsPreview === false ? true : false
    // );
    // TEMPORARY FOR MVP
    setShowSearchResultsPreview(false);
  };

  // EFFECT
  // dependency: showSearchResultsPreview state
  // based on the current showSearchResultsPreview state, it will either
  // show or hide the search-results-preview window using 'hidden' class
  // from general.css
  useEffect(() => {
    const searchResultsPreview = document.querySelector(
      ".search-results-preview"
    );
    const globalNavSearchResultsPreview = document.querySelector(
      ".global-nav-search-results-preview"
    );
    if (showSearchResultsPreview === false) {
      searchResultsPreview.classList.add("search-results-preview-hidden");
    }
    if (showSearchResultsPreview === true) {
      searchResultsPreview.classList.remove("search-results-preview-hidden");
    }
    // if the global-nav-search-results-preview window is present,
    // we also want to ensure we hide the global-nav-search-results-preview window
    // every time we are clicking into or away from the search-resuts-preview window
    if (globalNavSearchResultsPreview)
      globalNavSearchResultsPreview.classList.add("global-nav-preview-hidden");
  }, [showSearchResultsPreview]);

  // TEMPORARY HANDLER FUNCTION FOR SEARCH BAR
  // const temporarySearchInputHandler = () => {
  //   return;
  // };

  const [tempSearchState, setTempSearchState] = useState("");

  useEffect(() => {
    return () => {
      if (setSearchTerm) setSearchTerm("hepatitis c");
    };
  });

  // conditionally render this component based on whether the
  // searchTerm state in App.js is empty

  // if the searchTerm is empty (user has not inputted anything yet) or
  // the user has deleted the search term entry entirely from the search bar,
  // then render a search input that,
  return props.searchTerm === "" ? (
    // cannot be submitted using default form functionality
    <form onSubmit={(e) => e.preventDefault()} className="search-bar-form">
      <input
        // when user focuses on the search input, then toggle the search-results-preview
        // window so that it is shown (see function in this component above)
        onFocusCapture={updateShowSearchResultsPreview}
        // and when user 'removes' focus from the search input, toggle the search-results-preview
        // window back to hidden
        // use onBlur vs. onBlurCapture because of the event bubbling sequence
        // allows us to keep the search results preview window visible long enough
        // if user wants to click on one of the preview links
        onBlur={updateShowSearchResultsPreview}
        // onChange function provided by parent component
        // onChange={props.onChangeFunction}
        // TEMPORARY FOR MVP
        onChange={(e) => {
          setTempSearchState(e.target.value);
        }}
        type="search"
        // conditional class for this particular search input - provided by
        // the parent component
        className={props.inputClass}
        size="30"
        placeholder="Enter a disease or drug"
        // value={props.searchTerm}
        // TEMPORARY FOR MVP
        defaultValue={"hepatitis c"}
        // value={tempSearchState}
        aria-label="Search for a disease state or drug"
      />
      {/* <button className={props.submitBtnClass} type="submit">
        Search
      </button> */}
      {/* TEMPORARY FOR MVP */}
      <button
        onClick={
          props.submitBtnClickFunction
            ? () => {
                props.submitBtnClickFunction();
                // TEMPORARY FOR MVP
                setSearchTerm("hepatitis c");
              }
            : () => {
                // TEMPORARY FOR MVP
                setSearchTerm("hepatitis c");
              }
        }
        className={props.submitBtnClass}
        type="submit"
      >
        Search
      </button>
      {/* and contains no drug/disease links in the search-results-preview
      window */}
      <div className="search-results-preview search-results-preview-position"></div>
    </form>
  ) : (
    // alternatively, if there is a searchTerm and it is NOT empty, then render
    // a normal form with search input
    <form className="search-bar-form">
      <input
        onFocusCapture={updateShowSearchResultsPreview}
        onBlur={updateShowSearchResultsPreview}
        // onChange={props.onChangeFunction}
        onChange={(e) => {
          setTempSearchState(e.target.value);
        }}
        type="search"
        className={props.inputClass}
        placeholder="Enter a disease or drug"
        // value={props.searchTerm}
        // TEMPORARY FOR MVP
        defaultValue={"hepatitis c"}
        // value={tempSearchState}
        aria-label="Search for a disease state or drug"
      />
      <Link className="search-btn-link" to={`/demo/search-results-page`}>
        <button
          onClick={
            props.submitBtnClickFunction
              ? () => {
                  props.submitBtnClickFunction();
                  // TEMPORARY FOR MVP
                  setSearchTerm("hepatitis c");
                }
              : () => {
                  // TEMPORARY FOR MVP
                  setSearchTerm("hepatitis c");
                }
          }
          className={props.submitBtnClass}
          type="submit"
        >
          Search
        </button>
      </Link>
      {props.currentSearchResults.length ? (
        <div className="search-results-preview search-results-preview-position">
          {props.currentSearchResults.map((result) => {
            return (
              <Link
                // this onFocus function keeps the searchResultsPreview window open just
                // long enough so that when you remove focus from the search input to
                // click on this link, the preview window will not disappear before you click the link
                // onFocus={() => setShowSearchResultsPreview(true)}
                // TEMPORARY FOR MVP
                onFocus={() => setShowSearchResultsPreview(false)}
                key={resultPageFullNameReferenceListData[result]}
                className="search-result-preview-item"
                // utilize the resultPageFullNameReferenceListData to look up respective
                // resultPageId to route to the result-page-component with the correct param

                // TEMPORARY FOR MVP
                // to={`/demo/${resultPageFullNameReferenceListData[result]}`}
                to=""
              >
                {result}
              </Link>
            );
          })}
        </div>
      ) : (
        <div className="search-results-preview search-results-preview-position">
          <p className="search-results-preview-empty-text">
            Sorry, there are no matches for that search
          </p>
        </div>
      )}
    </form>
  );
};

export default SearchBar;
