const clinicianIdReference = {
  ABC1: {
    firstName: "David",
    lastName: "Kan",
    credentials: "MD",
    clinicianType: "physician",
    specialty: "Infectious Disease",
    institution: "NYU Langone Health",
  },
  ABC2: {
    firstName: "Thomas",
    lastName: "Chesky",
    credentials: "PharmD",
    clinicianType: "pharmacist",
    specialty: "Infectious Disease",
    institution: "UCSF Health",
  },
  ABC3: {
    firstName: "Veejaye",
    lastName: "Jane",
    credentials: "PharmD",
    specialty: "Infectious Disease",
    clinicianType: "pharmacist",
    institution: "Vanderbilt University Medical Center",
  },
  ABC4: {
    firstName: "Derrick",
    lastName: "Wise",
    credentials: "MD",
    clinicianType: "physician",
    specialty: "Infectious Disease",
    institution: "University of Oklahoma Medical Center",
  },
  ABC5: {
    firstName: "Melissa",
    lastName: "Thames",
    credentials: "MSN",
    clinicianType: "nursePractitioner",
    specialty: "Infectious Disease",
    institution: "UCLA Health",
  },
  ABC6: {
    firstName: "Rory",
    lastName: "Wilson",
    credentials: "PA-C",
    clinicianType: "physicianAssistant",
    specialty: "Pediatrics",
    institution: "Seattle Children's Hospital",
  },
  ABC7: {
    firstName: "Tessa",
    lastName: "Thompson",
    credentials: "MD",
    clinicianType: "physician",
    specialty: "Primary Care",
    institution: "Keck Medicine of USC",
  },
  ABC8: {
    firstName: "Jane",
    lastName: "Allen",
    credentials: "DNP, APRN, FNP-C",
    clinicianType: "nursePractitioner",
    specialty: "Infectious Disease",
    institution: "Keck Medicine of USC",
  },
  ABC9: {
    firstName: "Mary",
    lastName: "Chung",
    credentials: "MD, MPH",
    clinicianType: "physician",
    specialty: "Infectious Disease",
    institution: "Robert Wood Johnson University Hospital",
  },
  ABC10: {
    firstName: "Helen",
    lastName: "Ming",
    credentials: "PA-C",
    clinicianType: "physicianAssistant",
    specialty: "Infectious Disease",
    institution: "Mayo Clinic",
  },
};

export default clinicianIdReference;
