import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import "./landing-page.component.css";
import "../general.css";

import Footer from "../footer/footer.component";

const LandingPage = (props) => {
  // destructure props
  const {
    blurBackground,
    setShowDisclaimerModal,
    scrollWaitList,
    setScrollWaitList,
    showWaitListDemoBtn,
    setShowWaitListDemoBtn,
  } = props;

  // EFFECT
  // ensure the background blur is turned off when this landing-page
  // component is mounted
  useEffect(() => {
    blurBackground("blurOff");
  });

  // *************************
  // HEALTHCARE PROVIDER STATE
  // *************************
  // this state is used in the waitlist form to determine whether we render
  // the healthcare provider drop-down menu
  const [healthcareProviderCheck, setHealthcareProviderCheck] = useState(false);

  // HANDLER FUNCTION
  // handles the clicks on the btns in the landing-page-nav
  // and scrolls the respective sections into view
  const handleNavBtnClick = (targetSection) => {
    const targetElement = document.querySelector(
      `.scroll-anchor-${targetSection}`
    );
    targetElement.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });

    // and for mobile sizes, we need to make sure we turn the
    // landing-page-blur off
    setLandingPageBlurOn(false);
    // and we also hide the landing-page-nav
    setHiddenLandingPageNavShown(false);
  };

  // EFFECT
  // add intersection observer to change the landing-page-header
  // to a fixed element
  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: "0px",
      threshold: 0.8,
    };

    const changeHeaderToFixed = (entries) => {
      const ent = entries[0];
      if (ent.isIntersecting) {
        const header = document.querySelector(".landing-page-header");
        header.classList.add("landing-page-header-fixed");
      }
    };

    const observer = new IntersectionObserver(
      changeHeaderToFixed,
      observerOptions
    );

    const targetContainers = document.querySelectorAll(".content-container");

    targetContainers.forEach((container) => {
      observer.observe(container);
    });
  });

  // *************************
  // HIDDEN LANDING PAGE NAV SHOWN STATE
  // *************************
  const [hiddenLandingPageNavShown, setHiddenLandingPageNavShown] =
    useState(false);

  // *************************
  // LANDING PAGE BLUR STATE
  // *************************

  const [landingPageBlurOn, setLandingPageBlurOn] = useState(false);

  // TEMPORARY EFFECT FOR MVP
  useEffect(() => {
    // if the scrollWaitList state from App.js, set by the global-nav-wait-list-btn
    // is true, then scroll the user to the end of the page
    if (scrollWaitList === true) {
      window.scrollTo(0, document.body.scrollHeight);
      // and then set the scrollWaitList state back to false
      setScrollWaitList(false);
    }
  });

  // EFFECT
  // if the showWaitListDemoBtn state is true, causing the demo btn to render
  // then we need to set that state back to false upon unmounting of this component
  // useEffect(() => {
  //   return () => {
  //     setShowWaitListDemoBtn(false);
  //   };
  // });

  return (
    <section className="landing-page-section-container">
      <div
        className={
          landingPageBlurOn === true
            ? "landing-page-blur"
            : "landing-page-blur landing-page-blur-off"
        }
        onClick={() => {
          // if the user clicks on the landing-page-blur
          // we want to hide the blur
          setLandingPageBlurOn(false);
          // and hide the hidden-landing-page-nav
          setHiddenLandingPageNavShown(false);
        }}
      ></div>
      <div className="scroll-anchor-home scroll-anchor" />
      <header
        className="landing-page-header"
        onClick={(e) => {
          const clickTargetClasses = e.target.classList;
          // if the user clicked on the header while the hidden-landing-page-nav
          // is visible and the click was NOT on the landing-page-nav, then
          if (
            hiddenLandingPageNavShown === true &&
            !clickTargetClasses.contains("landing-page-nav")
          ) {
            // to ensure user does not get stuck, we also want to
            // hide the blur and hidden-landing-page-nav in mobile and tablet
            // sizes if user were to click on the header
            setLandingPageBlurOn(false);
            setHiddenLandingPageNavShown(false);
          }
        }}
      >
        <Link
          className="landing-page-orca-logo-link"
          to="/"
          onClick={() => {
            handleNavBtnClick("home");
          }}
        >
          <img
            src={require("../img/orca-landing-page-logo.png")}
            className="landing-page-header-orca-logo"
            alt="Orca logo"
          />
        </Link>
        <nav
          className={
            hiddenLandingPageNavShown === true
              ? "landing-page-nav landing-page-hidden-nav-open"
              : "landing-page-nav"
          }
        >
          <button
            className="landing-page-nav-close-btn"
            type="button"
            onClick={() => {
              // when user clicks this close button, we want
              // to hide the landing-page-nav and turn
              // the landing-page-blur off
              setHiddenLandingPageNavShown(false);
              setLandingPageBlurOn(false);
            }}
          >
            <img
              className="landing-page-nav-close-btn-icon"
              src={require("../img/close.png")}
              alt="menu close button"
            ></img>
          </button>
          <button
            className="landing-page-nav-btn what-btn"
            onClick={() => {
              handleNavBtnClick("what");
            }}
          >
            What is Orca?
          </button>
          <button
            className="landing-page-nav-btn data-btn"
            onClick={() => {
              handleNavBtnClick("data");
            }}
          >
            Our Data
          </button>
          <button
            className="landing-page-nav-btn demo-btn"
            onClick={() => {
              handleNavBtnClick("demo");
            }}
          >
            Try Demo
          </button>
          <button
            className="landing-page-nav-wait-list-cta-btn"
            onClick={() => {
              handleNavBtnClick("wait-list");
            }}
          >
            Join Waitlist
          </button>
        </nav>
        <button
          className="landing-page-hidden-nav-btn"
          onClick={() => {
            // this button only appears in tablet and mobile sizes
            // we want to set the state of hiddenLandingPageNavShown to the
            // opposite of the previous/current state
            setHiddenLandingPageNavShown((prevState) => {
              const nextState = prevState === true ? false : true;
              return nextState;
            });
            // also set the landing-page-blur on
            setLandingPageBlurOn(true);
          }}
        >
          <img
            src={require("../img/nav-hamburger-menu.png")}
            className="landing-page-hidden-nav-btn-icon"
            alt="hamburger menu button"
          />
        </button>
      </header>
      <main className="landing-page-main-content-container">
        <div className="landing-page-hero-container">
          <div className="landing-page-hero-lockup">
            <div className="landing-page-hero-text-box">
              <h1 className="landing-page-hero-title">
                Learn from your peers’ experiences
              </h1>
              <p className="landing-page-hero-description">
                Find insights to help with challenging & controversial drug
                therapy decisions{" "}
                <strong className="landing-page-hero-description-bold">
                  where there are limited supporting clinical data
                </strong>
              </p>
              <Link
                className="landing-page-hero-demo-btn"
                to={"/demo"}
                onClick={() => {
                  // turn the background blur on when user is navigating
                  // to the demo
                  blurBackground("blurOn");
                  // also set the showDisclaimerModal state to true
                  setShowDisclaimerModal(true);
                }}
              >
                Try the demo
              </Link>
            </div>
            <figure className="landing-page-hero-img-figure">
              <img
                src={require("../img/landing-page-hero-img.jpg")}
                className="landing-page-hero-img"
                alt="Infographic cartoon of pharmacists"
              />
            </figure>
          </div>
        </div>

        <div className="landing-page-what-container content-container">
          <div className="scroll-anchor-what scroll-anchor" />
          <div className="landing-page-subhead-lockup">
            <h2 className="landing-page-subhead">What is Orca?</h2>
            <div className="landing-page-horizontal-line"></div>
          </div>
          <div className="landing-page-what-cards-container">
            <div className="landing-page-what-card">
              <figure className="landing-page-what-card-img-figure">
                <img
                  src={require("../img/digital-platform.jpg")}
                  className="landing-page-what-card-img what-card-digital-platform-img"
                  alt="Infographic cartoon of doctors looking at an oversized tablet screen"
                />
              </figure>
              <div className="landing-page-what-card-text-box">
                <h3 className="landing-page-what-card-title">
                  Decision-making Support in Gray Areas of Drug Therapy
                  Management
                </h3>
                <p className="landing-page-what-card-description">
                  Orca is a platform that helps with challenging & controversial
                  decisions in the gray areas of drug therapy management where
                  there are no clear answers & limited clinical trial data
                </p>
              </div>
            </div>
            <div className="landing-page-what-card">
              <figure className="landing-page-what-card-img-figure">
                <img
                  src={require("../img/clinician-group.jpg")}
                  className="landing-page-what-card-img"
                  alt="Infographic cartoon of various clinicians"
                />
              </figure>
              <div className="landing-page-what-card-text-box">
                <h3 className="landing-page-what-card-title">
                  Actionable Insights From Your Peers' Experiences
                </h3>
                <p className="landing-page-what-card-description">
                  Our platform contains succinct, actionable insights drawn
                  directly from the experiences of clinicians and their
                  approaches to challenging scenarios
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="landing-page-data-container content-container">
          <div className="scroll-anchor-data scroll-anchor" />
          <div className="landing-page-subhead-lockup">
            <h2 className="landing-page-subhead">Our Data</h2>
            <div className="landing-page-horizontal-line"></div>
          </div>
          <div className="landing-page-data-content-cards-container">
            <div className="landing-page-data-content-card">
              <figure className="landing-page-data-content-card-img-figure">
                <img
                  src={require("../img/clinician-survey.jpg")}
                  className="landing-page-data-content-card-img clinician-survey-img"
                  alt="Infographic cartoon of people viewing a survey"
                />
              </figure>
              <div className="landing-page-data-content-card-text-box">
                <h3 className="landing-page-data-content-card-title">
                  We generate our insights by surveying and collecting data from
                  clinicians
                </h3>
                <ul className="landing-page-data-content-card-list">
                  <li className="landing-page-data-content-card-list-item">
                    <figure className="landing-page-data-content-card-list-item-bullet">
                      <img
                        src={require("../img/pill-bullet.png")}
                        className="landing-page-data-content-card-list-item-bullet-icon"
                        alt="Icon of a pill"
                      />
                    </figure>
                    <p className="landing-page-data-content-card-list-item-text">
                      Survey responses are also accompanied by a brief written
                      rationale from the clinician
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="landing-page-data-content-card">
              <figure className="landing-page-data-content-card-img-figure">
                <img
                  src={require("../img/verified.jpg")}
                  className="landing-page-data-content-card-img verified-img"
                  alt="Infographic cartoon of a woman verifiying her identity on smart phone"
                />
              </figure>
              <div className="landing-page-data-content-card-text-box">
                <h3 className="landing-page-data-content-card-title">
                  We ensure that all the data on our platform is sourced from
                  licensed and experienced clinicians
                </h3>
                <ul className="landing-page-data-content-card-list">
                  <li className="landing-page-data-content-card-list-item">
                    <figure className="landing-page-data-content-card-list-item-bullet">
                      <img
                        src={require("../img/pill-bullet.png")}
                        className="landing-page-data-content-card-list-item-bullet-icon"
                        alt="Icon of a pill"
                      />
                    </figure>
                    <p className="landing-page-data-content-card-list-item-text">
                      We verify the ID and active state license status for every
                      clinician on our platform
                    </p>
                  </li>
                  <li className="landing-page-data-content-card-list-item">
                    <figure className="landing-page-data-content-card-list-item-bullet">
                      <img
                        src={require("../img/pill-bullet.png")}
                        className="landing-page-data-content-card-list-item-bullet-icon"
                        alt="Icon of a pill"
                      />
                    </figure>
                    <p className="landing-page-data-content-card-list-item-text">
                      For each piece of data submitted, we verify that the
                      clinician actually has the specific experience mentioned
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="landing-page-data-content-card">
              <figure className="landing-page-data-content-card-img-figure">
                <img
                  src={require("../img/internal-clinical-team.jpg")}
                  className="landing-page-data-content-card-img internal-clinical-team-img"
                  alt="Infographic cartoon of person looking at data"
                />
              </figure>
              <div className="landing-page-data-content-card-text-box">
                <h3 className="landing-page-data-content-card-title">
                  We have a dedicated internal clinical team for each specialty
                  area that:
                </h3>
                <ul className="landing-page-data-content-card-list">
                  <li className="landing-page-data-content-card-list-item">
                    <figure className="landing-page-data-content-card-list-item-bullet">
                      <img
                        src={require("../img/pill-bullet.png")}
                        className="landing-page-data-content-card-list-item-bullet-icon"
                        alt="Icon of a pill"
                      />
                    </figure>
                    <p className="landing-page-data-content-card-list-item-text">
                      Reviews all survey submissions and generated insights to
                      maintain the high standard of all our clincial content
                    </p>
                  </li>
                  <li className="landing-page-data-content-card-list-item">
                    <figure className="landing-page-data-content-card-list-item-bullet">
                      <img
                        src={require("../img/pill-bullet.png")}
                        className="landing-page-data-content-card-list-item-bullet-icon"
                        alt="Icon of a pill"
                      />
                    </figure>
                    <p className="landing-page-data-content-card-list-item-text">
                      Curates new survey questions in collaboration with our
                      community
                    </p>
                  </li>
                  <li className="landing-page-data-content-card-list-item">
                    <img
                      src={require("../img/pill-bullet.png")}
                      className="landing-page-data-content-card-list-item-bullet-icon"
                      alt="Icon of a pill"
                    />
                    <p className="landing-page-data-content-card-list-item-text">
                      Continually monitors for developments in clinical practice
                      and periodically re-surveys clinicians to keep our data up
                      to date
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="landing-page-data-examples-container">
            <h3 className="landing-page-data-examples-subhead">
              Examples of our data + insights:
            </h3>
            <div className="landing-page-data-examples-pictures">
              <figure className="landing-page-data-example-img-figure">
                <img
                  src={require("../img/data-example-1.png")}
                  className="landing-page-data-example-img"
                  alt="Screenshot of an insight from Orca platform"
                />
              </figure>
              <figure className="landing-page-data-example-img-figure">
                <img
                  src={require("../img/data-example-2.png")}
                  className="landing-page-data-example-img"
                  alt="Screenshot of an insight from Orca platform"
                />
              </figure>
              <figure className="landing-page-data-example-img-figure data-example-img-no-border">
                <img
                  src={require("../img/data-example-3.png")}
                  className="landing-page-data-example-img"
                  alt="Screenshot of an insight from Orca platform"
                />
              </figure>
              <figure className="landing-page-data-example-img-figure data-example-img-no-border">
                <img
                  src={require("../img/data-example-4.png")}
                  className="landing-page-data-example-img"
                  alt="Screenshot of an insight from Orca platform"
                />
              </figure>
            </div>
          </div>
        </div>
        <div className="landing-page-demo-container content-container">
          <div className="scroll-anchor-demo scroll-anchor" />
          <div className="landing-page-subhead-lockup">
            <h2 className="landing-page-subhead">Want to take a look?</h2>
          </div>
          <p className="landing-page-demo-intro">
            Click the link below to see a demo of the platform we are building.
            Our product will initially be focused on{" "}
            <strong className="landing-page-demo-intro-emphasis">
              infectious diseases
            </strong>
            , but we will soon expand into other specialties and disease areas.
          </p>
          <Link
            className="landing-page-demo-link"
            to="/demo"
            onClick={() => {
              // turn the background blur on when user is navigating
              // to the demo
              blurBackground("blurOn");
              // also set the showDisclaimerModal state to true
              setShowDisclaimerModal(true);
            }}
          >
            Try the demo
          </Link>
          <p className="landing-page-demo-disclaimer">
            Note: all content currently within the demo are{" "}
            <strong className="landing-page-demo-disclaimer-emphasis">
              examples
            </strong>{" "}
            of the data and insights our platform will generate when it is
            completed
          </p>
        </div>
        <div className="landing-page-wait-list-container content-container">
          <div className="scroll-anchor-wait-list scroll-anchor" />
          <div className="landing-page-subhead-lockup">
            <h2 className="landing-page-subhead">Join Our Waitlist</h2>
            <div className="landing-page-horizontal-line"></div>
          </div>
          <form
            name="orca-wait-list"
            className="landing-page-wait-list-form"
            method="POST"
            data-netlify="true"
          >
            <h3 className="landing-page-wait-list-intro">
              Sign up to be notified about the future Orca product launches and
              other related updates
            </h3>
            <input type="hidden" name="form-name" value="orca-wait-list" />
            <p className="landing-page-wait-list-form-item">
              <label className="landing-page-wait-list-form-item-label">
                Name
              </label>
              <input
                className="landing-page-wait-list-form-item-input"
                type={"text"}
                name="name"
                placeholder="Jane Johnson"
                required
              />
            </p>
            <p className="landing-page-wait-list-form-item">
              <label className="landing-page-wait-list-form-item-label">
                Email
              </label>
              <input
                className="landing-page-wait-list-form-item-input"
                type={"email"}
                name="email"
                placeholder="jane@example.com"
                required
              />
            </p>
            <p className="landing-page-wait-list-form-item wait-list-checkbox-item">
              <input
                className="landing-page-wait-list-form-item-checkbox"
                type={"checkbox"}
                name="healthcareProviderCheckbox"
                onClick={() => {
                  setHealthcareProviderCheck((prevCheck) => {
                    return prevCheck === true ? false : true;
                  });
                }}
              ></input>
              <label className="landing-page-wait-list-form-item-label">
                I am a healthcare provider
              </label>
            </p>
            <p
              className={
                healthcareProviderCheck === true
                  ? "landing-page-wait-list-form-item"
                  : "landing-page-wait-list-form-item wait-list-form-item-hidden"
              }
            >
              <label className="landing-page-wait-list-form-item-label">
                I am a...
              </label>
              <select
                className="landing-page-wait-list-form-select"
                name="hcp-select"
              >
                <option
                  className="landing-page-wait-list-form-select"
                  value={"Please select one"}
                ></option>
                <option
                  className="landing-page-wait-list-form-select"
                  value={"physician"}
                >
                  Physician
                </option>
                <option
                  className="landing-page-wait-list-form-select"
                  value={"nursePractitioner"}
                >
                  Nurse Practitioner
                </option>
                <option
                  className="landing-page-wait-list-form-select"
                  value={"physicianAssistant"}
                >
                  Physician Assistant
                </option>
                <option
                  className="landing-page-wait-list-form-select"
                  value={"pharmacist"}
                >
                  Pharmacist
                </option>
                <option
                  className="landing-page-wait-list-form-select"
                  value={"nurse"}
                >
                  Nurse
                </option>
                <option
                  className="landing-page-wait-list-form-select"
                  value={"otherHcp"}
                >
                  Other healthcare provider
                </option>
              </select>
            </p>
            <p className="landing-page-wait-list-form-item">
              <label className="landing-page-wait-list-form-item-label">
                Optional: What specialty area/disease state would you like to
                see added to the Orca platform?
              </label>
              <input
                className="landing-page-wait-list-form-item-input"
                type={"text"}
                name="specialty-disease-area"
                placeholder="Crohn's disease"
              />
            </p>
            <div className="landing-page-waitlist-btns-box">
              <button
                className="landing-page-wait-list-form-submit-btn"
                type="submit"
              >
                Join Waitlist
              </button>
              {showWaitListDemoBtn === true ? (
                <Link
                  className="landing-page-wait-list-form-demo-btn"
                  to={"/demo"}
                  onClick={() => {
                    // turn the background blur on when user is navigating
                    // to the demo
                    blurBackground("blurOn");
                    // also set the showDisclaimerModal state to true
                    setShowDisclaimerModal(true);
                  }}
                >
                  Back to Demo
                </Link>
              ) : null}
            </div>
          </form>
        </div>
      </main>
      <Footer />
    </section>
  );
};

export default LandingPage;
