import React from "react";

import "../general.css";
import "./insights-filter-flags.component.css";

const InsightsFilterFlags = (props) => {
  // destructure props to get the needed states from result-data-page-component
  const { setInsightsFilter, allDrugsOrDiseasesObj } = props;
  // initialize several consts to the various filter properties within insightsFilter state
  // in result-data-page component that is being passed in as props to this component
  const { searchTerm, drugOrDiseaseId, clinicianType, patientBuilder } =
    props.insightsFilter;

  // initialize arrays to an array of all the possible clinician types from the clinicianType obj and,
  const allClinicianTypes = Object.keys(clinicianType);
  // an array of the values of the clinician type obj which represent how many clinicianTypes are currently
  // being included in the insightsFilter state in result-data-page component
  const clinicianTypeArrayValues = Object.values(clinicianType);
  // also create a filtered array of only the true values of clinicianTypeArrayValues
  // this is used in the handleRemoveFilterBtnClick handler function below to determine
  // if we need to remove the ability to remove the final clinicianType filter if there is only
  // 1 remaining clinicianType included in the insightsFilter state in result-data-page component
  const clinicianTypeArrayTrueValues = clinicianTypeArrayValues.filter(
    (boolean) => boolean === true
  );

  // initialize a filtered array of every patient attribute from patientBuilder obj
  // that is true
  const activePatientAttributesFilters = Object.keys(patientBuilder).filter(
    (attribute) => {
      return patientBuilder[attribute] === true ? attribute : null;
    }
  );

  // HANDLER FUNCTION
  // handles the clicks on the result-data-page-active-filter-remove-btn
  // when user clicks on the button to remove the targeted insight,
  // this function will also update the insightsFilter state
  const handleRemoveFilterBtnClick = (filter, property) => {
    // if the filter is NOT clinicianType and NOT patientBuilder, then simply create a shallow copy of the
    // previous insightsFilter and update the value to a blank string since we are
    // 'removing' this filter from insightsFilter state
    if (filter !== "clinicianType" && filter !== "patientBuilder") {
      setInsightsFilter((prevInsightsFilter) => {
        const newInsightsFilter = { ...prevInsightsFilter };
        newInsightsFilter[filter] = "";
        return newInsightsFilter;
      });
    }

    // if the filter is patientBuilder, then we have to update the patientBuilder obj of the
    // new insightsFilter state accordingly
    if (filter === "patientBuilder") {
      setInsightsFilter((prevInsightsFilter) => {
        const newInsightsFilter = { ...prevInsightsFilter };
        newInsightsFilter.patientBuilder[property] = false;
        return newInsightsFilter;
      });
    }
    // if the filter IS clinicianType, then
    if (filter === "clinicianType") {
      // if there is more than 1 true value, meaning if we remove this filter from insightsFilter, then we
      // will not end up with blank data in the data-insight-card components rendered in the result-data-page component,
      // then we can create a shallow copy of hte previous insightsFilter and reassign the value
      // of the respective clinicianType property to false to 'remove' that filter from insightsFilter state
      if (clinicianTypeArrayTrueValues.length > 1) {
        setInsightsFilter((prevInsightsFilter) => {
          const newInsightsFilter = { ...prevInsightsFilter };
          newInsightsFilter.clinicianType[property] = false;
          return newInsightsFilter;
        });
      }
      // however, if there is only 1 true value left, meaning that if we remove this filter we will end up with blank
      // data in the data-insight-card components rendered in the result-data-page component,
      // then we will not give the user the ability to remove this filter
      // instead, we will make the clinician-types-filter-warning visible. It will flash on the
      // screen for 2 seconds to warn the user that they cannot remove this filter and then will go back to being transparent
      if (clinicianTypeArrayTrueValues.length === 1) {
        const clinicianTypesFilterWarning = document.querySelector(
          ".insights-filter-flags-clinican-types-filter-warning"
        );
        clinicianTypesFilterWarning.classList.remove(
          "filter-warning-transparent"
        );
        setTimeout(() => {
          clinicianTypesFilterWarning.classList.add(
            "filter-warning-transparent"
          );
        }, 2000);
        return;
      }
    }
  };

  // HANDLER FUNCTION
  // adds all clnician types back into insights-filter state when user clicks on
  // the insights-filter-flags-add-all-clinicians-button
  const handleAddAllCliniciansBtn = () => {
    setInsightsFilter((prevInsightsFilter) => {
      const newInsightsFilter = { ...prevInsightsFilter };
      const allClinicianTypesArr = Object.keys(newInsightsFilter.clinicianType);
      allClinicianTypesArr.forEach((clinician) => {
        newInsightsFilter.clinicianType[clinician] = true;
      });
      return newInsightsFilter;
    });
  };

  return (
    <div className="insights-filter-flags-container">
      <h3 className="insights-filter-flags-header">Current filters:</h3>
      <div className="insights-filter-flags-all-filters-container grid grid--3-cols">
        {activePatientAttributesFilters.length > 0
          ? activePatientAttributesFilters.map((attribute) => {
              return (
                <div
                  className="insights-filter-flags-filter-box patient-builder-flag"
                  key={attribute}
                >
                  <p className="insights-filter-flags-filter-name">
                    Patient attribute:{" "}
                    <span className="flag-highlight">
                      {attribute === "treatmentNaive"
                        ? "treatment naive"
                        : attribute === "treatmentExperienced"
                        ? "treatment experienced"
                        : attribute === "compensatedCirrhosis"
                        ? "compensated cirrhosis"
                        : attribute === "decompensatedCirrhosis"
                        ? "decompensated cirrhosis"
                        : attribute === "genotype1"
                        ? "genotype 1"
                        : attribute === "genotype2"
                        ? "genotype 2"
                        : attribute === "genotype3"
                        ? "genotype 3"
                        : attribute === "genotype4"
                        ? "genotype 4"
                        : attribute === "genotype5or6"
                        ? "genotype 5 or 6"
                        : attribute === "hivHcvCoinfection"
                        ? "HIV-HCV co-infection"
                        : attribute === "organsFromHcvDonors"
                        ? "organs from HCV donors"
                        : attribute === "renalImpairment"
                        ? "renal impairment"
                        : attribute === "kidneyTransplant"
                        ? "kidney transplant"
                        : attribute === "acuteInfection"
                        ? "acute infection"
                        : attribute === "pregnant"
                        ? "pregnant"
                        : attribute === "pediatric"
                        ? "pediatric"
                        : attribute === "pwid"
                        ? "PWID"
                        : attribute === "msm"
                        ? "MSM"
                        : "corrections"}
                    </span>
                  </p>
                  <button
                    className="insights-filter-flags-remove-filter-btn"
                    type="button"
                    onClick={() => {
                      handleRemoveFilterBtnClick("patientBuilder", attribute);
                    }}
                  >
                    <img
                      className="insights-filter-flags-remove-filter-btn-icon"
                      src={require("../img/close.png")}
                      alt="remove filter button"
                    />
                  </button>
                </div>
              );
            })
          : ""}
        {searchTerm ? (
          <div className="insights-filter-flags-filter-box search-term-flag">
            <p className="insights-filter-flags-filter-name">
              Search term: <span className="flag-highlight">{searchTerm}</span>
            </p>
            <button
              className="insights-filter-flags-remove-filter-btn"
              type="button"
              onClick={() => {
                handleRemoveFilterBtnClick("searchTerm");
              }}
            >
              <img
                className="insights-filter-flags-remove-filter-btn-icon"
                src={require("../img/close.png")}
                alt="remove filter button"
              />
            </button>
          </div>
        ) : (
          ""
        )}
        {drugOrDiseaseId ? (
          <div className="insights-filter-flags-filter-box drug-or-disease-id-flag">
            <p className="insights-filter-flags-filter-name">
              {allDrugsOrDiseasesObj[drugOrDiseaseId].type === "disease"
                ? "Disease"
                : "Drug:"}{" "}
              <span className="flag-highlight">
                {allDrugsOrDiseasesObj[drugOrDiseaseId].name}
              </span>
            </p>
            <button
              className="insights-filter-flags-remove-filter-btn"
              type="button"
              onClick={() => {
                handleRemoveFilterBtnClick("drugOrDiseaseId");
              }}
            >
              <img
                className="insights-filter-flags-remove-filter-btn-icon"
                src={require("../img/close.png")}
                alt="remove filter button"
              />
            </button>
          </div>
        ) : (
          ""
        )}
        {/* for rendering the clinicianType filters, if there are BOTH true and false values
        in the clinicianTypeArrayValues, meaning that we are not including ALL clinicians 
        (in insightsFilter state) and we also did not filter OUT all clinicians either, 
        then render the flags normally */}
        {/* BUT if we are including ALL clinicianTypes in insightsFilter state, then just render
        a notification saying that using the insights-filter-flags-box element, but without a button
        allowing the user to remove the filter, as there is not filter to remove in this case */}
        {clinicianTypeArrayValues.includes(false) &&
        clinicianTypeArrayValues.includes(true) ? (
          allClinicianTypes
            .filter((clinician) => {
              return clinicianType[clinician] === true ? clinician : null;
            })
            .map((filteredClinician) => {
              return (
                <div
                  className="insights-filter-flags-filter-box clinician-type-flag"
                  key={filteredClinician}
                >
                  <p className="insights-filter-flags-filter-name">
                    Including data from:{" "}
                    <span className="flag-highlight">
                      {filteredClinician === "nursePractitioner"
                        ? "nurse practitioner"
                        : filteredClinician === "physicianAssistant"
                        ? "physician assistant"
                        : filteredClinician}
                      s
                    </span>
                  </p>
                  <button
                    className="insights-filter-flags-remove-filter-btn"
                    type="button"
                    onClick={() => {
                      handleRemoveFilterBtnClick(
                        "clinicianType",
                        filteredClinician
                      );
                    }}
                  >
                    <img
                      className="insights-filter-flags-remove-filter-btn-icon"
                      src={require("../img/close.png")}
                      alt="remove filter button"
                    />
                  </button>
                </div>
              );
            })
        ) : (
          <div className="insights-filter-flags-filter-box clinician-type-flag">
            <p className="insights-filter-flags-filter-name">
              <span className=" flag-highlight">
                Including ALL clinician types
              </span>
            </p>
          </div>
        )}
        {clinicianTypeArrayValues.includes(false) &&
        clinicianTypeArrayValues.includes(true) ? (
          <button
            className="insights-filter-flags-add-all-clinicians-button"
            type="button"
            onClick={handleAddAllCliniciansBtn}
          >
            <div className="insights-filter-flags-filter-box all-clinicians-btn-box">
              <p className="insights-filter-flags-filter-name">
                <span className="flag-highlight">
                  Click here to include data from ALL clinican types
                </span>
              </p>
            </div>
          </button>
        ) : null}
      </div>
      <p className="insights-filter-flags-clinican-types-filter-warning filter-warning-transparent">
        You must include at least 1 clinician type in your selected filters
      </p>
    </div>
  );
};

export default InsightsFilterFlags;
