import React, { useEffect } from "react";

import "../general.css";
import "./contact-us.component.css";

const ContactUs = (props) => {
  const { setGlobalNavSearchBarShown } = props;
  useEffect(() => {
    setGlobalNavSearchBarShown(true);
  });
  return (
    <section className="contact-us-section">
      <div className="contact-us-container container">
        <h1 className="contact-us-heading-primary">CONTACT INFO HERE</h1>
      </div>
    </section>
  );
};

export default ContactUs;
