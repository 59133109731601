import React, { useEffect } from "react";

import "../general.css";
import "./survey-page.component.css";

import SurveyQuestion from "../survey-question/survey-question.component";

const SurveyPage = (props) => {
  // destructure props
  const {
    surveyData,
    availableSurveys,
    completedSurveys,
    setGlobalNavSearchBarShown,
  } = props;

  // Effect
  // set the globalNavSearchBar state from result-data-page component to true
  // so that we render the globalNavSearchBar
  useEffect(() => {
    setGlobalNavSearchBarShown(true);
  });

  return (
    <section className="survey-page-section-container">
      <h1 className="survey-page-title">Survey Opportunities</h1>
      <h2 className="survey-page-subhead">Available Surveys</h2>
      <div className="survey-page-horizontal-line"></div>
      <div className="survey-page-survey-questions-container available-survey-questions-container">
        {availableSurveys.map((surveyId, index) => {
          return (
            <SurveyQuestion
              key={surveyId}
              surveyId={surveyId}
              surveyNumber={index + 1}
              surveyObj={surveyData[surveyId]}
              showBlur={false}
            />
          );
        })}
      </div>
      <h2 className="survey-page-subhead">Completed Surveys</h2>
      <div className="survey-page-horizontal-line"></div>
      <div className="survey-page-survey-questions-container completed-survey-questions-container">
        {completedSurveys.map((surveyId, index) => {
          return (
            <SurveyQuestion
              key={surveyId}
              surveyId={surveyId}
              surveyNumber={index + 1}
              surveyObj={surveyData[surveyId]}
              showBlur={true}
            />
          );
        })}
      </div>
    </section>
  );
};

export default SurveyPage;
