import drugAndDiseaseDataObj from "./drugAndDiseaseData";

// FUNCTION (in future, this should live on server)
// expected arguments: none
// dependencies: drugAndDiseaseData import
// expected output: an object with:
// keys: result full name - the full name of the drug or disease dynamically generated from properties included
// in drugAndDiseaseData. The name is generated differently depending on data type (drug or disease)
// values: resultPageId - unique identifier for each drug and disease in platform
const getResultFullNameReferenceData = () => {
  const resultFullNameReferenceList = {};
  const drugAndDiseaseObj = { ...drugAndDiseaseDataObj };
  const drugAndDiseaseArray = Object.values(drugAndDiseaseObj);

  drugAndDiseaseArray.forEach((drugDisease) => {
    resultFullNameReferenceList[
      `${
        drugDisease.type === "disease"
          ? `${
              drugDisease.diseaseSeverity
                ? `${drugDisease.diseaseSeverity} ${drugDisease.diseaseName}`
                : `${drugDisease.diseaseName}`
            }`
          : `${
              drugDisease.brandName
                ? `${drugDisease.brandName} (${drugDisease.genericName})`
                : `${drugDisease.genericName}`
            }`
      }`
    ] = drugDisease.resultPageId;
  });
  return resultFullNameReferenceList;
};

const resultPageFullNameReferenceListData = getResultFullNameReferenceData();

export default resultPageFullNameReferenceListData;
