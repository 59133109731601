import React, { useEffect } from "react";

import SearchBar from "../search-bar/search-bar.component";

import "./home-page.component.css";
import "../general.css";

const HomePage = (props) => {
  // every time that a user navigates back to the landing page
  // clear the existing search term
  const { setSearchTerm, setGlobalNavSearchBarShown } = props;

  // EFFECT
  // set the searchTerm state to an empty string every time
  // user navigates back to this page
  // TEMPORARY FOR MVP
  // useEffect(() => {
  //   setSearchTerm("");
  // }, [setSearchTerm]);

  // EFFECT
  // make the global nav search bar component visible
  useEffect(() => {
    setGlobalNavSearchBarShown(false);
  });

  // EFFECT
  // every time we render the homepage, scroll the user to the top
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <section className="section-hero">
      <div className="hero-flex-container">
        <div className="hero-text-box">
          <h1 className="hero-heading-primary">
            Manage drug treatment with confidence. Learn what your peers usually
            do in the same scenarios you're facing.
          </h1>
          <p className="hero-description">
            Search for a disease or drug and find practical insights on
            different drug therapy management scenarios like special populations
            and side effect management
          </p>
          <SearchBar
            // onChange function: update the searchTerm state in App.js
            onChangeFunction={props.updateSearchTerm}
            // this will provide the value displayed within the search term input
            // it will match the current searchTerm state
            searchTerm={props.searchTerm}
            // pass through the searchResults state from App.js
            currentSearchResults={props.searchResults}
            // styling classes specific to the search bar in this landing-page-component
            inputClass="hero-search"
            submitBtnClass="btn search-btn"
            // TEMPORARY FOR MVP
            setSearchTerm={setSearchTerm}
          />
          <p className="hero-mvp-disclaimer">
            {" "}
            Note: this demo is initially focused on Hepatitis C, but we will
            soon be expanding into other infectious diseases and other disease
            areas.
          </p>
        </div>
        <div className="hero-img-box">
          <img
            src={require("../img/hero-img.jpg")}
            className="hero-img"
            alt="Female clinician consulting patient"
          />
        </div>
      </div>
    </section>
  );
};

export default HomePage;
