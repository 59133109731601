import React, { useState } from "react";

import "../general.css";
import "./survey-question.component.css";

const SurveyQuestion = (props) => {
  // destructure props
  const { surveyId, surveyNumber, surveyObj, showBlur } = props;
  //   destructure surveyObj
  const { topic, question, answerType, answerPlaceholder, answers } = surveyObj;

  // CURRENT ANSWER STATE
  // this state stores the current answer as the user interacts
  // with this survey question
  const [currentAnswer, setCurrentAnswer] = useState(
    localStorage.getItem(surveyId)
  );

  // HANDLER FUNCTION
  // handles updating the currentAnswer state when user interacts with
  // number input survey questions
  const handleAnswerInput = (e) => {
    setCurrentAnswer(e.target.value);
  };

  // HANLDER FUNCTION
  // when user clicks 'submit answer' btn
  const handleAnswerSubmit = (e) => {
    // we would do a post request via api to our server, but now
    // using localStorage for MVP
    localStorage.setItem(surveyId, currentAnswer);
    // we will also add a greyed out overlay after the user submitted the answer
    const surveyContainerBlur = e.target
      .closest(".survey-question-container")
      .querySelector(".survey-question-blur");
    surveyContainerBlur.classList.remove("survey-blur-hidden");
  };

  // HANDLER FUNCTION
  const handleChangeAnswerBtnClick = (e) => {
    // initialize a const to the closest surveyContainerBlur
    const surveyContainerBlur = e.target.closest(".survey-question-blur");
    // turn the blur off
    surveyContainerBlur.classList.add("survey-blur-hidden");
  };

  return (
    <div className="survey-question-container">
      <div className="survey-question-topic">{topic}</div>
      <div
        className={
          showBlur === true
            ? "survey-question-blur"
            : "survey-question-blur survey-blur-hidden"
        }
      >
        <p className="survey-question-blur-subhead">Answer submitted!</p>
        <button
          className="survey-question-blur-change-answer-btn"
          onClick={handleChangeAnswerBtnClick}
        >
          Change my answer
        </button>
      </div>
      {/* <h3 className="survey-question-survey-number"></h3> */}
      <h3 className="survey-question-question-text">
        <span className="survey-question-survey-number">{surveyNumber}.</span>{" "}
        {question}
      </h3>

      <div className="survey-question-answer-container">
        {answerType === "number" ? (
          <div className="survey-question-number-answer">
            <input
              className="survey-question-number-input"
              type={"number"}
              min={0}
              placeholder={answerPlaceholder}
              onChange={handleAnswerInput}
            />
            <button
              className="survey-question-number-answer-submit-btn submit-answer-btn"
              type={"button"}
              onClick={handleAnswerSubmit}
            >
              Submit answer
            </button>
          </div>
        ) : answerType === "multipleChoice" ? (
          <div className="survey-question-multiple-choice-answers-container">
            {Object.keys(answers).map((answerId, index) => {
              return (
                <button
                  key={surveyId + index}
                  className={
                    currentAnswer === answerId
                      ? "survey-question-multiple-choice-answer-btn multiple-choice-answer-pressed"
                      : "survey-question-multiple-choice-answer-btn"
                  }
                  type="button"
                  onClick={handleAnswerInput}
                  value={answerId}
                >
                  {answers[answerId]}
                </button>
              );
            })}
            <button
              className="survey-question-number-answer-submit-btn submit-answer-btn"
              type={"button"}
              onClick={handleAnswerSubmit}
            >
              Submit answer
            </button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default SurveyQuestion;
