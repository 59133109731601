import React, { useEffect, useRef, useState } from "react";

import SearchResult from "../search-result/search-result.component";
import SearchBar from "../search-bar/search-bar.component";

import resultPageFullNameReferenceListData from "../resultPageNameReference";
import drugAndDiseaseDataObj from "../drugAndDiseaseData";

import "./search-results-page.component.css";
import "../general.css";

const SearchResultsPage = (props) => {
  // destructure props into several consts
  const { searchTerm } = props;

  // ******************************
  // ***SEARCH DESCRIPTION STATE***
  // ******************************

  // sets the search description that appears below search bar in search-results-page component
  const [searchDescription, setSearchDescription] = useState(searchTerm);

  // rendered search results ref - maintain the latest rendered search results between re-renders
  // of the component due to other reasons
  // const renderedSearchResults = useRef(props.searchResults);

  // TEMPORARY FOR MVP
  const renderedSearchResults = useRef(["Hepatitis C (HCV)"]);

  // FUNCTION
  // expected arguments: none
  // expected output/effect:
  // searchBar functionality - when user submits a search or click on the
  // 'Search' button in the searchBar component, we want the following to occur:
  const searchBarSubmitFxn = () => {
    // update the search description below the search bar with the latest search term
    setSearchDescription(searchTerm);
    // update the search results rendered in the component
    renderedSearchResults.current = props.searchResults;
  };

  // EFFECT
  // update the reminder added to the search description to prompt the user
  // to input a search term in order to proceed with searching for a drug/disease
  useEffect(() => {
    const emptySearchBarReminder = document.querySelector(
      ".search-results-page-search-empty-reminder"
    );
    emptySearchBarReminder.innerHTML = "";
    emptySearchBarReminder.insertAdjacentHTML(
      "afterbegin",
      `${searchTerm === "" ? `Please enter a search term. ` : ``}`
    );
  });

  // EFFECT
  // make the global nav search bar component visible
  useEffect(() => {
    props.setGlobalNavSearchBarShown(false);
  });

  return (
    <section className="section-search">
      <div className="search-container container">
        <div className="search-bar-container">
          <SearchBar
            onChangeFunction={props.updateSearchTerm}
            searchTerm={searchTerm}
            currentSearchResults={props.searchResults}
            // see above - when user clicks 'Search' button, we want the search description
            // to update and to update the renderedSearchResults ref
            submitBtnClickFunction={searchBarSubmitFxn}
            inputClass="search-results-search-input"
            submitBtnClass="btn search-btn"
          />
        </div>
        <div className="search-description">
          <span className="search-results-page-search-empty-reminder"></span>
          Showing search results for "{searchDescription}". <br />
          <br />
          <strong className="search-resut-mvp-disclaimer">
            This demo is initially focused on hepatitis C, but we will soon be
            expanding into other infectious diseases and other disease areas.
          </strong>
        </div>
        {/* if there are any search results that match the user's inputted search term... */}
        {/* then iterate through the search results we are going to render and generate a
        a SearchResult component for each search result */}
        {renderedSearchResults.current.length ? (
          <div className="search-results-container">
            {/* initialize the resultPageId variable with the ID value derived from
              the resulitPageFullNameReferenceList for each respective search result */}
            {renderedSearchResults.current.map((searchResult, index) => {
              const resultPageId =
                resultPageFullNameReferenceListData[searchResult];
              return (
                <SearchResult
                  key={resultPageId}
                  resultPageId={resultPageId}
                  resultNumber={index + 1}
                  resultTitle={searchResult}
                  resultTopInsights={
                    drugAndDiseaseDataObj[resultPageId].topInsights
                  }
                  resultAllInsights={
                    drugAndDiseaseDataObj[resultPageId].allInsights
                  }
                />
              );
            })}
          </div>
        ) : (
          <div className="search-result-error">
            Sorry, there are no results matching your search term
          </div>
        )}
      </div>
    </section>
  );
};

export default SearchResultsPage;
