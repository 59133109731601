const surveyData = {
  surv001: {
    topic: "Hepatitis C",
    question:
      "How many days would a patient need to have lapsed direct-acting antiviral (DAA) treatment before you would reconsider resuming the regimen and finishing out the original treatment course?",
    answerType: "number",
    answerPlaceholder: "Enter number of days",
    answers: null,
  },
  surv002: {
    topic: "Hepatitis C",
    question:
      "What treatment regimen do you prefer for patients who fail second-line DAA regimens (with either GP or SOF/VEL/VOX)?",
    answerType: "multipleChoice",
    answerPlaceholder: null,
    answers: {
      1: "SOF/VEL/VOX plus weight-based ribavirin for 24 weeks",
      2: "GP plus weight-based ribavirin for 16 weeks",
    },
  },
  surv003: {
    topic: "Hepatitis C",
    question:
      "What treatment do you prefer for the treatment of HCV patients with decompensated cirrhosis?",
    answerType: "multipleChoice",
    answerPlaceholder: null,
    answers: {
      1: "Ledipasvir-Sofosbuvir plus Ribavirin",
      2: "Sofosbuvir plus Ribavirin",
      3: "Sofosbuvir-Velpatasvir",
    },
  },
};

export default surveyData;
