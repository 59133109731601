import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";

import LandingPage from "./landing-page/landing-page.component";
import HomePage from "./home-page/home-page.component";
import AboutData from "./about-data/about-data.component";
import ContactUs from "./contact-us/contact-us.component";
import SearchResultsPage from "./search-results-page/search-results-page.component";
import Nav from "./nav/nav.component";
import ResultDataPage from "./result-data-page/result-data-page.component";
import SurveyPage from "./survey-page/survey-page.component";

import drugAndDiseaseDataObj from "./drugAndDiseaseData";
import surveyData from "./surveyData";

import "./App.css";
import "./general.css";

const App = () => {
  // ******************************
  // *** SEARCH TERMS STATE***
  // ******************************
  const [searchTerm, setSearchTerm] = useState("");
  // function to update the search term based on the value inputted by user
  // in the search bar
  const updateSearchTerm = (e) => {
    setSearchTerm(e.target.value);
  };

  // ******************************
  // *** SEARCH RESULTS STATE***
  // ******************************
  // used throughout the app - represents the current pool of search results based on the
  // user's search input
  const [searchResults, setSearchResults] = useState([]);

  // FUNCTION
  // expected arguments: none
  // dependencies: drugAndDiseaseData obj import
  // expected output: array of the full names of each drug and disease that is included
  // in the platform's pool of potential search results. The name is generated differently depending on
  // data type (drug or disease)Each search result would have an associated result-data-page component.
  const getAllSearchResults = () => {
    const allSearchResults = Object.values(drugAndDiseaseDataObj).map(
      (drugDisease) => {
        return drugDisease.fullName;
      }
    );

    return allSearchResults;
  };

  // EFFECT
  // dependency: searchTerm state
  // utilizes about getAllSearchResults function to create a filtered array
  // of search results that include the current search term within the search
  // result's full name. Sets the searchResults state to this filtered array.
  // Effect should only trigger every time the searchTerm is updated when user
  // types anything into the search-bar component
  useEffect(() => {
    // since array.includes method is case sensitve, initialize an all lower case
    // version of the current search term
    const currentSearchTerm = searchTerm.toLowerCase();
    const allSearchResults = getAllSearchResults();
    const filteredSearchResults = allSearchResults.filter((result) => {
      // create an all lower case version of the result element
      const resultElement = result.toLowerCase();
      return resultElement.includes(currentSearchTerm);
    });
    setSearchResults(filteredSearchResults);
  }, [searchTerm]);

  // ******************************
  // ***RESULT PAGE SCROLL TOP STATE***
  // ******************************
  // Boolean that keeps track of whether we want the ResultDataPage component page to scroll to the top upon a re-render.
  // We want this to be FALSE if we are re-rendering due to an insight search term being entered by the user
  const [resultPageScrollTop, setResultPageScrollTop] = useState(true);

  // ******************************
  // ***Global Nav Open STATE***
  // ******************************
  const [navOpen, setNavOpen] = useState(false);
  // Boolean that keeps track of whether the global nav is opened or closed and it is
  // used in the closeNav function in the nav component, which is used as part of the
  // onClick event listener of the full-app-container. If the NavOpen status is true, then
  // this event listener will close the global nav window if it detects a click outside of hte global nav
  // window

  // ******************************
  // ***Global Nav Search Bar Shown STATE***
  // ******************************
  const [globalNavSearchBarShown, setGlobalNavSearchBarShown] = useState(false);

  // **************************************
  // ***DATA INSIGHTS DETAIL MODAL STATE***
  // **************************************
  // this state represents whether the insights detail modal
  // is being shown at any given time. This is controlled by the
  // 'More Details' button on the bottom of each data-insights-card
  // component
  const [showDataInsightDetailsModal, setShowDataInsightDetailsModal] =
    useState(false);

  // ***************************************
  // ***INSIGHTS FILTER MODAL SHOWN STATE***
  // ***************************************
  // this state determines whether the insights-filter-modal component
  // will be visible within/while in result-data-page component
  const [showInsightsFilterModal, setShowInsightsFilterModal] = useState(false);

  // *****************************************
  // ***SHOW DISCLAIMER MODAL STATE***
  // *****************************************
  // this state keeps track of whether we are rendering the disclaimer-modal
  // when the web app first loads, we do want this disclaimer to appear, so initial
  // state will be set to true
  // it is not set back to true anywhere in the web, so it only appears once
  const [showDisclaimerModal, setShowDisclaimerModal] = useState(true);

  // **************************************
  // GLOBAL BACKGROUND BLUR Functionality
  // **************************************

  const [backgroundBlurOn, setBackgroundBlurOn] = useState(true);

  // FUNCTION
  // this function is used to add a blur to the background when having a menu or modal
  // present on the screen. It does this be setting the backgroundBlurOn state to
  // true or false depending on the desired behavior from the source of the
  // function call
  const blurBackground = (action) => {
    // const bodyElement = document.querySelector("body");

    if (action === "blurOn") {
      setBackgroundBlurOn(true);
      // we can re-implement the 'preven-scroll' CSS class addition/removal
      // after we figure out how to prevent the auto-scroll to the top of the page
      // that occurs when there is a re-render
      // bodyElement.classList.add("prevent-scroll");
    }
    if (action === "blurOff") {
      setBackgroundBlurOn(false);
      // bodyElement.classList.remove("prevent-scroll");
    }
  };

  // HANLDER FUNCTION = for when user clicks on the background-blur element
  // we can assume that whenever background-blur is present and sitting on top of
  // the entire app, that some menu or modal is open. Handle closing the respective
  // menu or modal accordingly

  const handleBlurBackgroundClick = () => {
    if (navOpen === true) {
      setNavOpen(false);
    }

    if (showDataInsightDetailsModal === true) {
      setShowDataInsightDetailsModal(false);
    }

    if (showInsightsFilterModal === true) {
      setShowInsightsFilterModal(false);
    }

    if (showDisclaimerModal === true) {
      setShowDisclaimerModal(false);
    }

    blurBackground("blurOff");
  };

  // *****************************************
  // ***CLOSE MODAL SCROLL TO ELEMENT STATE***
  // *****************************************
  // this state simply keeps track of the element we want to scroll back to
  // after closing any given modal window (namely, insights-filter-modal and data-isight-details-modal components)
  const [closeModalScrollToElement, setCloseModalScrollToElement] =
    useState("");

  // EFFECT
  // this effect complements the closeModalScrollToElement state. Upon re-rendering
  // of the result-data-page component, if there is an element that we need to scroll back to
  // then we will scroll that into view so it is in the center
  useEffect(() => {
    if (closeModalScrollToElement !== "") {
      closeModalScrollToElement.scrollIntoView({ block: "center" });
      // and then on cleanup, we will clear the element from this state so that
      // we do not continue to scroll back to it in future renders
      return function cleanup() {
        setCloseModalScrollToElement("");
      };
    }
  });

  // AVAILABLE and COMPLETED SURVEYS
  // initialize an array with all of the uncompleted surveys for this user
  const completedSurveys = [];
  const availableSurveys = Object.keys(surveyData).filter((surveyId) => {
    const surveyCompleted = localStorage.getItem(surveyId);
    if (surveyCompleted) completedSurveys.push(surveyId);
    return surveyCompleted ? null : surveyId;
  });

  // TEMPORARY STATE
  // *****************************************
  // ***MVP SCROLL WAIT LIST LANDING PAGE STATE***
  // *****************************************
  // temporary state for the demo, so that the user is scrolled to the wait list
  // section of the landing-page element when they click on the global-nav-wait-list-btn
  const [scrollWaitList, setScrollWaitList] = useState(false);

  // *************************
  // SHOW WAIT LIST DEMO BTN STATE
  // *************************
  // this state will make the 'back to demo' btn within the wait list form visible
  // and is true when the user pressses the 'Join Wailist' button in the global-nav component
  // in the the demo
  const [showWaitListDemoBtn, setShowWaitListDemoBtn] = useState(false);

  return (
    <div className="App">
      <div
        className={
          backgroundBlurOn === true
            ? "background-blur blur-on"
            : "background-blur"
        }
        onClick={handleBlurBackgroundClick}
      ></div>
      <Routes>
        <Route
          path="/"
          element={
            <LandingPage
              blurBackground={blurBackground}
              setShowDisclaimerModal={setShowDisclaimerModal}
              scrollWaitList={scrollWaitList}
              setScrollWaitList={setScrollWaitList}
              showWaitListDemoBtn={showWaitListDemoBtn}
              setShowWaitListDemoBtn={setShowWaitListDemoBtn}
            />
          }
        />
        <Route
          path="demo"
          element={
            <Nav
              navOpen={navOpen}
              setNavOpen={setNavOpen}
              setSearchTerm={setSearchTerm}
              updateSearchTerm={updateSearchTerm}
              searchTerm={searchTerm}
              searchResults={searchResults}
              globalNavSearchBarShown={globalNavSearchBarShown}
              setGlobalNavSearchBarShown={setGlobalNavSearchBarShown}
              blurBackground={blurBackground}
              showDisclaimerModal={showDisclaimerModal}
              setShowDisclaimerModal={setShowDisclaimerModal}
              availableSurveys={availableSurveys}
              setScrollWaitList={setScrollWaitList}
              setShowWaitListDemoBtn={setShowWaitListDemoBtn}
            />
          }
        >
          <Route
            index
            element={
              <HomePage
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                updateSearchTerm={updateSearchTerm}
                searchResults={searchResults}
                setGlobalNavSearchBarShown={setGlobalNavSearchBarShown}
              />
            }
          />
          <Route
            path="about-data"
            element={
              <AboutData
                setGlobalNavSearchBarShown={setGlobalNavSearchBarShown}
              />
            }
          />
          <Route
            path="contact"
            element={
              <ContactUs
                setGlobalNavSearchBarShown={setGlobalNavSearchBarShown}
              />
            }
          />
          <Route
            path="search-results-page"
            element={
              <SearchResultsPage
                searchTerm={searchTerm}
                updateSearchTerm={updateSearchTerm}
                searchResults={searchResults}
                setGlobalNavSearchBarShown={setGlobalNavSearchBarShown}
              />
            }
          />
          <Route
            path=":resultPageId"
            element={
              <ResultDataPage
                resultPageScrollTop={resultPageScrollTop}
                setResultPageScrollTop={setResultPageScrollTop}
                setGlobalNavSearchBarShown={setGlobalNavSearchBarShown}
                blurBackground={blurBackground}
                setCloseModalScrollToElement={setCloseModalScrollToElement}
                showDataInsightDetailsModal={showDataInsightDetailsModal}
                setShowDataInsightDetailsModal={setShowDataInsightDetailsModal}
                showInsightsFilterModal={showInsightsFilterModal}
                setShowInsightsFilterModal={setShowInsightsFilterModal}
              />
            }
          />
          <Route
            path="surveys"
            element={
              <SurveyPage
                surveyData={surveyData}
                availableSurveys={availableSurveys}
                completedSurveys={completedSurveys}
                setGlobalNavSearchBarShown={setGlobalNavSearchBarShown}
              />
            }
          />
        </Route>
      </Routes>
    </div>
  );
};

export default App;
