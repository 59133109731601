import React, { useState } from "react";

import "../general.css";
import "./patient-builder.component.css";

const PatientBuilder = (props) => {
  // destructure props
  const { insightsFilter, setInsightsFilter } = props;
  // destructure props into the individual patient builder properties
  // so that it can inform how whether the checkboxes below render with a check or not
  const {
    treatmentNaive,
    treatmentExperienced,
    compensatedCirrhosis,
    decompensatedCirrhosis,
    genotype1,
    genotype2,
    genotype3,
    genotype4,
    genotype5or6,
    hivHcvCoinfection,
    organsFromHcvDonors,
    renalImpairment,
    kidneyTransplant,
    acuteInfection,
    pregnant,
    pediatric,
    pwid,
    msm,
    corrections,
  } = insightsFilter.patientBuilder;

  // HANDLER FUNCTION
  // handle clicks on the patient builder buttons and update insightsFilter
  // state in result-data-page component accordingly
  const handlePatientBuilderBtnClick = (e) => {
    // initialize a const to the id of the button clicked which will tell us
    // which patient characteristic property will need to be updated in the
    // insightsFilter state
    const clickedPatientCharacteristic = e.target.id;
    // update insighstFilter state in result-data-page component
    setInsightsFilter((prevInsightsFilter) => {
      // initialize a const to a shallow copy of the previous insightsFilter state
      // this will become the next insightsFilter state that we will return out of the function
      const nextInsightsFilter = { ...prevInsightsFilter };
      // and initialize 2 consts to shallow copies of the patientBuilder obj from
      // the previous insightsFilter state

      // the prevPatientBuilder will be used to keep track of the previous value of
      // properties in the previous insightsFilter state
      const prevPatientBuilder = { ...prevInsightsFilter.patientBuilder };
      // this function will maniuplate + update the nextPaitentBuilder based on the
      // clicked button
      const nextPatientBuilder = { ...prevInsightsFilter.patientBuilder };

      // update the property in nextPatientBuilder with the same name as the id of the patient builder btn that was clicked
      // update it to the oppositve value (true/false) that it was in the previous insightsFilter state
      nextPatientBuilder[clickedPatientCharacteristic] =
        prevPatientBuilder[clickedPatientCharacteristic] === false
          ? true
          : false;

      // if the button clicked was one of the treatment line buttons (treatmentNaive or treatmentExperienced)
      if (
        clickedPatientCharacteristic === "treatmentNaive" ||
        clickedPatientCharacteristic === "treatmentExperienced"
      ) {
        // and if we just updated the associated property in nextPatientBuilder to true
        if (nextPatientBuilder[clickedPatientCharacteristic] === true) {
          // then also ensure we update the property of the other treatment line button to false
          const otherTreatmentLineCharacteristic =
            clickedPatientCharacteristic === "treatmentNaive"
              ? "treatmentExperienced"
              : "treatmentNaive";

          nextPatientBuilder[otherTreatmentLineCharacteristic] = false;
        }
      }
      // same for compensated and decompensated cirrhosis
      if (
        clickedPatientCharacteristic === "compensatedCirrhosis" ||
        clickedPatientCharacteristic === "decompensatedCirrhosis"
      ) {
        // and if we just updated the associated property in nextPatientBuilder to true
        if (nextPatientBuilder[clickedPatientCharacteristic] === true) {
          // then also ensure we update the property of the other treatment line button to false
          const otherTreatmentLineCharacteristic =
            clickedPatientCharacteristic === "compensatedCirrhosis"
              ? "decompensatedCirrhosis"
              : "compensatedCirrhosis";

          nextPatientBuilder[otherTreatmentLineCharacteristic] = false;
        }
      }
      // finally, update the patientBuilder property in the nextInsightsFilter
      // to be equal to a shallow copy of the nextPatientBuilder obj we have been
      // manipulating in this function
      nextInsightsFilter.patientBuilder = { ...nextPatientBuilder };

      // return out the nextInsightsFilter obj from this function to update
      // insightsFilter state accordingly
      return nextInsightsFilter;
    });
  };

  // *************************
  // SHOW MORE PATIENT ATTRIBUTES STATE
  // *************************
  const [showMoreAttributes, setShowMoreAttributes] = useState(false);

  return (
    <div className="patient-builder-container">
      <h3 className="patient-builder-header">Build Your Patient</h3>
      <p className="patient-builder-description">
        Click to add patient attribute filters to find insights
        <br />
        specific to your patient case
      </p>
      <div className="patient-builder-filter-buttons-container grid grid--2-cols">
        <button
          className={
            // conditionally render the patient-builder-filter buttons based on the associated property
            // within the patientBuilder obj within insightsFilter state
            // if the associated value is true, then render a pressed button
            treatmentNaive === true
              ? "patient-builder-treatment-naive-btn patient-builder-btn patient-builder-btn-pressed"
              : "patient-builder-treatment-naive-btn patient-builder-btn"
          }
          id="treatmentNaive"
          type="button"
          onClick={handlePatientBuilderBtnClick}
        >
          Treatment Naive
          <img
            className={
              // similarly to the button above, conditionally render this checkmark icon
              // to indicate to the user that this patient characteristic filter is now active
              treatmentNaive === true
                ? "patient-builder-btn-active-icon"
                : "patient-builder-btn-active-icon hidden"
            }
            src={require("../img/check-mark.png")}
            alt="check mark icon"
          />
        </button>
        <button
          className={
            treatmentExperienced === true
              ? "patient-builder-treatment-experienced-btn patient-builder-btn patient-builder-btn-pressed"
              : "patient-builder-treatment-experienced-btn patient-builder-btn"
          }
          id="treatmentExperienced"
          type="button"
          onClick={handlePatientBuilderBtnClick}
        >
          Treatment Experienced
          <img
            className={
              treatmentExperienced === true
                ? "patient-builder-btn-active-icon"
                : "patient-builder-btn-active-icon hidden"
            }
            src={require("../img/check-mark.png")}
            alt="check mark icon"
          />
        </button>
        <button
          className={
            compensatedCirrhosis === true
              ? "patient-builder-compensated-cirrhosis-btn patient-builder-btn patient-builder-btn-pressed"
              : "patient-builder-compensated-cirrhosis-btn patient-builder-btn"
          }
          id="compensatedCirrhosis"
          type="button"
          onClick={handlePatientBuilderBtnClick}
        >
          Compensated Cirrhosis
          <img
            className={
              compensatedCirrhosis === true
                ? "patient-builder-btn-active-icon"
                : "patient-builder-btn-active-icon hidden"
            }
            src={require("../img/check-mark.png")}
            alt="check mark icon"
          />
        </button>
        <button
          className={
            decompensatedCirrhosis === true
              ? "patient-builder-decompensated-cirrhosis-btn patient-builder-btn patient-builder-btn-pressed"
              : "patient-builder-decompensated-cirrhosis-btn patient-builder-btn"
          }
          id="decompensatedCirrhosis"
          type="button"
          onClick={handlePatientBuilderBtnClick}
        >
          Decompensated Cirrhosis
          <img
            className={
              decompensatedCirrhosis === true
                ? "patient-builder-btn-active-icon"
                : "patient-builder-btn-active-icon hidden"
            }
            src={require("../img/check-mark.png")}
            alt="check mark icon"
          />
        </button>
        <button
          className={
            genotype1 === true
              ? "patient-builder-genotype-1-btn patient-builder-btn patient-builder-btn-pressed"
              : "patient-builder-genotype-1-btn patient-builder-btn"
          }
          id="genotype1"
          type="button"
          onClick={handlePatientBuilderBtnClick}
        >
          Genotype 1
          <img
            className={
              genotype1 === true
                ? "patient-builder-btn-active-icon"
                : "patient-builder-btn-active-icon hidden"
            }
            src={require("../img/check-mark.png")}
            alt="check mark icon"
          />
        </button>
        <button
          className={
            genotype2 === true
              ? "patient-builder-genotype-2-btn patient-builder-btn patient-builder-btn-pressed"
              : "patient-builder-genotype-2-btn patient-builder-btn"
          }
          id="genotype2"
          type="button"
          onClick={handlePatientBuilderBtnClick}
        >
          Genotype 2
          <img
            className={
              genotype2 === true
                ? "patient-builder-btn-active-icon"
                : "patient-builder-btn-active-icon hidden"
            }
            src={require("../img/check-mark.png")}
            alt="check mark icon"
          />
        </button>
        <button
          className={
            genotype3 === true
              ? "patient-builder-genotype-3-btn patient-builder-btn patient-builder-btn-pressed"
              : "patient-builder-genotype-3-btn patient-builder-btn"
          }
          id="genotype3"
          type="button"
          onClick={handlePatientBuilderBtnClick}
        >
          Genotype 3
          <img
            className={
              genotype3 === true
                ? "patient-builder-btn-active-icon"
                : "patient-builder-btn-active-icon hidden"
            }
            src={require("../img/check-mark.png")}
            alt="check mark icon"
          />
        </button>
        <button
          className={
            genotype4 === true
              ? "patient-builder-genotype-4-btn patient-builder-btn patient-builder-btn-pressed"
              : "patient-builder-genotype-4-btn patient-builder-btn"
          }
          id="genotype4"
          type="button"
          onClick={handlePatientBuilderBtnClick}
        >
          Genotype 4
          <img
            className={
              genotype4 === true
                ? "patient-builder-btn-active-icon"
                : "patient-builder-btn-active-icon hidden"
            }
            src={require("../img/check-mark.png")}
            alt="check mark icon"
          />
        </button>
        <button
          className={
            genotype5or6 === true
              ? "patient-builder-genotype-5-or-6-btn patient-builder-btn patient-builder-btn-pressed"
              : "patient-builder-genotype-5-or-6-btn patient-builder-btn"
          }
          id="genotype5or6"
          type="button"
          onClick={handlePatientBuilderBtnClick}
        >
          Genotype 5 or 6
          <img
            className={
              genotype5or6 === true
                ? "patient-builder-btn-active-icon"
                : "patient-builder-btn-active-icon hidden"
            }
            src={require("../img/check-mark.png")}
            alt="check mark icon"
          />
        </button>
        <button
          className={
            hivHcvCoinfection === true
              ? "patient-builder-hivHcvCoinfection-btn patient-builder-btn patient-builder-btn-pressed"
              : "patient-builder-hivHcvCoinfection-btn patient-builder-btn"
          }
          id="hivHcvCoinfection"
          type="button"
          onClick={handlePatientBuilderBtnClick}
        >
          HIV-HCV Co-infection
          <img
            className={
              hivHcvCoinfection === true
                ? "patient-builder-btn-active-icon"
                : "patient-builder-btn-active-icon hidden"
            }
            src={require("../img/check-mark.png")}
            alt="check mark icon"
          />
        </button>

        <button
          className={
            showMoreAttributes === false
              ? "hidden"
              : organsFromHcvDonors === true
              ? "patient-builder-organsFromHcvDonors-btn patient-builder-btn patient-builder-btn-pressed"
              : "patient-builder-organsFromHcvDonors-btn patient-builder-btn"
          }
          id="organsFromHcvDonors"
          type="button"
          onClick={handlePatientBuilderBtnClick}
        >
          Organs from HCV Donors
          <img
            className={
              organsFromHcvDonors === true
                ? "patient-builder-btn-active-icon"
                : "patient-builder-btn-active-icon hidden"
            }
            src={require("../img/check-mark.png")}
            alt="check mark icon"
          />
        </button>
        <button
          className={
            showMoreAttributes === false
              ? "hidden"
              : renalImpairment === true
              ? "patient-builder-renalImpairment-btn patient-builder-btn patient-builder-btn-pressed"
              : "patient-builder-renalImpairment-btn patient-builder-btn"
          }
          id="renalImpairment"
          type="button"
          onClick={handlePatientBuilderBtnClick}
        >
          Renal Impairment
          <img
            className={
              renalImpairment === true
                ? "patient-builder-btn-active-icon"
                : "patient-builder-btn-active-icon hidden"
            }
            src={require("../img/check-mark.png")}
            alt="check mark icon"
          />
        </button>
        <button
          className={
            showMoreAttributes === false
              ? "hidden"
              : kidneyTransplant === true
              ? "patient-builder-kidneyTransplant-btn patient-builder-btn patient-builder-btn-pressed"
              : "patient-builder-kidneyTransplant-btn patient-builder-btn"
          }
          id="kidneyTransplant"
          type="button"
          onClick={handlePatientBuilderBtnClick}
        >
          Kidney Transplant
          <img
            className={
              kidneyTransplant === true
                ? "patient-builder-btn-active-icon"
                : "patient-builder-btn-active-icon hidden"
            }
            src={require("../img/check-mark.png")}
            alt="check mark icon"
          />
        </button>
        <button
          className={
            showMoreAttributes === false
              ? "hidden"
              : acuteInfection === true
              ? "patient-builder-acuteInfection-btn patient-builder-btn patient-builder-btn-pressed"
              : "patient-builder-acuteInfection-btn patient-builder-btn"
          }
          id="acuteInfection"
          type="button"
          onClick={handlePatientBuilderBtnClick}
        >
          Acute Infection
          <img
            className={
              acuteInfection === true
                ? "patient-builder-btn-active-icon"
                : "patient-builder-btn-active-icon hidden"
            }
            src={require("../img/check-mark.png")}
            alt="check mark icon"
          />
        </button>
        <button
          className={
            showMoreAttributes === false
              ? "hidden"
              : pregnant === true
              ? "patient-builder-pregnant-btn patient-builder-btn patient-builder-btn-pressed"
              : "patient-builder-pregnant-btn patient-builder-btn"
          }
          id="pregnant"
          type="button"
          onClick={handlePatientBuilderBtnClick}
        >
          Pregnant
          <img
            className={
              pregnant === true
                ? "patient-builder-btn-active-icon"
                : "patient-builder-btn-active-icon hidden"
            }
            src={require("../img/check-mark.png")}
            alt="check mark icon"
          />
        </button>
        <button
          className={
            showMoreAttributes === false
              ? "hidden"
              : pediatric === true
              ? "patient-builder-pediatric-btn patient-builder-btn patient-builder-btn-pressed"
              : "patient-builder-pediatric-btn patient-builder-btn"
          }
          id="pediatric"
          type="button"
          onClick={handlePatientBuilderBtnClick}
        >
          Pediatric
          <img
            className={
              pediatric === true
                ? "patient-builder-btn-active-icon"
                : "patient-builder-btn-active-icon hidden"
            }
            src={require("../img/check-mark.png")}
            alt="check mark icon"
          />
        </button>
        <button
          className={
            showMoreAttributes === false
              ? "hidden"
              : pwid === true
              ? "patient-builder-pwid-btn patient-builder-btn patient-builder-btn-pressed"
              : "patient-builder-pwid-btn patient-builder-btn"
          }
          id="pwid"
          type="button"
          onClick={handlePatientBuilderBtnClick}
        >
          PWID
          <img
            className={
              pwid === true
                ? "patient-builder-btn-active-icon"
                : "patient-builder-btn-active-icon hidden"
            }
            src={require("../img/check-mark.png")}
            alt="check mark icon"
          />
        </button>
        <button
          className={
            showMoreAttributes === false
              ? "hidden"
              : msm === true
              ? "patient-builder-msm-btn patient-builder-btn patient-builder-btn-pressed"
              : "patient-builder-msm-btn patient-builder-btn"
          }
          id="msm"
          type="button"
          onClick={handlePatientBuilderBtnClick}
        >
          MSM
          <img
            className={
              msm === true
                ? "patient-builder-btn-active-icon"
                : "patient-builder-btn-active-icon hidden"
            }
            src={require("../img/check-mark.png")}
            alt="check mark icon"
          />
        </button>
        <button
          className={
            showMoreAttributes === false
              ? "hidden"
              : corrections === true
              ? "patient-builder-corrections-btn patient-builder-btn patient-builder-btn-pressed"
              : "patient-builder-corrections-btn patient-builder-btn"
          }
          id="corrections"
          type="button"
          onClick={handlePatientBuilderBtnClick}
        >
          Corrections
          <img
            className={
              corrections === true
                ? "patient-builder-btn-active-icon"
                : "patient-builder-btn-active-icon hidden"
            }
            src={require("../img/check-mark.png")}
            alt="check mark icon"
          />
        </button>
      </div>
      <button
        className={
          showMoreAttributes === true
            ? "hidden"
            : "patient-builder-show-more-attributes-btn patient-builder-btn"
        }
        type="button"
        onClick={() => {
          setShowMoreAttributes(true);
        }}
      >
        Show more patient attribute filters
      </button>
      <button
        className={
          showMoreAttributes === false
            ? "hidden"
            : "patient-builder-show-more-attributes-btn show-less-attributes-btn patient-builder-btn"
        }
        type="button"
        onClick={() => {
          setShowMoreAttributes(false);
        }}
      >
        Show less filters
      </button>
    </div>
  );
};

export default PatientBuilder;
