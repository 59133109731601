import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
// import { Link } from "react-router-dom";

import DataInsightCard from "../data-insight-card/data-insight-card.component";
import DataInsightDetailsModal from "../data-insight-details-modal/data-insight-details-modal.component";
import InsightsFilterModal from "../insights-filter-modal/insights-filter-modal.component";
import PatientBuilder from "../patient-builder/patient-builder.component";
import InsightsFilterFlags from "../insights-filter-flags/insights-filter-flags.component";

import drugAndDiseaseDataObj from "../drugAndDiseaseData";
import resultPageIdReferenceData from "../resultPageIdReference";

import "../general.css";
import "./result-data-page.component.css";

const ResultDataPage = (props) => {
  // grab the params from the url which contains the resultPageId of the drug/disease page we are trying to render
  const params = useParams();
  // initialize several consts to the pieces of data needed from the respective drug/disease property
  // within drugAndDiseaseData in props
  const selectedResultPageId = params.resultPageId;
  const selectedResultName =
    drugAndDiseaseDataObj[selectedResultPageId].fullName;
  const allInsights = {
    ...drugAndDiseaseDataObj[selectedResultPageId].allInsights,
  };
  const allInsightsKeys = Object.keys(allInsights);
  const {
    resultPageScrollTop,
    setResultPageScrollTop,
    showDataInsightDetailsModal,
    setShowDataInsightDetailsModal,
    showInsightsFilterModal,
    setShowInsightsFilterModal,
    setGlobalNavSearchBarShown,
    blurBackground,
    setCloseModalScrollToElement,
  } = props;

  // initialize an obj with keys of every unique drug/disease ID contained
  // within the insights for the respective result-data-page component
  // values will be objects with properties including the respective name associated with each id
  // and the type (drug/disease) associated with each id
  // used as a prop to pass into insights-filter-modal component and insights-filter-flag components
  const allDrugsOrDiseasesObj = {};

  Object.values(allInsights).forEach((insightObj) => {
    insightObj.data.forEach((dataItem) => {
      if (!allDrugsOrDiseasesObj[dataItem.item]) {
        allDrugsOrDiseasesObj[dataItem.item] = {
          name: resultPageIdReferenceData[dataItem.item],
          type: drugAndDiseaseDataObj[dataItem.item].type,
        };
      }
    });
  });
  // EFFECT
  // make the global nav search bar component visible
  // on this page
  useEffect(() => {
    setGlobalNavSearchBarShown(true);
  });

  // *********************************
  // ***INSIGHTS FILTER STATE***
  // *********************************
  // this state represents the filters that are currently being applied
  // to the set of data-insight-card components that are being shown
  // to the user on the result-data-page at any given time
  const [insightsFilter, setInsightsFilter] = useState({
    searchTerm: "",
    // date: {
    //   beginning: "",
    //   end: "",
    // },
    drugOrDiseaseId: "",
    patientBuilder: {
      treatmentNaive: false,
      treatmentExperienced: false,
      compensatedCirrhosis: false,
      decompensatedCirrhosis: false,
      genotype1: false,
      genotype2: false,
      genotype3: false,
      genotype4: false,
      genotype5or6: false,
      hivHcvCoinfection: false,
      organsFromHcvDonors: false,
      renalImpairment: false,
      kidneyTransplant: false,
      acuteInfection: false,
      pregnant: false,
      pediatric: false,
      pwid: false,
      msm: false,
      corrections: false,
    },
    clinicianType: {
      physician: true,
      pharmacist: true,
      nursePractitioner: true,
      physicianAssistant: true,
    },
  });

  // *****************************************
  // ***NEXT INSIGHTS FILTERS STATE***
  // *****************************************
  // this state keeps track of the next set of filters that the user
  // is about to make 'active' when they setState of insightsFilter
  // upon pressing the Filter Insights button in the insights-filter-modal component
  // this state is passed as a prop and used in insights-filter-modal component
  const [nextInsightsFilter, setNextInsightsFilter] = useState({
    ...insightsFilter,
  });

  // HANDLER FUNCTION
  // to handle changes to the insights search term input and update the searchTerm
  // property in the insightsFilter state accordingly
  const handleInsightsSearchTermChange = (e) => {
    setResultPageScrollTop(false);

    const currentSearchTerm = e.target.value;

    setInsightsFilter((prevInsightsFilter) => {
      const newInsightsFilter = { ...prevInsightsFilter };
      newInsightsFilter.searchTerm = currentSearchTerm;
      return newInsightsFilter;
    });
  };

  // EFFECT
  useEffect(() => {
    // if the scrollToTop Ref is true, then scroll the user
    // to the top of the page upon re-render
    if (resultPageScrollTop === true) window.scrollTo(0, 0);
  }, [selectedResultPageId, resultPageScrollTop]);

  // HANDLER FUNCTION
  // handles clicks on the 'Filter Insights' button which will make the
  // insights-filter-modal component visible on the screen and also update the
  // nextInsightsFilter state so that it matches the current insightsFilter state
  // and is ready for the user to manipulate + update nextInsightsFilter / insightsFilter state
  // in the insights-filter-modal window
  const handleFilterInsightsBtnClick = (e) => {
    setShowInsightsFilterModal(true);
    setNextInsightsFilter({ ...insightsFilter });
    // also set the closeModalScrollToElement state to the clicked btn so that
    // we can scroll back to the filter insights button when we close the
    // insights-filter-modal window
    setCloseModalScrollToElement(e.target);
  };

  // *****************************************
  // ***CURRENT DATA INSIGHTS DETAIL STATE***
  // *****************************************
  const [selectedDataInsightId, setSelectedDataInsightId] = useState("");

  // **********************************************************
  // ***CURRENT DATA INSIGHTS DETAIL TOTAL RESPONDENTS STATE***
  // **********************************************************
  // in future - this number will represent the total # of respondents BASED ON THE
  // CURRENT ACTIVE FILTERS
  const [
    selectedDataInsightRespondentsTotal,
    setSelectedDataInsightRespondentsTotal,
  ] = useState(0);

  // **********************************************************
  // ***CURRENT DATA INSIGHTS DETAIL SORTED DATA ITEMS STATE***
  // **********************************************************
  const [selectedDataInsightSortedItems, setSelectedDataInsightSortedItems] =
    useState([]);

  // **********************************************************
  // ***CURRENT DATA INSIGHTS DETAIL TOP DATA ITEMS STATE***
  // **********************************************************
  const [selectedDataInsightTopItems, setSelectedDataInsightTopItems] =
    useState([]);

  // Create 2 states to store the original and filtered insightsRationale
  // arrays, respectively. We store 2 so that we can manipulate the filteredInsightsRationale
  // state from clicks on the filter drop-down menu within the data-insight-details-modal
  // component

  // this insights rationale state is set when the user clicks on 'more details'
  // button in the data-insight-card component
  // **********************************************************
  // ***INSIGHTS RATIONALE STATE***
  // **********************************************************
  const [insightsRationale, setInsightsRationale] = useState([]);

  // ***************************************
  // ***FILTERED INSIGHTS RATIONALE STATE***
  // ***************************************
  const [filteredInsightsRationale, setFilteredInsightsRationale] = useState(
    []
  );

  // EFFECT
  // this effect checks for whether there are any data-insight-card components
  // that were rendered meaning that there was at least 1 insight that matches ALL of
  // the user-inputted filters and/or search term
  // if there are ZERO matches, then we want to unhide the no-matching-insights-reminder
  // container, if there is at least 1 match, then we want to make sure the same container
  // is hidden
  useEffect(() => {
    const insightCardComponents = document
      .querySelector(".result-data-page-peer-insights-container")
      .querySelectorAll(".data-insight-container");
    const noMatchingInsightsContainer = document.querySelector(
      ".all-insights-no-matching-insights-reminder"
    );
    if (insightCardComponents.length === 0) {
      noMatchingInsightsContainer.classList.remove("hidden");
    }
    if (insightCardComponents.length > 0)
      noMatchingInsightsContainer.classList.add("hidden");
  });

  // ***************************************
  // ***INSIGHTS RATINGS STATE***
  // ***************************************
  // this state will keep track of the helpful/not helpful ratings that the user
  // may give to each insight while using the app during this session
  // NOTE: IN FUTURE, WE WILL BE PULLING THE INSIGHT RATINGS FOR THE INDIVIDUAL USER PROFILE
  // FROM A DATABASE AND WE WILL NOT BE USING STATE TO MANAGE THIS
  const [insightsRatings, setInsightsRatings] = useState(() => {
    const insightsRatingsObj = {};
    allInsightsKeys.forEach((insightKey) => {
      const storedRating = localStorage.getItem(insightKey);
      storedRating
        ? (insightsRatingsObj[insightKey] = storedRating)
        : (insightsRatingsObj[insightKey] = "");
    });
    return insightsRatingsObj;
  });

  // ***************************************
  // ***RATIONALE RATINGS STATE***
  // ***************************************
  // this state will keep track of the helpful/not helpful ratings that the user
  // may give to each rationale WITHIN each insight while using the app during this session
  // NOTE: IN FUTURE, WE WILL BE PULLING THE INSIGHT RATINGS FOR THE INDIVIDUAL USER PROFILE
  // FROM A DATABASE AND WE WILL NOT BE USING STATE TO MANAGE THIS
  const [rationaleRatings, setRationaleRatings] = useState(() => {
    const rationaleRatingsObj = {};
    allInsightsKeys.forEach((insightKey) => {
      rationaleRatingsObj[insightKey] = {};
      allInsights[insightKey].data.forEach((dataObj) => {
        dataObj.rationale.forEach((rationaleObj) => {
          if (
            !rationaleRatingsObj[insightKey][
              rationaleObj.item + rationaleObj.clinicianId
            ]
          ) {
            rationaleRatingsObj[insightKey][
              rationaleObj.item + rationaleObj.clinicianId
            ] = "";
          }
        });
      });
    });
    return rationaleRatingsObj;
  });

  return (
    <section className="result-data-page-container container">
      <h1 className="result-data-page-title">
        {selectedResultName[0].toUpperCase() + selectedResultName.slice(1)}
      </h1>
      {/* <div className="result-data-page-treatment-visualized-container">
        <div className="result-data-page-treatment-visualized-title-container">
          <h2 className="result-data-page-subhead">
            {selectedResultName
              .split(" ")
              .map((word) => {
                return `${word[0].toUpperCase()}${word.slice(1)}`;
              })
              .join(" ")}{" "}
            Treatment Visualized
          </h2>
          <p className="result-data-page-subhead-description">
            Find helpful insights throughout the treatment algorithm and other
            treatment visualizations for {selectedResultName}
          </p>
          <div className="result-data-page-horizontal-line"></div>
        </div>
        <div className="result-data-page-treatment-visualizations-container">
          FPO
        </div>
      </div> */}
      <div className="result-data-page-peer-insights-section-container">
        <div className="result-data-page-peer-insights-title-container">
          <h2 className="result-data-page-subhead">Peer Survey Insights</h2>
          <p className="result-data-page-subhead-description">
            Search or browse through all available peer survey insights for
            <br />
            {selectedResultName}
          </p>
          {/* <Link className="result-data-page-survey-link-btn" to="/demo/surveys">
            See surveys available for you to complete
          </Link> */}
          <div className="result-data-page-horizontal-line"></div>
        </div>
        <div className="result-data-page-search-filter-flags-container">
          <PatientBuilder
            insightsFilter={insightsFilter}
            setInsightsFilter={setInsightsFilter}
          />
          <div className="result-data-page-search-and-filter-container">
            <div className="results-data-page-insights-search-bar-container">
              <p className="result-data-page-insights-search-bar-description">
                You can also search for specific key words:
              </p>
              <input
                className="results-data-page-insights-search-bar"
                type="search"
                value={insightsFilter.searchTerm}
                placeholder="Search for a specific insight"
                onChange={handleInsightsSearchTermChange}
              />
            </div>
            <button
              className="results-data-page-filter-insights-btn"
              type="button"
              onClick={handleFilterInsightsBtnClick}
            >
              Other filters
            </button>
          </div>

          <InsightsFilterFlags
            insightsFilter={insightsFilter}
            setInsightsFilter={setInsightsFilter}
            allDrugsOrDiseasesObj={allDrugsOrDiseasesObj}
          />
        </div>
        {/* iterate through all the allInsights for this drug/disease and render a
        data-insight-card component for each insight */}
        <div className="result-data-page-peer-insights-container grid grid--2-cols">
          {allInsightsKeys.map((insightId) => {
            return (
              <DataInsightCard
                key={insightId}
                // pass in the respective insight data that should be rendered within this
                // data-insight-card component
                insightObj={allInsights[insightId]}
                setShowDataInsightDetailsModal={setShowDataInsightDetailsModal}
                setSelectedDataInsightId={setSelectedDataInsightId}
                setSelectedDataInsightRespondentsTotal={
                  setSelectedDataInsightRespondentsTotal
                }
                setSelectedDataInsightSortedItems={
                  setSelectedDataInsightSortedItems
                }
                setSelectedDataInsightTopItems={setSelectedDataInsightTopItems}
                insightsFilter={insightsFilter}
                setInsightsRationale={setInsightsRationale}
                filteredInsightsRationale={filteredInsightsRationale}
                setFilteredInsightsRationale={setFilteredInsightsRationale}
                // The below 2 props will likely be removed once we implement a back-end + database
                insightsRatings={insightsRatings}
                setInsightsRatings={setInsightsRatings}
                setCloseModalScrollToElement={setCloseModalScrollToElement}
              />
            );
          })}
          <div className="all-insights-no-matching-insights-reminder hidden">
            Sorry, there are no insights matching <br />
            your search term or filtering options.
          </div>
        </div>
      </div>
      <DataInsightDetailsModal
        insightObj={allInsights[selectedDataInsightId]}
        insightsFilter={insightsFilter}
        showDataInsightDetailsModal={showDataInsightDetailsModal}
        setShowDataInsightDetailsModal={setShowDataInsightDetailsModal}
        selectedDataInsightRespondentsTotal={
          selectedDataInsightRespondentsTotal
        }
        selectedDataInsightSortedItems={selectedDataInsightSortedItems}
        selectedDataInsightTopItems={selectedDataInsightTopItems}
        blurBackground={props.blurBackground}
        setResultPageScrollTop={setResultPageScrollTop}
        insightsRationale={insightsRationale}
        filteredInsightsRationale={filteredInsightsRationale}
        setFilteredInsightsRationale={setFilteredInsightsRationale}
        // The below 2 props will likely be removed once we implement a back-end + database
        insightsRatings={insightsRatings}
        setInsightsRatings={setInsightsRatings}
        rationaleRatings={rationaleRatings}
        setRationaleRatings={setRationaleRatings}
      />
      <InsightsFilterModal
        showInsightsFilterModal={showInsightsFilterModal}
        setShowInsightsFilterModal={setShowInsightsFilterModal}
        blurBackground={blurBackground}
        insightsFilter={insightsFilter}
        setInsightsFilter={setInsightsFilter}
        nextInsightsFilter={nextInsightsFilter}
        setNextInsightsFilter={setNextInsightsFilter}
        selectedResultName={selectedResultName}
        drugDiseaseObj={drugAndDiseaseDataObj[selectedResultPageId]}
        allDrugsOrDiseasesObj={allDrugsOrDiseasesObj}
      />
    </section>
  );
};

export default ResultDataPage;
