const origDrugAndDiseaseDataObj = {
  "148BBA": {
    type: "drug",
    brandName: "Mavyret",
    genericName: "glecaprevir/pibrentasvir",
    routeOfAdmin: "oral",
    resultPageId: "148BBA",
    topInsights: ["148BBA-01", "148BBA-02", "148BBA-03"],
    allInsights: {
      "148BBA-01": {
        insightId: "148BBA-01",
        insightName: "Most commonly used therapies",
        insight: {
          insightSummaryRenderType: "topItems",
          insightSummarySingleLine: null,
          insightTopItemsThreshold: 0,
          insightIntro: "most frequently use",
          insightEnding: "to treat atopic dermatitis",
        },
        surveyQuestion: {
          intro:
            "To generate this data and insight, we asked the following question to dermatologists and dermatology clinicians:",
          question:
            "For every 10 atopic dermatitis patients you encounter in your practice, for how many of them would you say you end up trialing (insert drug here)? If you have not prescribed the drug, mark 'Never prescribed' in your response",
        },
        surveyThresholdExplanation: "",
        patientAttributes: {
          treatmentNaive: false,
          treatmentExperienced: true,
          decompensatedCirrhosis: false,
          injectionDrugUse: true,
        },
        dataPresentation: "circles",
        description: "Most commonly used therapies are:",
        dataDescription: "Clinician-reported frequency of use",
        numOfRespondents: {
          physician: 143,
          nursePractitioner: 54,
          physicianAssistant: 32,
          pharmacist: 42,
        },
        dataDenominator: 10,
        data: [
          {
            item: "148BBA",
            dataPoint: {
              all: 7.5,
              physician: 8,
              nursePractitioner: 9,
              physicianAssistant: 6,
              pharmacist: 7,
            },
            rationale: [
              {
                clinicianId: "ABC1",
                item: "148BBA",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
              {
                clinicianId: "ABC2",
                item: "148BBA",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
            ],
          },
          {
            item: "9TR81",
            dataPoint: {
              all: 3,
              physician: 2,
              nursePractitioner: 4,
              physicianAssistant: 2.5,
              pharmacist: 3.5,
            },
            rationale: [
              {
                clinicianId: "ABC3",
                item: "9TR81",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
              {
                clinicianId: "ABC4",
                item: "9TR81",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
            ],
          },
          {
            item: "7RRP1",
            dataPoint: {
              all: 8.5,
              physician: 8,
              nursePractitioner: 8.5,
              physicianAssistant: 7,
              pharmacist: 9,
            },
            rationale: [
              {
                clinicianId: "ABC7",
                item: "7RRP1",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
              {
                clinicianId: "ABC8",
                item: "7RRP1",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
            ],
          },
          {
            item: "148BBA",
            dataPoint: {
              all: 1,
              physician: 1.5,
              nursePractitioner: 2.5,
              physicianAssistant: 0.5,
              pharmacist: 1,
            },
            rationale: [
              {
                clinicianId: "ABC9",
                item: "148BBA",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
              {
                clinicianId: "ABC10",
                item: "148BBA",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
            ],
          },
        ],
      },
      "148BBA-02": {
        insightId: "148BBA-02",
        insightName: "Overall drug efficacy ratings",
        insight: {
          insightSummaryRenderType: "topItems",
          insightSummarySingleLine: null,
          insightTopItemsThreshold: 7,
          insightIntro: "feel that",
          insightEnding:
            "are the most effective drugs to treat atopic dermatitis",
        },
        surveyQuestion: {
          intro:
            "To generate this data and insight, we asked the following question to dermatologists and dermatology clinicians:",
          question:
            "Please rate the following drugs on a scale of 1-10 (1 being almost not effective at all and 10 being extremely effective) for overall treatment of atopic dermatitis. If you have not prescribed the drug, mark 'Never prescribed' in your response",
        },
        surveyThresholdExplanation:
          "Only drugs with a rating of 7/10 or higher were included as one of the 'most effective' drugs",
        patientAttributes: {
          treatmentNaive: false,
          treatmentExperienced: true,
          decompensatedCirrhosis: false,
          injectionDrugUse: true,
        },
        dataPresentation: "circles",
        description: "Clinicians feel the following drugs are most effective:",
        dataDescription: "Clinician-reported efficacy ratings",
        numOfRespondents: {
          physician: 113,
          nursePractitioner: 24,
          physicianAssistant: 12,
          pharmacist: 61,
        },
        dataDenominator: 10,
        data: [
          {
            item: "148BBA",
            dataPoint: {
              all: 7.5,
              physician: 8,
              nursePractitioner: 9,
              physicianAssistant: 6,
              pharmacist: 7,
            },
            rationale: [
              {
                clinicianId: "ABC1",
                item: "148BBA",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "EFFICACY Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
              {
                clinicianId: "ABC2",
                item: "148BBA",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
            ],
          },
          {
            item: "9TR81",
            dataPoint: {
              all: 3,
              physician: 2,
              nursePractitioner: 4,
              physicianAssistant: 2.5,
              pharmacist: 3.5,
            },
            rationale: [
              {
                clinicianId: "ABC3",
                item: "9TR81",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
              {
                clinicianId: "ABC4",
                item: "9TR81",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
            ],
          },
          {
            item: "LKA088F",
            dataPoint: {
              all: 2.5,
              physician: 1,
              nursePractitioner: 5,
              physicianAssistant: 4,
              pharmacist: 3,
            },
            rationale: [
              {
                clinicianId: "ABC5",
                item: "LKA088F",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "EFFICACY Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
              {
                clinicianId: "ABC6",
                item: "LKA088F",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
            ],
          },
          {
            item: "7RRP1",
            dataPoint: {
              all: 8.5,
              physician: 8,
              nursePractitioner: 8.5,
              physicianAssistant: 7,
              pharmacist: 9,
            },
            rationale: [
              {
                clinicianId: "ABC7",
                item: "7RRP1",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
              {
                clinicianId: "ABC8",
                item: "7RRP1",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
            ],
          },
          {
            item: "148BBA",
            dataPoint: {
              all: 1,
              physician: 1.5,
              nursePractitioner: 2.5,
              physicianAssistant: 0.5,
              pharmacist: 1,
            },
            rationale: [
              {
                clinicianId: "ABC9",
                item: "148BBA",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
              {
                clinicianId: "ABC10",
                item: "148BBA",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
            ],
          },
        ],
      },
      "148BBA-03": {
        insightId: "148BBA-03",
        insightName: "Overall safety ratings",
        insight: {
          insightSummaryRenderType: "topItems",
          insightSummarySingleLine: null,
          insightTopItemsThreshold: 0,
          insightIntro: "feel that",
          insightEnding: "are the safest drugs used to treat atopic dermatitis",
        },
        surveyQuestion: {
          intro:
            "To generate this data and insight, we asked the following question to dermatologists and dermatology clinicians:",
          question:
            "Please rate the following drugs on a scale of 1 -> 10 - 1 is worst, 10 is best (1 being many safety and adverse event concerns and 10 being very few safety and adverse event concerns) for when using them to treat atopic dermatitis. If you have not prescribed the drug, mark 'Never prescribed' in your response",
        },
        surveyThresholdExplanation: "",
        patientAttributes: {
          treatmentNaive: false,
          treatmentExperienced: true,
          decompensatedCirrhosis: false,
          injectionDrugUse: true,
        },
        dataPresentation: "circles",
        // description: "Clinicians feel the following drugs are safest:",
        dataDescription: "Clinician-reported safety ratings",
        numOfRespondents: {
          physician: 94,
          nursePractitioner: 64,
          physicianAssistant: 82,
          pharmacist: 37,
        },
        dataDenominator: 10,
        data: [
          {
            item: "148BBA",
            dataPoint: {
              all: 7.5,
              physician: 8,
              nursePractitioner: 9,
              physicianAssistant: 6,
              pharmacist: 7,
            },
            rationale: [
              {
                clinicianId: "ABC1",
                item: "148BBA",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
              {
                clinicianId: "ABC2",
                item: "148BBA",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
            ],
          },
          {
            item: "9TR81",
            dataPoint: {
              all: 3,
              physician: 2,
              nursePractitioner: 4,
              physicianAssistant: 2.5,
              pharmacist: 3.5,
            },
            rationale: [
              {
                clinicianId: "ABC3",
                item: "9TR81",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
              {
                clinicianId: "ABC4",
                item: "9TR81",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
            ],
          },
          {
            item: "LKA088F",
            dataPoint: {
              all: 2.5,
              physician: 1,
              nursePractitioner: 5,
              physicianAssistant: 4,
              pharmacist: 3,
            },
            rationale: [
              {
                clinicianId: "ABC5",
                item: "LKA088F",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
              {
                clinicianId: "ABC6",
                item: "LKA088F",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
            ],
          },
          {
            item: "7RRP1",
            dataPoint: {
              all: 8.5,
              physician: 8,
              nursePractitioner: 8.5,
              physicianAssistant: 7,
              pharmacist: 9,
            },
            rationale: [
              {
                clinicianId: "ABC7",
                item: "7RRP1",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
              {
                clinicianId: "ABC8",
                item: "7RRP1",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
            ],
          },
          {
            item: "148BBA",
            dataPoint: {
              all: 1,
              physician: 1.5,
              nursePractitioner: 2.5,
              physicianAssistant: 0.5,
              pharmacist: 1,
            },
            rationale: [
              {
                clinicianId: "ABC9",
                item: "148BBA",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
              {
                clinicianId: "ABC10",
                item: "148BBA",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
            ],
          },
        ],
      },
    },
  },
  "9TR81": {
    type: "drug",
    brandName: "Epclusa",
    genericName: "sofosbuvir/velpatasvir",
    routeOfAdmin: "oral",
    resultPageId: "9TR81",
    topInsights: ["9TR81-01", "9TR81-02", "9TR81-03"],
    allInsights: {
      "9TR81-01": {
        insightId: "9TR81-01",
        insightName: "Most commonly used therapies",
        insight: {
          insightSummaryRenderType: "topItems",
          insightSummarySingleLine: null,
          insightTopItemsThreshold: 0,
          insightIntro: "most frequently use",
          insightEnding: "to treat atopic dermatitis",
        },
        surveyQuestion: {
          intro:
            "To generate this data and insight, we asked the following question to dermatologists and dermatology clinicians:",
          question:
            "For every 10 atopic dermatitis patients you encounter in your practice, for how many of them would you say you end up trialing (insert drug here)? If you have not prescribed the drug, mark 'Never prescribed' in your response",
        },
        surveyThresholdExplanation: "",
        patientAttributes: {
          treatmentNaive: false,
          treatmentExperienced: true,
          decompensatedCirrhosis: false,
          injectionDrugUse: true,
        },
        dataPresentation: "circles",
        description: "Most commonly used therapies are:",
        dataDescription: "Clinician-reported frequency of use",
        numOfRespondents: {
          physician: 143,
          nursePractitioner: 54,
          physicianAssistant: 32,
          pharmacist: 42,
        },
        dataDenominator: 10,
        data: [
          {
            item: "148BBA",
            dataPoint: {
              all: 7.5,
              physician: 8,
              nursePractitioner: 9,
              physicianAssistant: 6,
              pharmacist: 7,
            },
            rationale: [
              {
                clinicianId: "ABC1",
                item: "148BBA",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
              {
                clinicianId: "ABC2",
                item: "148BBA",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
            ],
          },
          {
            item: "9TR81",
            dataPoint: {
              all: 3,
              physician: 2,
              nursePractitioner: 4,
              physicianAssistant: 2.5,
              pharmacist: 3.5,
            },
            rationale: [
              {
                clinicianId: "ABC3",
                item: "9TR81",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
              {
                clinicianId: "ABC4",
                item: "9TR81",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
            ],
          },
          {
            item: "7RRP1",
            dataPoint: {
              all: 8.5,
              physician: 8,
              nursePractitioner: 8.5,
              physicianAssistant: 7,
              pharmacist: 9,
            },
            rationale: [
              {
                clinicianId: "ABC7",
                item: "7RRP1",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
              {
                clinicianId: "ABC8",
                item: "7RRP1",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
            ],
          },
          {
            item: "148BBA",
            dataPoint: {
              all: 1,
              physician: 1.5,
              nursePractitioner: 2.5,
              physicianAssistant: 0.5,
              pharmacist: 1,
            },
            rationale: [
              {
                clinicianId: "ABC9",
                item: "148BBA",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
              {
                clinicianId: "ABC10",
                item: "148BBA",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
            ],
          },
        ],
      },
      "9TR81-02": {
        insightId: "9TR81-02",
        insightName: "Overall drug efficacy ratings",
        insight: {
          insightSummaryRenderType: "topItems",
          insightSummarySingleLine: null,
          insightTopItemsThreshold: 7,
          insightIntro: "feel that",
          insightEnding:
            "are the most effective drugs to treat atopic dermatitis",
        },
        surveyQuestion: {
          intro:
            "To generate this data and insight, we asked the following question to dermatologists and dermatology clinicians:",
          question:
            "Please rate the following drugs on a scale of 1-10 (1 being almost not effective at all and 10 being extremely effective) for overall treatment of atopic dermatitis. If you have not prescribed the drug, mark 'Never prescribed' in your response",
        },
        surveyThresholdExplanation:
          "Only drugs with a rating of 7/10 or higher were included as one of the 'most effective' drugs",
        patientAttributes: {
          treatmentNaive: false,
          treatmentExperienced: true,
          decompensatedCirrhosis: false,
          injectionDrugUse: true,
        },
        dataPresentation: "circles",
        description: "Clinicians feel the following drugs are most effective:",
        dataDescription: "Clinician-reported efficacy ratings",
        numOfRespondents: {
          physician: 113,
          nursePractitioner: 24,
          physicianAssistant: 12,
          pharmacist: 61,
        },
        dataDenominator: 10,
        data: [
          {
            item: "148BBA",
            dataPoint: {
              all: 7.5,
              physician: 8,
              nursePractitioner: 9,
              physicianAssistant: 6,
              pharmacist: 7,
            },
            rationale: [
              {
                clinicianId: "ABC1",
                item: "148BBA",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "EFFICACY Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
              {
                clinicianId: "ABC2",
                item: "148BBA",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
            ],
          },
          {
            item: "9TR81",
            dataPoint: {
              all: 3,
              physician: 2,
              nursePractitioner: 4,
              physicianAssistant: 2.5,
              pharmacist: 3.5,
            },
            rationale: [
              {
                clinicianId: "ABC3",
                item: "9TR81",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
              {
                clinicianId: "ABC4",
                item: "9TR81",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
            ],
          },
          {
            item: "LKA088F",
            dataPoint: {
              all: 2.5,
              physician: 1,
              nursePractitioner: 5,
              physicianAssistant: 4,
              pharmacist: 3,
            },
            rationale: [
              {
                clinicianId: "ABC5",
                item: "LKA088F",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "EFFICACY Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
              {
                clinicianId: "ABC6",
                item: "LKA088F",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
            ],
          },
          {
            item: "7RRP1",
            dataPoint: {
              all: 8.5,
              physician: 8,
              nursePractitioner: 8.5,
              physicianAssistant: 7,
              pharmacist: 9,
            },
            rationale: [
              {
                clinicianId: "ABC7",
                item: "7RRP1",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
              {
                clinicianId: "ABC8",
                item: "7RRP1",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
            ],
          },
          {
            item: "148BBA",
            dataPoint: {
              all: 1,
              physician: 1.5,
              nursePractitioner: 2.5,
              physicianAssistant: 0.5,
              pharmacist: 1,
            },
            rationale: [
              {
                clinicianId: "ABC9",
                item: "148BBA",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
              {
                clinicianId: "ABC10",
                item: "148BBA",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
            ],
          },
        ],
      },
      "9TR81-03": {
        insightId: "9TR81-03",
        insightName: "Overall safety ratings",
        insight: {
          insightSummaryRenderType: "topItems",
          insightSummarySingleLine: null,
          insightTopItemsThreshold: 0,
          insightIntro: "feel that",
          insightEnding: "are the safest drugs used to treat atopic dermatitis",
        },
        surveyQuestion: {
          intro:
            "To generate this data and insight, we asked the following question to dermatologists and dermatology clinicians:",
          question:
            "Please rate the following drugs on a scale of 1 -> 10 - 1 is worst, 10 is best (1 being many safety and adverse event concerns and 10 being very few safety and adverse event concerns) for when using them to treat atopic dermatitis. If you have not prescribed the drug, mark 'Never prescribed' in your response",
        },
        surveyThresholdExplanation: "",
        patientAttributes: {
          treatmentNaive: false,
          treatmentExperienced: true,
          decompensatedCirrhosis: false,
          injectionDrugUse: true,
        },
        dataPresentation: "circles",
        // description: "Clinicians feel the following drugs are safest:",
        dataDescription: "Clinician-reported safety ratings",
        numOfRespondents: {
          physician: 94,
          nursePractitioner: 64,
          physicianAssistant: 82,
          pharmacist: 37,
        },
        dataDenominator: 10,
        data: [
          {
            item: "148BBA",
            dataPoint: {
              all: 7.5,
              physician: 8,
              nursePractitioner: 9,
              physicianAssistant: 6,
              pharmacist: 7,
            },
            rationale: [
              {
                clinicianId: "ABC1",
                item: "148BBA",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
              {
                clinicianId: "ABC2",
                item: "148BBA",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
            ],
          },
          {
            item: "9TR81",
            dataPoint: {
              all: 3,
              physician: 2,
              nursePractitioner: 4,
              physicianAssistant: 2.5,
              pharmacist: 3.5,
            },
            rationale: [
              {
                clinicianId: "ABC3",
                item: "9TR81",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
              {
                clinicianId: "ABC4",
                item: "9TR81",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
            ],
          },
          {
            item: "LKA088F",
            dataPoint: {
              all: 2.5,
              physician: 1,
              nursePractitioner: 5,
              physicianAssistant: 4,
              pharmacist: 3,
            },
            rationale: [
              {
                clinicianId: "ABC5",
                item: "LKA088F",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
              {
                clinicianId: "ABC6",
                item: "LKA088F",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
            ],
          },
          {
            item: "7RRP1",
            dataPoint: {
              all: 8.5,
              physician: 8,
              nursePractitioner: 8.5,
              physicianAssistant: 7,
              pharmacist: 9,
            },
            rationale: [
              {
                clinicianId: "ABC7",
                item: "7RRP1",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
              {
                clinicianId: "ABC8",
                item: "7RRP1",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
            ],
          },
          {
            item: "148BBA",
            dataPoint: {
              all: 1,
              physician: 1.5,
              nursePractitioner: 2.5,
              physicianAssistant: 0.5,
              pharmacist: 1,
            },
            rationale: [
              {
                clinicianId: "ABC9",
                item: "148BBA",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
              {
                clinicianId: "ABC10",
                item: "148BBA",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
            ],
          },
        ],
      },
    },
  },
  LKA088F: {
    type: "drug",
    brandName: "Harvoni",
    genericName: "ledipasvir/sofosbuvir",
    routeOfAdmin: "oral",
    resultPageId: "LKA088F",
    topInsights: ["LKA088F-01", "LKA088F-02", "LKA088F-03"],
    allInsights: {
      "LKA088F-01": {
        insightId: "LKA088F-01",
        insightName: "Most commonly used therapies",
        insight: {
          insightSummaryRenderType: "topItems",
          insightSummarySingleLine: null,
          insightTopItemsThreshold: 0,
          insightIntro: "most frequently use",
          insightEnding: "to treat atopic dermatitis",
        },
        surveyQuestion: {
          intro:
            "To generate this data and insight, we asked the following question to dermatologists and dermatology clinicians:",
          question:
            "For every 10 atopic dermatitis patients you encounter in your practice, for how many of them would you say you end up trialing (insert drug here)? If you have not prescribed the drug, mark 'Never prescribed' in your response",
        },
        surveyThresholdExplanation: "",
        patientAttributes: {
          treatmentNaive: false,
          treatmentExperienced: true,
          decompensatedCirrhosis: false,
          injectionDrugUse: true,
        },
        dataPresentation: "circles",
        description: "Most commonly used therapies are:",
        dataDescription: "Clinician-reported frequency of use",
        numOfRespondents: {
          physician: 143,
          nursePractitioner: 54,
          physicianAssistant: 32,
          pharmacist: 42,
        },
        dataDenominator: 10,
        data: [
          {
            item: "148BBA",
            dataPoint: {
              all: 7.5,
              physician: 8,
              nursePractitioner: 9,
              physicianAssistant: 6,
              pharmacist: 7,
            },
            rationale: [
              {
                clinicianId: "ABC1",
                item: "148BBA",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
              {
                clinicianId: "ABC2",
                item: "148BBA",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
            ],
          },
          {
            item: "9TR81",
            dataPoint: {
              all: 3,
              physician: 2,
              nursePractitioner: 4,
              physicianAssistant: 2.5,
              pharmacist: 3.5,
            },
            rationale: [
              {
                clinicianId: "ABC3",
                item: "9TR81",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
              {
                clinicianId: "ABC4",
                item: "9TR81",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
            ],
          },
          {
            item: "7RRP1",
            dataPoint: {
              all: 8.5,
              physician: 8,
              nursePractitioner: 8.5,
              physicianAssistant: 7,
              pharmacist: 9,
            },
            rationale: [
              {
                clinicianId: "ABC7",
                item: "7RRP1",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
              {
                clinicianId: "ABC8",
                item: "7RRP1",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
            ],
          },
          {
            item: "148BBA",
            dataPoint: {
              all: 1,
              physician: 1.5,
              nursePractitioner: 2.5,
              physicianAssistant: 0.5,
              pharmacist: 1,
            },
            rationale: [
              {
                clinicianId: "ABC9",
                item: "148BBA",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
              {
                clinicianId: "ABC10",
                item: "148BBA",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
            ],
          },
        ],
      },
      "LKA088F-02": {
        insightId: "LKA088F-02",
        insightName: "Overall drug efficacy ratings",
        insight: {
          insightSummaryRenderType: "topItems",
          insightSummarySingleLine: null,
          insightTopItemsThreshold: 7,
          insightIntro: "feel that",
          insightEnding:
            "are the most effective drugs to treat atopic dermatitis",
        },
        surveyQuestion: {
          intro:
            "To generate this data and insight, we asked the following question to dermatologists and dermatology clinicians:",
          question:
            "Please rate the following drugs on a scale of 1-10 (1 being almost not effective at all and 10 being extremely effective) for overall treatment of atopic dermatitis. If you have not prescribed the drug, mark 'Never prescribed' in your response",
        },
        surveyThresholdExplanation:
          "Only drugs with a rating of 7/10 or higher were included as one of the 'most effective' drugs",
        patientAttributes: {
          treatmentNaive: false,
          treatmentExperienced: true,
          decompensatedCirrhosis: false,
          injectionDrugUse: true,
        },
        dataPresentation: "circles",
        description: "Clinicians feel the following drugs are most effective:",
        dataDescription: "Clinician-reported efficacy ratings",
        numOfRespondents: {
          physician: 113,
          nursePractitioner: 24,
          physicianAssistant: 12,
          pharmacist: 61,
        },
        dataDenominator: 10,
        data: [
          {
            item: "148BBA",
            dataPoint: {
              all: 7.5,
              physician: 8,
              nursePractitioner: 9,
              physicianAssistant: 6,
              pharmacist: 7,
            },
            rationale: [
              {
                clinicianId: "ABC1",
                item: "148BBA",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "EFFICACY Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
              {
                clinicianId: "ABC2",
                item: "148BBA",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
            ],
          },
          {
            item: "9TR81",
            dataPoint: {
              all: 3,
              physician: 2,
              nursePractitioner: 4,
              physicianAssistant: 2.5,
              pharmacist: 3.5,
            },
            rationale: [
              {
                clinicianId: "ABC3",
                item: "9TR81",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
              {
                clinicianId: "ABC4",
                item: "9TR81",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
            ],
          },
          {
            item: "LKA088F",
            dataPoint: {
              all: 2.5,
              physician: 1,
              nursePractitioner: 5,
              physicianAssistant: 4,
              pharmacist: 3,
            },
            rationale: [
              {
                clinicianId: "ABC5",
                item: "LKA088F",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "EFFICACY Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
              {
                clinicianId: "ABC6",
                item: "LKA088F",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
            ],
          },
          {
            item: "7RRP1",
            dataPoint: {
              all: 8.5,
              physician: 8,
              nursePractitioner: 8.5,
              physicianAssistant: 7,
              pharmacist: 9,
            },
            rationale: [
              {
                clinicianId: "ABC7",
                item: "7RRP1",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
              {
                clinicianId: "ABC8",
                item: "7RRP1",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
            ],
          },
          {
            item: "148BBA",
            dataPoint: {
              all: 1,
              physician: 1.5,
              nursePractitioner: 2.5,
              physicianAssistant: 0.5,
              pharmacist: 1,
            },
            rationale: [
              {
                clinicianId: "ABC9",
                item: "148BBA",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
              {
                clinicianId: "ABC10",
                item: "148BBA",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
            ],
          },
        ],
      },
      "LKA088F-03": {
        insightId: "LKA088F-03",
        insightName: "Overall safety ratings",
        insight: {
          insightSummaryRenderType: "topItems",
          insightSummarySingleLine: null,
          insightTopItemsThreshold: 0,
          insightIntro: "feel that",
          insightEnding: "are the safest drugs used to treat atopic dermatitis",
        },
        surveyQuestion: {
          intro:
            "To generate this data and insight, we asked the following question to dermatologists and dermatology clinicians:",
          question:
            "Please rate the following drugs on a scale of 1 -> 10 - 1 is worst, 10 is best (1 being many safety and adverse event concerns and 10 being very few safety and adverse event concerns) for when using them to treat atopic dermatitis. If you have not prescribed the drug, mark 'Never prescribed' in your response",
        },
        surveyThresholdExplanation: "",
        patientAttributes: {
          treatmentNaive: false,
          treatmentExperienced: true,
          decompensatedCirrhosis: false,
          injectionDrugUse: true,
        },
        dataPresentation: "circles",
        // description: "Clinicians feel the following drugs are safest:",
        dataDescription: "Clinician-reported safety ratings",
        numOfRespondents: {
          physician: 94,
          nursePractitioner: 64,
          physicianAssistant: 82,
          pharmacist: 37,
        },
        dataDenominator: 10,
        data: [
          {
            item: "148BBA",
            dataPoint: {
              all: 7.5,
              physician: 8,
              nursePractitioner: 9,
              physicianAssistant: 6,
              pharmacist: 7,
            },
            rationale: [
              {
                clinicianId: "ABC1",
                item: "148BBA",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
              {
                clinicianId: "ABC2",
                item: "148BBA",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
            ],
          },
          {
            item: "9TR81",
            dataPoint: {
              all: 3,
              physician: 2,
              nursePractitioner: 4,
              physicianAssistant: 2.5,
              pharmacist: 3.5,
            },
            rationale: [
              {
                clinicianId: "ABC3",
                item: "9TR81",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
              {
                clinicianId: "ABC4",
                item: "9TR81",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
            ],
          },
          {
            item: "LKA088F",
            dataPoint: {
              all: 2.5,
              physician: 1,
              nursePractitioner: 5,
              physicianAssistant: 4,
              pharmacist: 3,
            },
            rationale: [
              {
                clinicianId: "ABC5",
                item: "LKA088F",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
              {
                clinicianId: "ABC6",
                item: "LKA088F",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
            ],
          },
          {
            item: "7RRP1",
            dataPoint: {
              all: 8.5,
              physician: 8,
              nursePractitioner: 8.5,
              physicianAssistant: 7,
              pharmacist: 9,
            },
            rationale: [
              {
                clinicianId: "ABC7",
                item: "7RRP1",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
              {
                clinicianId: "ABC8",
                item: "7RRP1",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
            ],
          },
          {
            item: "148BBA",
            dataPoint: {
              all: 1,
              physician: 1.5,
              nursePractitioner: 2.5,
              physicianAssistant: 0.5,
              pharmacist: 1,
            },
            rationale: [
              {
                clinicianId: "ABC9",
                item: "148BBA",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
              {
                clinicianId: "ABC10",
                item: "148BBA",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
            ],
          },
        ],
      },
    },
  },
  "1PYFFA": {
    type: "disease",
    diseaseName: "Hepatitis C (HCV)",
    diseaseSeverity: "",
    resultPageId: "1PYFFA",
    topInsights: ["1PYFFA-01", "1PYFFA-02", "1PYFFA-03"],
    allInsights: {
      "1PYFFA-01": {
        insightId: "1PYFFA-01",
        insightName: "Simplified treatment for treatment-naive adults",
        insight: {
          insightSummaryRenderType: "topItems",
          insightSummarySingleLine: null,
          insightTopItemsThreshold: 6,
          insightIntro: "feel that",
          insightEnding:
            "is the preferred simplified HCV treatment for treatment-naive adults",
        },
        surveyQuestion: {
          intro:
            "To generate this data and insight, we asked the following question to infectious disease clinicians:",
          question:
            "Which simplified pangenotypic HCV treatment do you prefer for treatment-naive adults?",
        },
        surveyThresholdExplanation: "",
        patientAttributes: {
          treatmentNaive: true,
          treatmentExperienced: false,
          compensatedCirrhosis: true,
          decompensatedCirrhosis: true,
          genotype1: true,
          genotype2: true,
          genotype3: true,
          genotype4: true,
          genotype5or6: true,
          hivHcvCoinfection: false,
          organsFromHcvDonors: false,
          renalImpairment: false,
          kidneyTransplant: false,
          acuteInfection: false,
          pregnant: false,
          pediatric: false,
          pwid: false,
          msm: false,
          corrections: false,
        },
        dataPresentation: "circles",
        description: "Preferred therapies are:",
        dataDescription: "% of clinicians reporting preference for treatment",
        numOfRespondents: {
          physician: 56,
          nursePractitioner: 8,
          physicianAssistant: 6,
          pharmacist: 34,
        },
        dataDenominator: 10,
        data: [
          {
            item: "148BBA",
            dataPoint: {
              all: 6.5,
              physician: 7,
              nursePractitioner: 6,
              physicianAssistant: 6,
              pharmacist: 7,
            },
            rationale: [
              {
                clinicianId: "ABC1",
                item: "148BBA",
                rationaleBrief:
                  "It's simple, the treatment duration is shorter",
                rationaleFull:
                  "From what I've seen, my patients are much better able to stay adherent with the Mavyret treatment duration and it's been affordable for most of my patients.",
              },
            ],
          },
          {
            item: "9TR81",
            dataPoint: {
              all: 2.5,
              physician: 2,
              nursePractitioner: 3,
              physicianAssistant: 3,
              pharmacist: 2,
            },
            rationale: [],
          },
          {
            item: "N0101",
            dataPoint: {
              all: 1,
              physician: 1,
              nursePractitioner: 1,
              physicianAssistant: 1,
              pharmacist: 1,
            },
            rationale: [],
          },
        ],
      },
      "1PYFFA-02": {
        insightId: "1PYFFA-02",
        insightName:
          "Preferred DAA for HCV patients with decompensated cirrhosis",
        insight: {
          insightSummaryRenderType: "topItems",
          insightSummarySingleLine: null,
          insightTopItemsThreshold: 5,
          insightIntro: "feel that",
          insightEnding:
            "is the preferred direct-acting antiviral (DAA) treatment for HCV patients with decompensated cirrhosis (genotype 1, 4, 5, or 6)",
        },
        surveyQuestion: {
          intro:
            "To generate this data and insight, we asked the following question to infectious disease clinicians:",
          question:
            "Which direct-acting antiviral (DAA) do you prefer for HCV patients with decompensated cirrhosis and genotype 1, 4, 5, or 6?",
        },
        surveyThresholdExplanation: "",
        patientAttributes: {
          treatmentNaive: true,
          treatmentExperienced: false,
          compensatedCirrhosis: false,
          decompensatedCirrhosis: true,
          genotype1: true,
          genotype2: false,
          genotype3: false,
          genotype4: true,
          genotype5or6: true,
          hivHcvCoinfection: false,
          organsFromHcvDonors: false,
          renalImpairment: false,
          kidneyTransplant: false,
          acuteInfection: false,
          pregnant: false,
          pediatric: false,
          pwid: false,
          msm: false,
          corrections: false,
        },
        dataPresentation: "circles",
        description: "Preferred therapies are:",
        dataDescription: "% of clinicians reporting preference for treatment:",
        numOfRespondents: {
          physician: 42,
          nursePractitioner: 5,
          physicianAssistant: 7,
          pharmacist: 51,
        },
        dataDenominator: 10,
        data: [
          {
            item: "N0101",
            dataPoint: {
              all: 7.5,
              physician: 3,
              nursePractitioner: 3,
              physicianAssistant: 3,
              pharmacist: 3,
            },
            rationale: [],
          },
          {
            item: "9TR81",
            dataPoint: {
              all: 3,
              physician: 1,
              nursePractitioner: 2,
              physicianAssistant: 2,
              pharmacist: 1,
            },
            rationale: [],
          },
          {
            item: "LKA088F",
            dataPoint: {
              // all property should be deprecated
              all: 5,
              physician: 6,
              nursePractitioner: 5,
              physicianAssistant: 5,
              pharmacist: 6,
            },
            rationale: [
              {
                clinicianId: "ABC5",
                item: "LKA088F",
                rationaleBrief:
                  "I've had the most success with Harvoni in this patient population.",
                rationaleFull:
                  "I've had the most success with Harvoni in this patient population. I've tried all the different options and I just find Harvoni easiest to manage for myself and for the patient.",
              },
            ],
          },
        ],
      },
      "1PYFFA-03": {
        insightId: "1PYFFA-03",
        insightName: "2nd-line regimen for Mavyret treatment failure",
        insight: {
          insightSummaryRenderType: "topItems",
          insightSummarySingleLine: null,
          insightTopItemsThreshold: 6,
          insightIntro: "prefer",
          insightEnding:
            "to treat HCV patients who failed first-line Mavyret (glecaprevir/pibrentasvir) treatment",
        },
        surveyQuestion: {
          intro:
            "To generate this data and insight, we asked the following question to infectious disease clinicians:",
          question:
            "What treatment regimen do you prefer for the management of patients with HCV who failed first-line Mavyret (glecaprevir/pibrentasvir) treatment, with or without compensated cirrhosis?",
        },
        surveyThresholdExplanation: "",
        patientAttributes: {
          treatmentNaive: false,
          treatmentExperienced: true,
          compensatedCirrhosis: true,
          decompensatedCirrhosis: true,
          genotype1: true,
          genotype2: true,
          genotype3: true,
          genotype4: true,
          genotype5or6: true,
          hivHcvCoinfection: false,
          organsFromHcvDonors: false,
          renalImpairment: false,
          kidneyTransplant: false,
          acuteInfection: false,
          pregnant: false,
          pediatric: false,
          pwid: false,
          msm: false,
          corrections: false,
        },
        dataPresentation: "circles",
        dataDescription: "% of clinicians reporting preference for treatment",
        numOfRespondents: {
          physician: 37,
          nursePractitioner: 4,
          physicianAssistant: 6,
          pharmacist: 17,
        },
        dataDenominator: 10,
        data: [
          {
            item: "VPL801",
            dataPoint: {
              all: 8.5,
              physician: 7,
              nursePractitioner: 6,
              physicianAssistant: 6,
              pharmacist: 7,
            },
            rationale: [
              {
                clinicianId: "ABC9",
                item: "148BBA",
                rationaleBrief:
                  "This regimen is supported by the guidelines, so I usually go with this.",
                rationaleFull:
                  "This regimen is recommended by the AASLD and IDSA guidelines, so I usually select this regimen in this situation.",
              },
            ],
          },
          {
            item: "YTS819",
            dataPoint: {
              all: 1,
              physician: 2,
              nursePractitioner: 2,
              physicianAssistant: 1,
              pharmacist: 2,
            },
            rationale: [],
          },
          {
            item: "N0101",
            dataPoint: {
              all: 1,
              physician: 1,
              nursePractitioner: 2,
              physicianAssistant: 3,
              pharmacist: 1,
            },
            rationale: [],
          },
        ],
      },
      "1PYFFA-04": {
        insightId: "1PYFFA-04",
        insightName: "3rd-line treament after failing multiple DAA regimens",
        insight: {
          insightSummaryRenderType: "topItems",
          insightSummarySingleLine: null,
          insightTopItemsThreshold: 4,
          insightIntro: "prefer",
          insightEnding:
            "for the management of patients with HCV who fail multiple DAA regimens",
        },
        surveyQuestion: {
          intro:
            "To generate this data and insight, we asked the following question to infectious disease clinicians:",
          question:
            "What treatment regimen do you prefer for the management of patients with HCV who fail second-line DAA regimens (with either GP or SOF/VEL/VOX), with or without compensated cirrhosis?",
        },
        surveyThresholdExplanation: "",
        patientAttributes: {
          treatmentNaive: false,
          treatmentExperienced: true,
          compensatedCirrhosis: true,
          decompensatedCirrhosis: true,
          genotype1: true,
          genotype2: true,
          genotype3: true,
          genotype4: true,
          genotype5or6: true,
          hivHcvCoinfection: false,
          organsFromHcvDonors: false,
          renalImpairment: false,
          kidneyTransplant: false,
          acuteInfection: false,
          pregnant: false,
          pediatric: false,
          pwid: false,
          msm: false,
          corrections: false,
        },
        dataPresentation: "circles",
        dataDescription: "% of clinicians reporting preference for treatment",
        numOfRespondents: {
          physician: 28,
          nursePractitioner: 2,
          physicianAssistant: 4,
          pharmacist: 9,
        },
        dataDenominator: 10,
        data: [
          {
            item: "APF913",
            dataPoint: {
              all: 8.5,
              physician: 4,
              nursePractitioner: 3,
              physicianAssistant: 4,
              pharmacist: 4,
            },
            rationale: [],
          },
          {
            item: "JBR542",
            dataPoint: {
              all: 1,
              physician: 5,
              nursePractitioner: 4,
              physicianAssistant: 5,
              pharmacist: 5,
            },
            rationale: [
              {
                clinicianId: "ABC9",
                item: "JBR542",
                rationaleBrief:
                  "I prefer Mavyret because of the shorter treatment duration.",
                rationaleFull:
                  "While the guidelines recommend either treatment regimen, I prefer Mavyret over Vosevi due to the shorter treatment duration (16 vs. 24 weeks) for my patients.",
              },
            ],
          },
          {
            item: "N0101",
            dataPoint: {
              all: 1,
              physician: 1,
              nursePractitioner: 3,
              physicianAssistant: 1,
              pharmacist: 1,
            },
            rationale: [
              {
                clinicianId: "ABC10",
                item: "N0101",
                rationaleBrief:
                  "The evidence is limited and the guidelines recommend either regimen.",
                rationaleFull:
                  "This is an area of significant controversy, and for which the evidence-based guidance is still quite limited. However, the AASLD and the IDSA guidelines currently recommend both regimens, which are both given the same rating (IIa, B).",
              },
            ],
          },
        ],
      },
      "1PYFFA-05": {
        insightId: "1PYFFA-05",
        insightName: "Re-starting lapsed DAA treatment",
        insight: {
          insightSummaryRenderType: "topItems",
          insightSummarySingleLine: null,
          insightTopItemsThreshold: 6,
          insightIntro: "feel",
          insightEnding:
            "that it would take ~8.5 days of lapsed DAA treatment before they would reconsider re-starting and completing the regimen",
        },
        surveyQuestion: {
          intro:
            "To generate this data and insight, we asked the following question to infectious disease clinicians:",
          question:
            "How many days of treatment interruption would a patient need to have before needing to reconsider resuming the regimen and finishing out the original treatment course?",
        },
        surveyThresholdExplanation: "",
        patientAttributes: {
          treatmentNaive: true,
          treatmentExperienced: true,
          compensatedCirrhosis: true,
          decompensatedCirrhosis: true,
          genotype1: true,
          genotype2: true,
          genotype3: true,
          genotype4: true,
          genotype5or6: true,
          hivHcvCoinfection: false,
          organsFromHcvDonors: false,
          renalImpairment: false,
          kidneyTransplant: false,
          acuteInfection: false,
          pregnant: false,
          pediatric: false,
          pwid: false,
          msm: false,
          corrections: false,
        },
        dataPresentation: "number",
        dataDescription: "",
        numOfRespondents: {
          physician: 48,
          nursePractitioner: 6,
          physicianAssistant: 8,
          pharmacist: 14,
        },
        dataDenominator: 10,
        dataNumber: "~8.5 days",
        data: [
          {
            item: "XBA912",
            dataPoint: {
              all: 8.5,
              physician: 4,
              nursePractitioner: 3,
              physicianAssistant: 4,
              pharmacist: 4,
            },
            rationale: [
              {
                clinicianId: "ABC3",
                item: "XBA912",
                rationaleBrief:
                  "There are some study data that support 10 days.",
                rationaleFull:
                  "Although not rigorously studied, evaluation of lapses in DAA adherence with treatment failure has been reported in clinical studies. In the SIMPLIFY trial of SOF/VEL x 12 weeks in a population of PWID (N=103) found that even with treatment lapses of 7-10 days, overall SVR12 was roughly 94%. In the ANCHOR trial, also in a high-risk PWID population, many took 4 months to complete 3 months worth of therapy, and still had high SVR12, approximately 90% in the per protocol analysis.",
              },
            ],
          },
          {
            item: "TVF112",
            dataPoint: {
              all: 1,
              physician: 5,
              nursePractitioner: 4,
              physicianAssistant: 5,
              pharmacist: 5,
            },
            rationale: [
              {
                clinicianId: "ABC1",
                item: "TVF112",
                rationaleBrief:
                  "I usually go with ~7 days, based on my experience",
                rationaleFull:
                  "Based on my clinical experience, here are my recommendations. If treatment is missed for <7 days, restart the regimen and finish the course. If treatment is interrupted >7 days, check the HCV RNA. If negative, restart the regimen and finish the course. If positive, do not immediately restart but rather obtain RAS testing and then tailor treatment based on results.",
              },
            ],
          },
        ],
      },
    },
  },
  N0101: {
    type: "",
    brandName: "",
    genericName: "No preference",
    resultPageId: "N0101",
    topInsights: ["7RRP1-01", "7RRP1-02", "7RRP1-03"],
    allInsights: {
      "7RRP1-01": {
        insightId: "7RRP1-01",
        insightName: "Most commonly used therapies",
        insight: {
          insightSummaryRenderType: "topItems",
          insightSummarySingleLine: null,
          insightTopItemsThreshold: 0,
          insightIntro: "most frequently use",
          insightEnding: "to treat atopic dermatitis",
        },
        surveyQuestion: {
          intro:
            "To generate this data and insight, we asked the following question to dermatologists and dermatology clinicians:",
          question:
            "For every 10 atopic dermatitis patients you encounter in your practice, for how many of them would you say you end up trialing (insert drug here)? If you have not prescribed the drug, mark 'Never prescribed' in your response",
        },
        surveyThresholdExplanation: "",
        patientAttributes: {
          treatmentNaive: false,
          treatmentExperienced: true,
          decompensatedCirrhosis: false,
          injectionDrugUse: true,
        },
        dataPresentation: "circles",
        description: "Most commonly used therapies are:",
        dataDescription: "Clinician-reported frequency of use",
        numOfRespondents: {
          physician: 143,
          nursePractitioner: 54,
          physicianAssistant: 32,
          pharmacist: 42,
        },
        dataDenominator: 10,
        data: [
          {
            item: "148BBA",
            dataPoint: {
              all: 7.5,
              physician: 8,
              nursePractitioner: 9,
              physicianAssistant: 6,
              pharmacist: 7,
            },
            rationale: [
              {
                clinicianId: "ABC1",
                item: "148BBA",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
              {
                clinicianId: "ABC2",
                item: "148BBA",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
            ],
          },
          {
            item: "9TR81",
            dataPoint: {
              all: 3,
              physician: 2,
              nursePractitioner: 4,
              physicianAssistant: 2.5,
              pharmacist: 3.5,
            },
            rationale: [
              {
                clinicianId: "ABC3",
                item: "9TR81",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
              {
                clinicianId: "ABC4",
                item: "9TR81",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
            ],
          },
          {
            item: "7RRP1",
            dataPoint: {
              all: 8.5,
              physician: 8,
              nursePractitioner: 8.5,
              physicianAssistant: 7,
              pharmacist: 9,
            },
            rationale: [
              {
                clinicianId: "ABC7",
                item: "7RRP1",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
              {
                clinicianId: "ABC8",
                item: "7RRP1",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
            ],
          },
          {
            item: "148BBA",
            dataPoint: {
              all: 1,
              physician: 1.5,
              nursePractitioner: 2.5,
              physicianAssistant: 0.5,
              pharmacist: 1,
            },
            rationale: [
              {
                clinicianId: "ABC9",
                item: "148BBA",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
              {
                clinicianId: "ABC10",
                item: "148BBA",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
            ],
          },
        ],
      },
      "7RRP1-02": {
        insightId: "7RRP1-02",
        insightName: "Overall drug efficacy ratings",
        insight: {
          insightSummaryRenderType: "topItems",
          insightSummarySingleLine: null,
          insightTopItemsThreshold: 7,
          insightIntro: "feel that",
          insightEnding:
            "are the most effective drugs to treat atopic dermatitis",
        },
        surveyQuestion: {
          intro:
            "To generate this data and insight, we asked the following question to dermatologists and dermatology clinicians:",
          question:
            "Please rate the following drugs on a scale of 1-10 (1 being almost not effective at all and 10 being extremely effective) for overall treatment of atopic dermatitis. If you have not prescribed the drug, mark 'Never prescribed' in your response",
        },
        surveyThresholdExplanation:
          "Only drugs with a rating of 7/10 or higher were included as one of the 'most effective' drugs",
        patientAttributes: {
          treatmentNaive: false,
          treatmentExperienced: true,
          decompensatedCirrhosis: false,
          injectionDrugUse: true,
        },
        dataPresentation: "circles",
        description: "Clinicians feel the following drugs are most effective:",
        dataDescription: "Clinician-reported efficacy ratings",
        numOfRespondents: {
          physician: 113,
          nursePractitioner: 24,
          physicianAssistant: 12,
          pharmacist: 61,
        },
        dataDenominator: 10,
        data: [
          {
            item: "148BBA",
            dataPoint: {
              all: 7.5,
              physician: 8,
              nursePractitioner: 9,
              physicianAssistant: 6,
              pharmacist: 7,
            },
            rationale: [
              {
                clinicianId: "ABC1",
                item: "148BBA",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "EFFICACY Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
              {
                clinicianId: "ABC2",
                item: "148BBA",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
            ],
          },
          {
            item: "9TR81",
            dataPoint: {
              all: 3,
              physician: 2,
              nursePractitioner: 4,
              physicianAssistant: 2.5,
              pharmacist: 3.5,
            },
            rationale: [
              {
                clinicianId: "ABC3",
                item: "9TR81",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
              {
                clinicianId: "ABC4",
                item: "9TR81",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
            ],
          },
          {
            item: "LKA088F",
            dataPoint: {
              all: 2.5,
              physician: 1,
              nursePractitioner: 5,
              physicianAssistant: 4,
              pharmacist: 3,
            },
            rationale: [
              {
                clinicianId: "ABC5",
                item: "LKA088F",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "EFFICACY Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
              {
                clinicianId: "ABC6",
                item: "LKA088F",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
            ],
          },
          {
            item: "7RRP1",
            dataPoint: {
              all: 8.5,
              physician: 8,
              nursePractitioner: 8.5,
              physicianAssistant: 7,
              pharmacist: 9,
            },
            rationale: [
              {
                clinicianId: "ABC7",
                item: "7RRP1",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
              {
                clinicianId: "ABC8",
                item: "7RRP1",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
            ],
          },
          {
            item: "148BBA",
            dataPoint: {
              all: 1,
              physician: 1.5,
              nursePractitioner: 2.5,
              physicianAssistant: 0.5,
              pharmacist: 1,
            },
            rationale: [
              {
                clinicianId: "ABC9",
                item: "148BBA",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
              {
                clinicianId: "ABC10",
                item: "148BBA",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
            ],
          },
        ],
      },
      "7RRP1-03": {
        insightId: "7RRP1-03",
        insightName: "Overall safety ratings",
        insight: {
          insightSummaryRenderType: "topItems",
          insightSummarySingleLine: null,
          insightTopItemsThreshold: 0,
          insightIntro: "feel that",
          insightEnding: "are the safest drugs used to treat atopic dermatitis",
        },
        surveyQuestion: {
          intro:
            "To generate this data and insight, we asked the following question to dermatologists and dermatology clinicians:",
          question:
            "Please rate the following drugs on a scale of 1 -> 10 - 1 is worst, 10 is best (1 being many safety and adverse event concerns and 10 being very few safety and adverse event concerns) for when using them to treat atopic dermatitis. If you have not prescribed the drug, mark 'Never prescribed' in your response",
        },
        surveyThresholdExplanation: "",
        patientAttributes: {
          treatmentNaive: false,
          treatmentExperienced: true,
          decompensatedCirrhosis: false,
          injectionDrugUse: true,
        },
        dataPresentation: "circles",
        // description: "Clinicians feel the following drugs are safest:",
        dataDescription: "Clinician-reported safety ratings",
        numOfRespondents: {
          physician: 94,
          nursePractitioner: 64,
          physicianAssistant: 82,
          pharmacist: 37,
        },
        dataDenominator: 10,
        data: [
          {
            item: "148BBA",
            dataPoint: {
              all: 7.5,
              physician: 8,
              nursePractitioner: 9,
              physicianAssistant: 6,
              pharmacist: 7,
            },
            rationale: [
              {
                clinicianId: "ABC1",
                item: "148BBA",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
              {
                clinicianId: "ABC2",
                item: "148BBA",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
            ],
          },
          {
            item: "9TR81",
            dataPoint: {
              all: 3,
              physician: 2,
              nursePractitioner: 4,
              physicianAssistant: 2.5,
              pharmacist: 3.5,
            },
            rationale: [
              {
                clinicianId: "ABC3",
                item: "9TR81",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
              {
                clinicianId: "ABC4",
                item: "9TR81",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
            ],
          },
          {
            item: "LKA088F",
            dataPoint: {
              all: 2.5,
              physician: 1,
              nursePractitioner: 5,
              physicianAssistant: 4,
              pharmacist: 3,
            },
            rationale: [
              {
                clinicianId: "ABC5",
                item: "LKA088F",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
              {
                clinicianId: "ABC6",
                item: "LKA088F",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
            ],
          },
          {
            item: "7RRP1",
            dataPoint: {
              all: 8.5,
              physician: 8,
              nursePractitioner: 8.5,
              physicianAssistant: 7,
              pharmacist: 9,
            },
            rationale: [
              {
                clinicianId: "ABC7",
                item: "7RRP1",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
              {
                clinicianId: "ABC8",
                item: "7RRP1",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
            ],
          },
          {
            item: "148BBA",
            dataPoint: {
              all: 1,
              physician: 1.5,
              nursePractitioner: 2.5,
              physicianAssistant: 0.5,
              pharmacist: 1,
            },
            rationale: [
              {
                clinicianId: "ABC9",
                item: "148BBA",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
              {
                clinicianId: "ABC10",
                item: "148BBA",
                rationaleBrief:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                rationaleFull:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum., consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              },
            ],
          },
        ],
      },
    },
  },
  "7RRP1": {
    type: "drug",
    brandName: "",
    genericName:
      "Daily fixed-dose combination of glecaprevir/pibrentasvir plus daily sofosbuvir and weight-based ribavirin for 16 weeks",
    resultPageId: "7RRP1",
    topInsights: ["7RRP1-01", "7RRP1-02", "7RRP1-03"],
    allInsights: {
      "7RRP1-01": {
        insightId: "7RRP1-01",
        insightName: "Most commonly used therapies",
        insight: {
          insightSummaryRenderType: "topItems",
          insightSummarySingleLine: null,
          insightTopItemsThreshold: 0,
          insightIntro: "most frequently use",
          insightEnding: "to treat atopic dermatitis",
        },
        surveyQuestion: {
          intro:
            "To generate this data and insight, we asked the following question to dermatologists and dermatology clinicians:",
          question:
            "For every 10 atopic dermatitis patients you encounter in your practice, for how many of them would you say you end up trialing (insert drug here)? If you have not prescribed the drug, mark 'Never prescribed' in your response",
        },
        surveyThresholdExplanation: "",
        patientAttributes: {
          treatmentNaive: false,
          treatmentExperienced: true,
          decompensatedCirrhosis: false,
          injectionDrugUse: true,
        },
        dataPresentation: "circles",
        description: "Most commonly used therapies are:",
        dataDescription: "Clinician-reported frequency of use",
        numOfRespondents: {
          physician: 143,
          nursePractitioner: 54,
          physicianAssistant: 32,
          pharmacist: 42,
        },
        dataDenominator: 10,
        data: [],
      },
      "7RRP1-02": {
        insightId: "7RRP1-02",
        insightName: "Overall drug efficacy ratings",
        insight: {
          insightSummaryRenderType: "topItems",
          insightSummarySingleLine: null,
          insightTopItemsThreshold: 7,
          insightIntro: "feel that",
          insightEnding:
            "are the most effective drugs to treat atopic dermatitis",
        },
        surveyQuestion: {
          intro:
            "To generate this data and insight, we asked the following question to dermatologists and dermatology clinicians:",
          question:
            "Please rate the following drugs on a scale of 1-10 (1 being almost not effective at all and 10 being extremely effective) for overall treatment of atopic dermatitis. If you have not prescribed the drug, mark 'Never prescribed' in your response",
        },
        surveyThresholdExplanation:
          "Only drugs with a rating of 7/10 or higher were included as one of the 'most effective' drugs",
        patientAttributes: {
          treatmentNaive: false,
          treatmentExperienced: true,
          decompensatedCirrhosis: false,
          injectionDrugUse: true,
        },
        dataPresentation: "circles",
        description: "Clinicians feel the following drugs are most effective:",
        dataDescription: "Clinician-reported efficacy ratings",
        numOfRespondents: {
          physician: 113,
          nursePractitioner: 24,
          physicianAssistant: 12,
          pharmacist: 61,
        },
        dataDenominator: 10,
        data: [],
      },
      "7RRP1-03": {
        insightId: "7RRP1-03",
        insightName: "Overall safety ratings",
        insight: {
          insightSummaryRenderType: "topItems",
          insightSummarySingleLine: null,
          insightTopItemsThreshold: 0,
          insightIntro: "feel that",
          insightEnding: "are the safest drugs used to treat atopic dermatitis",
        },
        surveyQuestion: {
          intro:
            "To generate this data and insight, we asked the following question to dermatologists and dermatology clinicians:",
          question:
            "Please rate the following drugs on a scale of 1 -> 10 - 1 is worst, 10 is best (1 being many safety and adverse event concerns and 10 being very few safety and adverse event concerns) for when using them to treat atopic dermatitis. If you have not prescribed the drug, mark 'Never prescribed' in your response",
        },
        surveyThresholdExplanation: "",
        patientAttributes: {
          treatmentNaive: false,
          treatmentExperienced: true,
          decompensatedCirrhosis: false,
          injectionDrugUse: true,
        },
        dataPresentation: "circles",
        // description: "Clinicians feel the following drugs are safest:",
        dataDescription: "Clinician-reported safety ratings",
        numOfRespondents: {
          physician: 94,
          nursePractitioner: 64,
          physicianAssistant: 82,
          pharmacist: 37,
        },
        dataDenominator: 10,
        data: [],
      },
    },
  },
  YTS819: {
    type: "drug",
    brandName: "",
    genericName:
      "Daily fixed-dose combination of glecaprevir (300 mg)/pibrentasvir (120 mg) plus daily sofosbuvir (400 mg) and weight-based ribavirin for 16 weeks",
    resultPageId: "YTS819",
    topInsights: ["YTS819-01", "YTS819-02", "YTS819-03"],
    allInsights: {
      "7RRP1-01": {
        insightId: "7RRP1-01",
        insightName: "Most commonly used therapies",
        insight: {
          insightSummaryRenderType: "topItems",
          insightSummarySingleLine: null,
          insightTopItemsThreshold: 0,
          insightIntro: "most frequently use",
          insightEnding: "to treat atopic dermatitis",
        },
        surveyQuestion: {
          intro:
            "To generate this data and insight, we asked the following question to dermatologists and dermatology clinicians:",
          question:
            "For every 10 atopic dermatitis patients you encounter in your practice, for how many of them would you say you end up trialing (insert drug here)? If you have not prescribed the drug, mark 'Never prescribed' in your response",
        },
        surveyThresholdExplanation: "",
        patientAttributes: {
          treatmentNaive: false,
          treatmentExperienced: true,
          decompensatedCirrhosis: false,
          injectionDrugUse: true,
        },
        dataPresentation: "circles",
        description: "Most commonly used therapies are:",
        dataDescription: "Clinician-reported frequency of use",
        numOfRespondents: {
          physician: 143,
          nursePractitioner: 54,
          physicianAssistant: 32,
          pharmacist: 42,
        },
        dataDenominator: 10,
        data: [],
      },
      "7RRP1-02": {
        insightId: "7RRP1-02",
        insightName: "Overall drug efficacy ratings",
        insight: {
          insightSummaryRenderType: "topItems",
          insightSummarySingleLine: null,
          insightTopItemsThreshold: 7,
          insightIntro: "feel that",
          insightEnding:
            "are the most effective drugs to treat atopic dermatitis",
        },
        surveyQuestion: {
          intro:
            "To generate this data and insight, we asked the following question to dermatologists and dermatology clinicians:",
          question:
            "Please rate the following drugs on a scale of 1-10 (1 being almost not effective at all and 10 being extremely effective) for overall treatment of atopic dermatitis. If you have not prescribed the drug, mark 'Never prescribed' in your response",
        },
        surveyThresholdExplanation:
          "Only drugs with a rating of 7/10 or higher were included as one of the 'most effective' drugs",
        patientAttributes: {
          treatmentNaive: false,
          treatmentExperienced: true,
          decompensatedCirrhosis: false,
          injectionDrugUse: true,
        },
        dataPresentation: "circles",
        description: "Clinicians feel the following drugs are most effective:",
        dataDescription: "Clinician-reported efficacy ratings",
        numOfRespondents: {
          physician: 113,
          nursePractitioner: 24,
          physicianAssistant: 12,
          pharmacist: 61,
        },
        dataDenominator: 10,
        data: [],
      },
      "7RRP1-03": {
        insightId: "7RRP1-03",
        insightName: "Overall safety ratings",
        insight: {
          insightSummaryRenderType: "topItems",
          insightSummarySingleLine: null,
          insightTopItemsThreshold: 0,
          insightIntro: "feel that",
          insightEnding: "are the safest drugs used to treat atopic dermatitis",
        },
        surveyQuestion: {
          intro:
            "To generate this data and insight, we asked the following question to dermatologists and dermatology clinicians:",
          question:
            "Please rate the following drugs on a scale of 1 -> 10 - 1 is worst, 10 is best (1 being many safety and adverse event concerns and 10 being very few safety and adverse event concerns) for when using them to treat atopic dermatitis. If you have not prescribed the drug, mark 'Never prescribed' in your response",
        },
        surveyThresholdExplanation: "",
        patientAttributes: {
          treatmentNaive: false,
          treatmentExperienced: true,
          decompensatedCirrhosis: false,
          injectionDrugUse: true,
        },
        dataPresentation: "circles",
        // description: "Clinicians feel the following drugs are safest:",
        dataDescription: "Clinician-reported safety ratings",
        numOfRespondents: {
          physician: 94,
          nursePractitioner: 64,
          physicianAssistant: 82,
          pharmacist: 37,
        },
        dataDenominator: 10,
        data: [],
      },
    },
  },
  VPL801: {
    type: "drug",
    brandName: "",
    genericName:
      "Daily fixed-dose combination of sofosbuvir (400 mg)/ velpatasvir (100 mg)/ voxilaprevir (100 mg) +/- weight-based ribavirin for 12 weeks",
    resultPageId: "VPL801",
    topInsights: ["VPL801-01", "VPL801-02", "VPL801-03"],
    allInsights: {
      "7RRP1-01": {
        insightId: "7RRP1-01",
        insightName: "Most commonly used therapies",
        insight: {
          insightSummaryRenderType: "topItems",
          insightSummarySingleLine: null,
          insightTopItemsThreshold: 0,
          insightIntro: "most frequently use",
          insightEnding: "to treat atopic dermatitis",
        },
        surveyQuestion: {
          intro:
            "To generate this data and insight, we asked the following question to dermatologists and dermatology clinicians:",
          question:
            "For every 10 atopic dermatitis patients you encounter in your practice, for how many of them would you say you end up trialing (insert drug here)? If you have not prescribed the drug, mark 'Never prescribed' in your response",
        },
        surveyThresholdExplanation: "",
        patientAttributes: {
          treatmentNaive: false,
          treatmentExperienced: true,
          decompensatedCirrhosis: false,
          injectionDrugUse: true,
        },
        dataPresentation: "circles",
        description: "Most commonly used therapies are:",
        dataDescription: "Clinician-reported frequency of use",
        numOfRespondents: {
          physician: 143,
          nursePractitioner: 54,
          physicianAssistant: 32,
          pharmacist: 42,
        },
        dataDenominator: 10,
        data: [],
      },
      "7RRP1-02": {
        insightId: "7RRP1-02",
        insightName: "Overall drug efficacy ratings",
        insight: {
          insightSummaryRenderType: "topItems",
          insightSummarySingleLine: null,
          insightTopItemsThreshold: 7,
          insightIntro: "feel that",
          insightEnding:
            "are the most effective drugs to treat atopic dermatitis",
        },
        surveyQuestion: {
          intro:
            "To generate this data and insight, we asked the following question to dermatologists and dermatology clinicians:",
          question:
            "Please rate the following drugs on a scale of 1-10 (1 being almost not effective at all and 10 being extremely effective) for overall treatment of atopic dermatitis. If you have not prescribed the drug, mark 'Never prescribed' in your response",
        },
        surveyThresholdExplanation:
          "Only drugs with a rating of 7/10 or higher were included as one of the 'most effective' drugs",
        patientAttributes: {
          treatmentNaive: false,
          treatmentExperienced: true,
          decompensatedCirrhosis: false,
          injectionDrugUse: true,
        },
        dataPresentation: "circles",
        description: "Clinicians feel the following drugs are most effective:",
        dataDescription: "Clinician-reported efficacy ratings",
        numOfRespondents: {
          physician: 113,
          nursePractitioner: 24,
          physicianAssistant: 12,
          pharmacist: 61,
        },
        dataDenominator: 10,
        data: [],
      },
      "7RRP1-03": {
        insightId: "7RRP1-03",
        insightName: "Overall safety ratings",
        insight: {
          insightSummaryRenderType: "topItems",
          insightSummarySingleLine: null,
          insightTopItemsThreshold: 0,
          insightIntro: "feel that",
          insightEnding: "are the safest drugs used to treat atopic dermatitis",
        },
        surveyQuestion: {
          intro:
            "To generate this data and insight, we asked the following question to dermatologists and dermatology clinicians:",
          question:
            "Please rate the following drugs on a scale of 1 -> 10 - 1 is worst, 10 is best (1 being many safety and adverse event concerns and 10 being very few safety and adverse event concerns) for when using them to treat atopic dermatitis. If you have not prescribed the drug, mark 'Never prescribed' in your response",
        },
        surveyThresholdExplanation: "",
        patientAttributes: {
          treatmentNaive: false,
          treatmentExperienced: true,
          decompensatedCirrhosis: false,
          injectionDrugUse: true,
        },
        dataPresentation: "circles",
        // description: "Clinicians feel the following drugs are safest:",
        dataDescription: "Clinician-reported safety ratings",
        numOfRespondents: {
          physician: 94,
          nursePractitioner: 64,
          physicianAssistant: 82,
          pharmacist: 37,
        },
        dataDenominator: 10,
        data: [],
      },
    },
  },
  APF913: {
    type: "drug",
    brandName: "",
    genericName:
      "Vosevi ( sofosbuvir/ velpatasvir/ voxilaprevir ) plus weight-based ribavirin for 24 weeks",
    resultPageId: "APF913",
    topInsights: ["APF913-01", "APF913-02", "APF913-03"],
    allInsights: {
      "7RRP1-01": {
        insightId: "7RRP1-01",
        insightName: "Most commonly used therapies",
        insight: {
          insightSummaryRenderType: "topItems",
          insightSummarySingleLine: null,
          insightTopItemsThreshold: 0,
          insightIntro: "most frequently use",
          insightEnding: "to treat atopic dermatitis",
        },
        surveyQuestion: {
          intro:
            "To generate this data and insight, we asked the following question to dermatologists and dermatology clinicians:",
          question:
            "For every 10 atopic dermatitis patients you encounter in your practice, for how many of them would you say you end up trialing (insert drug here)? If you have not prescribed the drug, mark 'Never prescribed' in your response",
        },
        surveyThresholdExplanation: "",
        patientAttributes: {
          treatmentNaive: false,
          treatmentExperienced: true,
          decompensatedCirrhosis: false,
          injectionDrugUse: true,
        },
        dataPresentation: "circles",
        description: "Most commonly used therapies are:",
        dataDescription: "Clinician-reported frequency of use",
        numOfRespondents: {
          physician: 143,
          nursePractitioner: 54,
          physicianAssistant: 32,
          pharmacist: 42,
        },
        dataDenominator: 10,
        data: [],
      },
      "7RRP1-02": {
        insightId: "7RRP1-02",
        insightName: "Overall drug efficacy ratings",
        insight: {
          insightSummaryRenderType: "topItems",
          insightSummarySingleLine: null,
          insightTopItemsThreshold: 7,
          insightIntro: "feel that",
          insightEnding:
            "are the most effective drugs to treat atopic dermatitis",
        },
        surveyQuestion: {
          intro:
            "To generate this data and insight, we asked the following question to dermatologists and dermatology clinicians:",
          question:
            "Please rate the following drugs on a scale of 1-10 (1 being almost not effective at all and 10 being extremely effective) for overall treatment of atopic dermatitis. If you have not prescribed the drug, mark 'Never prescribed' in your response",
        },
        surveyThresholdExplanation:
          "Only drugs with a rating of 7/10 or higher were included as one of the 'most effective' drugs",
        patientAttributes: {
          treatmentNaive: false,
          treatmentExperienced: true,
          decompensatedCirrhosis: false,
          injectionDrugUse: true,
        },
        dataPresentation: "circles",
        description: "Clinicians feel the following drugs are most effective:",
        dataDescription: "Clinician-reported efficacy ratings",
        numOfRespondents: {
          physician: 113,
          nursePractitioner: 24,
          physicianAssistant: 12,
          pharmacist: 61,
        },
        dataDenominator: 10,
        data: [],
      },
      "7RRP1-03": {
        insightId: "7RRP1-03",
        insightName: "Overall safety ratings",
        insight: {
          insightSummaryRenderType: "topItems",
          insightSummarySingleLine: null,
          insightTopItemsThreshold: 0,
          insightIntro: "feel that",
          insightEnding: "are the safest drugs used to treat atopic dermatitis",
        },
        surveyQuestion: {
          intro:
            "To generate this data and insight, we asked the following question to dermatologists and dermatology clinicians:",
          question:
            "Please rate the following drugs on a scale of 1 -> 10 - 1 is worst, 10 is best (1 being many safety and adverse event concerns and 10 being very few safety and adverse event concerns) for when using them to treat atopic dermatitis. If you have not prescribed the drug, mark 'Never prescribed' in your response",
        },
        surveyThresholdExplanation: "",
        patientAttributes: {
          treatmentNaive: false,
          treatmentExperienced: true,
          decompensatedCirrhosis: false,
          injectionDrugUse: true,
        },
        dataPresentation: "circles",
        // description: "Clinicians feel the following drugs are safest:",
        dataDescription: "Clinician-reported safety ratings",
        numOfRespondents: {
          physician: 94,
          nursePractitioner: 64,
          physicianAssistant: 82,
          pharmacist: 37,
        },
        dataDenominator: 10,
        data: [],
      },
    },
  },
  JBR542: {
    type: "drug",
    brandName: "",
    genericName:
      "Mavyret (glecaprevir/pibrentasvir) plus weight-based ribavirin for 16 weeks",
    resultPageId: "JBR542",
    topInsights: ["JBR542-01", "JBR542-02", "JBR542-03"],
    allInsights: {
      "7RRP1-01": {
        insightId: "7RRP1-01",
        insightName: "Most commonly used therapies",
        insight: {
          insightSummaryRenderType: "topItems",
          insightSummarySingleLine: null,
          insightTopItemsThreshold: 0,
          insightIntro: "most frequently use",
          insightEnding: "to treat atopic dermatitis",
        },
        surveyQuestion: {
          intro:
            "To generate this data and insight, we asked the following question to dermatologists and dermatology clinicians:",
          question:
            "For every 10 atopic dermatitis patients you encounter in your practice, for how many of them would you say you end up trialing (insert drug here)? If you have not prescribed the drug, mark 'Never prescribed' in your response",
        },
        surveyThresholdExplanation: "",
        patientAttributes: {
          treatmentNaive: false,
          treatmentExperienced: true,
          decompensatedCirrhosis: false,
          injectionDrugUse: true,
        },
        dataPresentation: "circles",
        description: "Most commonly used therapies are:",
        dataDescription: "Clinician-reported frequency of use",
        numOfRespondents: {
          physician: 143,
          nursePractitioner: 54,
          physicianAssistant: 32,
          pharmacist: 42,
        },
        dataDenominator: 10,
        data: [],
      },
      "7RRP1-02": {
        insightId: "7RRP1-02",
        insightName: "Overall drug efficacy ratings",
        insight: {
          insightSummaryRenderType: "topItems",
          insightSummarySingleLine: null,
          insightTopItemsThreshold: 7,
          insightIntro: "feel that",
          insightEnding:
            "are the most effective drugs to treat atopic dermatitis",
        },
        surveyQuestion: {
          intro:
            "To generate this data and insight, we asked the following question to dermatologists and dermatology clinicians:",
          question:
            "Please rate the following drugs on a scale of 1-10 (1 being almost not effective at all and 10 being extremely effective) for overall treatment of atopic dermatitis. If you have not prescribed the drug, mark 'Never prescribed' in your response",
        },
        surveyThresholdExplanation:
          "Only drugs with a rating of 7/10 or higher were included as one of the 'most effective' drugs",
        patientAttributes: {
          treatmentNaive: false,
          treatmentExperienced: true,
          decompensatedCirrhosis: false,
          injectionDrugUse: true,
        },
        dataPresentation: "circles",
        description: "Clinicians feel the following drugs are most effective:",
        dataDescription: "Clinician-reported efficacy ratings",
        numOfRespondents: {
          physician: 113,
          nursePractitioner: 24,
          physicianAssistant: 12,
          pharmacist: 61,
        },
        dataDenominator: 10,
        data: [],
      },
      "7RRP1-03": {
        insightId: "7RRP1-03",
        insightName: "Overall safety ratings",
        insight: {
          insightSummaryRenderType: "topItems",
          insightSummarySingleLine: null,
          insightTopItemsThreshold: 0,
          insightIntro: "feel that",
          insightEnding: "are the safest drugs used to treat atopic dermatitis",
        },
        surveyQuestion: {
          intro:
            "To generate this data and insight, we asked the following question to dermatologists and dermatology clinicians:",
          question:
            "Please rate the following drugs on a scale of 1 -> 10 - 1 is worst, 10 is best (1 being many safety and adverse event concerns and 10 being very few safety and adverse event concerns) for when using them to treat atopic dermatitis. If you have not prescribed the drug, mark 'Never prescribed' in your response",
        },
        surveyThresholdExplanation: "",
        patientAttributes: {
          treatmentNaive: false,
          treatmentExperienced: true,
          decompensatedCirrhosis: false,
          injectionDrugUse: true,
        },
        dataPresentation: "circles",
        // description: "Clinicians feel the following drugs are safest:",
        dataDescription: "Clinician-reported safety ratings",
        numOfRespondents: {
          physician: 94,
          nursePractitioner: 64,
          physicianAssistant: 82,
          pharmacist: 37,
        },
        dataDenominator: 10,
        data: [],
      },
    },
  },
  XBA912: {
    type: "strategy",
    brandName: "",
    genericName: "10 days",
    resultPageId: "XBA912",
    topInsights: ["JBR542-01", "JBR542-02", "JBR542-03"],
    allInsights: {
      "7RRP1-01": {
        insightId: "7RRP1-01",
        insightName: "Most commonly used therapies",
        insight: {
          insightSummaryRenderType: "topItems",
          insightSummarySingleLine: null,
          insightTopItemsThreshold: 0,
          insightIntro: "most frequently use",
          insightEnding: "to treat atopic dermatitis",
        },
        surveyQuestion: {
          intro:
            "To generate this data and insight, we asked the following question to dermatologists and dermatology clinicians:",
          question:
            "For every 10 atopic dermatitis patients you encounter in your practice, for how many of them would you say you end up trialing (insert drug here)? If you have not prescribed the drug, mark 'Never prescribed' in your response",
        },
        surveyThresholdExplanation: "",
        patientAttributes: {
          treatmentNaive: false,
          treatmentExperienced: true,
          decompensatedCirrhosis: false,
          injectionDrugUse: true,
        },
        dataPresentation: "circles",
        description: "Most commonly used therapies are:",
        dataDescription: "Clinician-reported frequency of use",
        numOfRespondents: {
          physician: 143,
          nursePractitioner: 54,
          physicianAssistant: 32,
          pharmacist: 42,
        },
        dataDenominator: 10,
        data: [],
      },
      "7RRP1-02": {
        insightId: "7RRP1-02",
        insightName: "Overall drug efficacy ratings",
        insight: {
          insightSummaryRenderType: "topItems",
          insightSummarySingleLine: null,
          insightTopItemsThreshold: 7,
          insightIntro: "feel that",
          insightEnding:
            "are the most effective drugs to treat atopic dermatitis",
        },
        surveyQuestion: {
          intro:
            "To generate this data and insight, we asked the following question to dermatologists and dermatology clinicians:",
          question:
            "Please rate the following drugs on a scale of 1-10 (1 being almost not effective at all and 10 being extremely effective) for overall treatment of atopic dermatitis. If you have not prescribed the drug, mark 'Never prescribed' in your response",
        },
        surveyThresholdExplanation:
          "Only drugs with a rating of 7/10 or higher were included as one of the 'most effective' drugs",
        patientAttributes: {
          treatmentNaive: false,
          treatmentExperienced: true,
          decompensatedCirrhosis: false,
          injectionDrugUse: true,
        },
        dataPresentation: "circles",
        description: "Clinicians feel the following drugs are most effective:",
        dataDescription: "Clinician-reported efficacy ratings",
        numOfRespondents: {
          physician: 113,
          nursePractitioner: 24,
          physicianAssistant: 12,
          pharmacist: 61,
        },
        dataDenominator: 10,
        data: [],
      },
      "7RRP1-03": {
        insightId: "7RRP1-03",
        insightName: "Overall safety ratings",
        insight: {
          insightSummaryRenderType: "topItems",
          insightSummarySingleLine: null,
          insightTopItemsThreshold: 0,
          insightIntro: "feel that",
          insightEnding: "are the safest drugs used to treat atopic dermatitis",
        },
        surveyQuestion: {
          intro:
            "To generate this data and insight, we asked the following question to dermatologists and dermatology clinicians:",
          question:
            "Please rate the following drugs on a scale of 1 -> 10 - 1 is worst, 10 is best (1 being many safety and adverse event concerns and 10 being very few safety and adverse event concerns) for when using them to treat atopic dermatitis. If you have not prescribed the drug, mark 'Never prescribed' in your response",
        },
        surveyThresholdExplanation: "",
        patientAttributes: {
          treatmentNaive: false,
          treatmentExperienced: true,
          decompensatedCirrhosis: false,
          injectionDrugUse: true,
        },
        dataPresentation: "circles",
        // description: "Clinicians feel the following drugs are safest:",
        dataDescription: "Clinician-reported safety ratings",
        numOfRespondents: {
          physician: 94,
          nursePractitioner: 64,
          physicianAssistant: 82,
          pharmacist: 37,
        },
        dataDenominator: 10,
        data: [],
      },
    },
  },
  TVF112: {
    type: "strategy",
    brandName: "",
    genericName: "7 days",
    resultPageId: "TVF112",
    topInsights: ["JBR542-01", "JBR542-02", "JBR542-03"],
    allInsights: {
      "7RRP1-01": {
        insightId: "7RRP1-01",
        insightName: "Most commonly used therapies",
        insight: {
          insightSummaryRenderType: "topItems",
          insightSummarySingleLine: null,
          insightTopItemsThreshold: 0,
          insightIntro: "most frequently use",
          insightEnding: "to treat atopic dermatitis",
        },
        surveyQuestion: {
          intro:
            "To generate this data and insight, we asked the following question to dermatologists and dermatology clinicians:",
          question:
            "For every 10 atopic dermatitis patients you encounter in your practice, for how many of them would you say you end up trialing (insert drug here)? If you have not prescribed the drug, mark 'Never prescribed' in your response",
        },
        surveyThresholdExplanation: "",
        patientAttributes: {
          treatmentNaive: false,
          treatmentExperienced: true,
          decompensatedCirrhosis: false,
          injectionDrugUse: true,
        },
        dataPresentation: "circles",
        description: "Most commonly used therapies are:",
        dataDescription: "Clinician-reported frequency of use",
        numOfRespondents: {
          physician: 143,
          nursePractitioner: 54,
          physicianAssistant: 32,
          pharmacist: 42,
        },
        dataDenominator: 10,
        data: [],
      },
      "7RRP1-02": {
        insightId: "7RRP1-02",
        insightName: "Overall drug efficacy ratings",
        insight: {
          insightSummaryRenderType: "topItems",
          insightSummarySingleLine: null,
          insightTopItemsThreshold: 7,
          insightIntro: "feel that",
          insightEnding:
            "are the most effective drugs to treat atopic dermatitis",
        },
        surveyQuestion: {
          intro:
            "To generate this data and insight, we asked the following question to dermatologists and dermatology clinicians:",
          question:
            "Please rate the following drugs on a scale of 1-10 (1 being almost not effective at all and 10 being extremely effective) for overall treatment of atopic dermatitis. If you have not prescribed the drug, mark 'Never prescribed' in your response",
        },
        surveyThresholdExplanation:
          "Only drugs with a rating of 7/10 or higher were included as one of the 'most effective' drugs",
        patientAttributes: {
          treatmentNaive: false,
          treatmentExperienced: true,
          decompensatedCirrhosis: false,
          injectionDrugUse: true,
        },
        dataPresentation: "circles",
        description: "Clinicians feel the following drugs are most effective:",
        dataDescription: "Clinician-reported efficacy ratings",
        numOfRespondents: {
          physician: 113,
          nursePractitioner: 24,
          physicianAssistant: 12,
          pharmacist: 61,
        },
        dataDenominator: 10,
        data: [],
      },
      "7RRP1-03": {
        insightId: "7RRP1-03",
        insightName: "Overall safety ratings",
        insight: {
          insightSummaryRenderType: "topItems",
          insightSummarySingleLine: null,
          insightTopItemsThreshold: 0,
          insightIntro: "feel that",
          insightEnding: "are the safest drugs used to treat atopic dermatitis",
        },
        surveyQuestion: {
          intro:
            "To generate this data and insight, we asked the following question to dermatologists and dermatology clinicians:",
          question:
            "Please rate the following drugs on a scale of 1 -> 10 - 1 is worst, 10 is best (1 being many safety and adverse event concerns and 10 being very few safety and adverse event concerns) for when using them to treat atopic dermatitis. If you have not prescribed the drug, mark 'Never prescribed' in your response",
        },
        surveyThresholdExplanation: "",
        patientAttributes: {
          treatmentNaive: false,
          treatmentExperienced: true,
          decompensatedCirrhosis: false,
          injectionDrugUse: true,
        },
        dataPresentation: "circles",
        // description: "Clinicians feel the following drugs are safest:",
        dataDescription: "Clinician-reported safety ratings",
        numOfRespondents: {
          physician: 94,
          nursePractitioner: 64,
          physicianAssistant: 82,
          pharmacist: 37,
        },
        dataDenominator: 10,
        data: [],
      },
    },
  },
};

const initializeDrugAndDiseaseData = () => {
  // initialize a drugAndDiseaseObj cont to the value of drugAndDiseaseDataObj in state
  const drugAndDiseaseObj = { ...origDrugAndDiseaseDataObj };

  // iterate through every value of the object and add the generated full name of the drug/disease
  // to each entry
  const allDrugAndDiseaseValues = Object.values(drugAndDiseaseObj);
  // for every value obj in the drugAndDiseaseDataObj
  allDrugAndDiseaseValues.forEach((drugDisease) => {
    drugDisease.fullName = `${
      drugDisease.type === "disease"
        ? `${
            drugDisease.diseaseSeverity
              ? `${drugDisease.diseaseSeverity} ${drugDisease.diseaseName}`
              : `${drugDisease.diseaseName}`
          }`
        : `${
            drugDisease.brandName
              ? `${drugDisease.brandName} (${drugDisease.genericName})`
              : `${drugDisease.genericName}`
          }`
    }`;
  });

  // generate the fullNameReferenceList now that we have the fullName property added to each disease/drug
  const fullNameReferenceList = {};
  Object.keys(drugAndDiseaseObj).forEach((resultPageId) => {
    fullNameReferenceList[origDrugAndDiseaseDataObj[resultPageId].fullName] =
      resultPageId;
  });

  // NOW, iterate through every value of the object and add a unique dataPointId AND the associated resultPageId to each
  // data point within each insight of allInsights property
  allDrugAndDiseaseValues.forEach((drugDisease) => {
    // initalize an array of every insight in allInsights
    const insightsArray = Object.values(drugDisease.allInsights);
    // and for each insight,
    insightsArray.forEach((insight) => {
      // initialize an array of each data point for that insight
      const dataPointsArray = Object.values(insight.data);
      // and for each data point
      dataPointsArray.forEach((dataPoint, index) => {
        // add a property: key: resultPageId, value: respecitve resultPageId grabbed from
        // the fullNameReferenceList initialized above
        dataPoint.resultPageId = fullNameReferenceList[dataPoint.item];
        // and add another property: key: dataPointId, value: unique identifier generated
        // using the current insightId and the index of the data point
        dataPoint.dataPointId = `${insight.insightId}-${index + 1}`;
      });
    });
  });

  return drugAndDiseaseObj;
};

const drugAndDiseaseDataObj = initializeDrugAndDiseaseData();

export default drugAndDiseaseDataObj;
