import React from "react";
import { Link } from "react-router-dom";

import "./search-result.component.css";
import "../general.css";

const SearchResult = (props) => {
  // destructure props and initialize several conts
  const {
    resultPageId,
    resultNumber,
    resultTitle,
    resultTopInsights,
    resultAllInsights,
  } = props;

  return (
    <div className="search-result">
      <Link
        key={resultPageId}
        className="search-result-title"
        to={`/demo/${resultPageId}`}
      >
        {resultNumber}. {resultTitle[0].toUpperCase() + resultTitle.slice(1)}
      </Link>
      <div className="search-result-details-container">
        <h2 className="search-result-trending-topics-subhead">
          Trending Topics:
        </h2>
        <div className="search-result-insight-names-container">
          {resultTopInsights.map((insightId) => {
            return (
              <Link
                key={insightId}
                className="search-result-insight-name"
                to={`/demo/${resultPageId}`}
              >
                {resultAllInsights[insightId].insightName}
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SearchResult;
