import React from "react";
import { Link } from "react-router-dom";

import "../general.css";
import "./data-insight-card.component.css";

import resultPageIdReferenceData from "../resultPageIdReference";
import clinicianIdReference from "../clinicianIdReference";

const DataInsightCard = (props) => {
  // destructure props and initialize consts
  const {
    insightObj,
    setShowDataInsightDetailsModal,
    setSelectedDataInsightId,
    setSelectedDataInsightRespondentsTotal,
    setSelectedDataInsightSortedItems,
    setSelectedDataInsightTopItems,
    insightsFilter,
    setInsightsRationale,
    setFilteredInsightsRationale,
    insightsRatings,
    setInsightsRatings,
    setCloseModalScrollToElement,
  } = props;

  // initialize a const to the insightId associated with the current insight being
  // rendered in this data-insight-card component
  const currentInsightId = insightObj.insightId;

  // destructure insightsFilter to consts that represent the currently active filters
  const { searchTerm, drugOrDiseaseId, clinicianType, patientBuilder } =
    insightsFilter;
  // *****************************
  // ACTIVE CLINICIAN TYPES VARIABLE
  // *****************************
  // initialize a const to the array of all the "active" clinicianTypes
  // as dictated by the currently active insightsFilter
  const activeClinicianTypes = Object.keys(insightObj.numOfRespondents).filter(
    (clinician) => {
      return clinicianType[clinician] === true ? clinician : null;
    }
  );

  // *****************************
  // ***TOTAL RESPONDENTS VARIABLE***
  // *****************************
  // set the totalRespondents variable to the total number of respondents
  // for this insight, based on the active insights filters that are in place
  // iterate through each of the active clinician types and for each one
  const totalRespondents = activeClinicianTypes.reduce((sum, clinician) => {
    // add that respondent count to the total sum
    return (sum += insightObj.numOfRespondents[clinician]);
  }, 0);

  // **NOTE** - the calculations for the "average" data point will have to be updated
  // so that it more accurately refelcts the true average relative to the number of respondents
  // per clinician type
  // *********************************
  // ***UPDATED DATA ITEMS VARIABLE***
  // *********************************
  // initialize a const to a shallow copy of the insightObj.data array
  // and map over each dataItem to create a newDataItem that adds a property
  // with the 'average' of dataPoints corresponding to the activeClinicianTypes
  // based on currently active filters in the insightsFilter obj from props
  const updatedDataItems = insightObj.data
    .slice()
    .map((dataItem) => {
      const newDataItem = { ...dataItem };
      const sumOfDataPoints = activeClinicianTypes.reduce((sum, clinician) => {
        return (sum += dataItem.dataPoint[clinician]);
      }, 0);
      const dataPointAverage = (
        sumOfDataPoints / activeClinicianTypes.length
      ).toPrecision(2);

      newDataItem.calculatedDataPointAvg = Number(dataPointAverage);

      return newDataItem;
    })
    // and sort them in descending order using the new calculatedDataPointAvg property
    .sort((a, b) => {
      return b.calculatedDataPointAvg - a.calculatedDataPointAvg;
    });

  // initialize a const to the 'top items' for this particular insight
  // the top items consist ONLY of the top 3 highest rated drugs/diseases
  // for this insight unless one of those top 3 drugs/diseases does NOT
  // meet the threshold for inclusion in the top items
  const insightTopItems = updatedDataItems
    // iterate through each sorted data item and
    .filter((dataItem, index) => {
      // if the datapoint, eg. avg rating, etc. is higher than the threshold
      // coming from the drugAndDiseaseData obj AND is one of the top 3 items
      // after sorting by rating/score/frequency
      return dataItem.calculatedDataPointAvg >=
        insightObj.insight.insightTopItemsThreshold && index <= 2
        ? dataItem.item
        : "";
    })
    // and then map a new array of just the names of the drug/disease
    .map((dataItem) => resultPageIdReferenceData[dataItem.item]);

  // HANDLER FUNCTION for ratings-btn clicks
  // will trigger the animation and styling changes for a clicked ratings btn
  // and will update insightsRatings state in result-data-page component

  const handleRatingBtnClick = (e, btnClicked) => {
    // ***ANIMATION***
    // select the clicked ratings btn
    const ratingBtn = e.target.closest(".data-insight-ratings-btn");
    // toggle the clicked-ratings-btn-class
    // use setTimeout to animate a 'jump' when it is clicked
    ratingBtn.classList.toggle("clicked-ratings-btn");
    setTimeout(() => {
      ratingBtn.classList.toggle("clicked-ratings-btn");
    }, 200);
    // ***UPDATING STATE***
    // create a shallow copy of the previous insightsRatings obj and
    // update the property with the key of the insightId that is associated with
    // the insight of the data-insight-card component the user is currently interacting
    // with

    // TEMPORARY FOR MVP
    setInsightsRatings((prevInsightsRatings) => {
      const newInsightsRatings = { ...prevInsightsRatings };
      newInsightsRatings[currentInsightId] = btnClicked;
      return newInsightsRatings;
    });
    // PLACEHOLDER FOR API ENDPOINT to add to record in database
    // of total 'likes' for this insight, and from which specialty and
    // other high-level identifiers

    // TEMPORARY FOR MVP
    localStorage.setItem(currentInsightId, btnClicked);
  };

  // HANDLER FUNCTION (PLACEHOLDER)
  // this placeholder handler function opens up a small modal to explain to
  // demo participants that, in the future, this link will bring them to
  // a page dedicated to that drug or disease
  const handleDrugOrDiseaseLinkClick = (e) => {
    const targetDisclaimerElement = e.target
      .closest(".data-insight-data-container")
      .querySelector(".data-insight-drug-or-disease-link-demo-disclaimer");
    targetDisclaimerElement.classList.toggle("disclaimer-hidden");
    setTimeout(() => {
      targetDisclaimerElement.classList.toggle("disclaimer-hidden");
    }, 3000);
  };

  // HANLDER FUNCTION
  // used to update the insightsRationale state in results-data-page component
  // when user clicks on 'More Details', we will update the array of rationale
  // that is displayed in the data-insight-details-modal component
  const updateInsightsRationale = () => {
    const allRationale = [];
    insightObj.data.forEach((dataItem) => {
      dataItem.rationale.forEach((rationaleItem) => {
        allRationale.push(rationaleItem);
      });
    });
    // filter allRationle by the relevant active filters in insightsFilter
    // start by initializing a few consts to the value of the current relevant filters
    // within insightsFilter state in result-data-page component
    const currentClinicianFilter = insightsFilter.clinicianType;

    // created a filtered array of all the rationale associated with this insight
    // iterate through each rationale and do several if checks
    const filteredRationale = allRationale.filter((rationale) => {
      // initialize a variable to keep track of whether we want to include
      // this rationale in the filteredRationale array
      let includeRationale = true;

      // if the clinicianType filter (in insightsFilter) associated with the author of this insight
      // is false, then reassign includeRationale to false
      if (
        currentClinicianFilter[
          clinicianIdReference[rationale.clinicianId].clinicianType
        ] === false
      )
        includeRationale = false;

      // if the currentDrugFilter is not empty, meaning that the user has selected a filter
      // in the insights-filter-modal component AND the drugOrDiseaseId/resultPageId associated with the rationale
      // does NOT match the currentDrugFilter, then reassign includeRationale to false
      if (drugOrDiseaseId !== "" && drugOrDiseaseId !== rationale.item)
        includeRationale = false;

      // only include the rationale that did not trigger the includeRationale to be reassigned to false
      //  in the filteredRationale array
      return includeRationale === true ? rationale : null;
    });

    // update the states - set the new insightsRationale so that we keep track
    // of the insightsRationale array prior to a user clicking into the data-insight-details-modal
    // because they can further filter the array within data-insight-details-modal
    setInsightsRationale(filteredRationale);
    // also set the new filteredInsightsRationale state - this will be the state/array that the user
    // can further filter and manipulate if they choose to enter the data-insight-details-modal
    // for this particular insight
    setFilteredInsightsRationale(filteredRationale);
  };

  // HANDLER FUNCTION
  // handle clicks on the 'More Details' button in each data-insight-card component
  const handleMoreDetailsBtnClick = (e) => {
    // set the showDataInsightDetailsModal state to true so that this modal renders
    setShowDataInsightDetailsModal(true);
    // set the selectedDataInsightId to the currentInsightId so that we render the
    // correct insight within the data-insight-details-modal component
    setSelectedDataInsightId(currentInsightId);
    // set the selectedDataInsightRespondentsTotal based on the current clinicianType
    // obj within insightsFilter
    setSelectedDataInsightRespondentsTotal(totalRespondents);
    // set the selectedDataInsightSortedDataItems and topDataItems state from result-data-page component
    // so that if/when user clicks on the 'More Details' button, the modal will have the
    // necessary data loaded
    setSelectedDataInsightSortedItems(updatedDataItems);
    setSelectedDataInsightTopItems(insightTopItems);
    // and update the insightsRationale state in result-data-page component so we are rendering
    // the correct rationale in the data-insight-details-modal component
    updateInsightsRationale();

    // finally, set closeModalScrollToElement state to the insight element we want to
    // scroll back to after the user closes the data-insight-details-modal that is about to
    // open after clicking 'More Details'
    const insightElementToScrollBackTo = e.target.closest(
      ".data-insight-container"
    );
    setCloseModalScrollToElement(insightElementToScrollBackTo);
  };

  // *****************************
  // ***DISPLAY INSIGHT FUNCTION***
  // *****************************
  // this function is called in the return statement below for this component
  // it is used to determine if we should render this data-insight-card component for this
  // data insight based on the currently active filters in the insightFilters obj in props
  // which is coming from the insightFilters state in result-data-page component
  const displayInsight = () => {
    // initialize an includeInsightTracker object to keep track of each of the
    // filters in insightsFilter obj from prop
    const includeInsightTracker = {
      drugOrDiseaseIdCheck: false,
      searchTermCheck: false,
      patientBuilderCheck: false,
    };
    // we are going to check each filter to determine if we should render
    // this data-insight-card component
    // initialize several consts to the current filters in the insightsFilter obj from props
    const currentSearchTerm = searchTerm.toLowerCase();
    const currentPatientBuilderAttributes = Object.keys(patientBuilder).filter(
      (attribute) => {
        return patientBuilder[attribute] === true ? attribute : null;
      }
    );

    // if the currentPatientBuilderAttributes array is empty, then we can set the patientBuilderCheck
    // property in includeInsightTracker to true
    if (currentPatientBuilderAttributes.length === 0)
      includeInsightTracker.patientBuilderCheck = true;

    // if there ARE elements in the currentPatientBuilderAttributes array,
    // then initialize an allPatientAttributesCheck variable to keep track of whether
    // the insight matches every active patient attribute
    if (currentPatientBuilderAttributes.length > 0) {
      let allPatientAttributesCheck = true;
      const patientAttributesObj = { ...insightObj.patientAttributes };
      // iterate through every active attribute in the currentPatientBuilderAttributes array
      currentPatientBuilderAttributes.forEach((attribute) => {
        // and for each attribute, check that the corresponding attribute in the patientAttributes obj in insightObj
        // is true, if it is NOT true, then reassign value of allPatientAttributesCheck to false
        if (patientAttributesObj[attribute] === false)
          allPatientAttributesCheck = false;
      });
      // if at the end the allPatientAttributesCheck is STILL true, then we can reassign the value of
      // patientBuilderCheck property in includeInsightTracker to true
      if (allPatientAttributesCheck === true)
        includeInsightTracker.patientBuilderCheck = true;
    }
    // initialize an array of all the drugOrDiseaseIds that are included in this insight
    const allDrugsOrDiseaseIdsInThisInsight = insightObj.data.map(
      (dataObj) => dataObj.item
    );
    // if the drugOrDiseaseId filter is empty, meaning the user did not add this filter
    // then set the drugOrDiseaseIdCheck property in the tracker to true
    if (drugOrDiseaseId === "")
      includeInsightTracker.drugOrDiseaseIdCheck = true;
    // if it is NOT empty, meaning the user did add this filter, then
    // iterate through all the drugsOrDiseaseInThisInsight to see if there is
    // at least 1 drug or disease that matches the current active drugOrDiseaseId filter
    // then we should set the drugOrDiseaseIdCheck property to true
    if (drugOrDiseaseId !== "") {
      allDrugsOrDiseaseIdsInThisInsight.forEach((id) => {
        if (id === drugOrDiseaseId)
          includeInsightTracker.drugOrDiseaseIdCheck = true;
      });
    }

    // if the currentSearchTerm filter is empty, meaning the user did not yet enter a search term
    // then set the searchTermCheck property in the tracker to true
    if (currentSearchTerm === "") {
      includeInsightTracker.searchTermCheck = true;
    }
    // if it is NOT empty, meaning the user did enter a search term
    if (currentSearchTerm !== "") {
      // initialize several const which represent that content of the insight that we need to check
      // for a match to the currentSearchTerm
      const fullInsight =
        `${insightObj.insight.insightIntro} ${insightObj.insight.insightEnding}`.toLowerCase();
      const insightName = insightObj.insightName.toLowerCase();
      const allDrugsOrDiseases = allDrugsOrDiseaseIdsInThisInsight.map((id) =>
        resultPageIdReferenceData[id].toLowerCase()
      );
      // now check to see if the currentSearchTerm string can be found in at least 1 of the above pieces of content
      if (fullInsight.includes(currentSearchTerm))
        includeInsightTracker.searchTermCheck = true;
      if (insightName.includes(currentSearchTerm))
        includeInsightTracker.searchTermCheck = true;
      allDrugsOrDiseases.forEach((drugOrDisease) => {
        if (drugOrDisease.includes(currentSearchTerm))
          includeInsightTracker.searchTermCheck = true;
      });
    }

    // if there are the same amount of 'trues' in the includeInsightTracker as
    // the amount of possible properties in the includeInsightTracker, meaning this insight
    // passed all of the checks against the active filters in insightsFilter obj from props above
    // then return true from this function, which will then trigger the rendering of this specific
    // data-insight-card component
    return Object.values(includeInsightTracker).filter(
      (property) => property === true
    ).length === Object.keys(includeInsightTracker).length
      ? true
      : false;
  };

  return displayInsight() === true ? (
    <div className="data-insight-container">
      <div className="data-insight-title">{insightObj.insightName}</div>
      <div className="data-insight-badges-and-buttons-container">
        <div className="data-insight-badges-container"></div>
        <div className="data-insight-ratings-container">
          <button
            className="data-insight-ratings-btn helpful-btn"
            type="button"
            onClick={(e) => {
              handleRatingBtnClick(e, "helpful");
            }}
          >
            <img
              className="data-insight-ratings-icon helpful-icon"
              src={require("../img/helpful-icon.png")}
              alt="helpful rating icon"
            ></img>
            <p
              className={
                insightsRatings[currentInsightId] === "helpful"
                  ? "data-insight-ratings-label clicked-label"
                  : "data-insight-ratings-label"
              }
            >
              Helpful!
            </p>
          </button>
          <button
            className="data-insight-ratings-btn not-helpful-btn"
            type="button"
            onClick={(e) => {
              handleRatingBtnClick(e, "notHelpful");
            }}
          >
            <img
              className="data-insight-ratings-icon not-helpful-icon"
              src={require("../img/not-helpful-icon.png")}
              alt="not helpful rating icon"
            ></img>
            <p
              className={
                insightsRatings[currentInsightId] === "notHelpful"
                  ? "data-insight-ratings-label clicked-label"
                  : "data-insight-ratings-label"
              }
            >
              Not helpful
            </p>
          </button>
        </div>
      </div>
      <div className="data-insight-summary">
        Your peers {insightObj.insight.insightIntro}{" "}
        <span className="data-insight-summary-top-items">
          {insightTopItems.map((item, index) => {
            if (
              index !== insightTopItems.length - 1 &&
              insightTopItems.length > 2
            )
              return `${item}, `;
            if (
              index !== insightTopItems.length - 1 &&
              insightTopItems.length <= 2
            )
              return `${item} `;

            if (
              index === insightTopItems.length - 1 &&
              insightTopItems.length === 1
            )
              return `${item}`;
            return `and ${item}`;
          })}
        </span>{" "}
        {insightObj.insight.insightEnding}
      </div>
      <div className="data-insight-horizontal-line"></div>
      <div className="data-insight-data-container">
        <div className="data-insight-drug-or-disease-link-demo-disclaimer disclaimer-hidden">
          In the future, this link will open up a dedicated page with all the
          insights related to this drug.
        </div>
        <p className="data-insight-data-description">
          {insightObj.dataDescription}
          <span className="data-insight-data-description-respondent-count-highlight">
            {"(n="}
            {totalRespondents}
            {")"}
          </span>
        </p>
        <div className="data-insight-data-visual-container">
          {/* If the style of the data presentation for this specific insight
        is 'circles', then iterate through the updatedDataItems and for each item.
        For each data item, we want to get the relevant rating (based on the insightsFilter) and 
        round down the rating and then create an array of full and empty circles to visually 
        represent the calculated rating  */}
          {insightObj.dataPresentation === "circles" ? (
            updatedDataItems.map((dataItem, index) => {
              const roundedTotalRespondentRating = Math.floor(
                dataItem.calculatedDataPointAvg
              );
              const fullCirclesArray = [];
              for (let i = 0; i < roundedTotalRespondentRating; i++) {
                fullCirclesArray.push("circle");
              }
              const emptyCirclesArray = [];
              for (let i = 0; i < 10 - roundedTotalRespondentRating; i++) {
                emptyCirclesArray.push("circle");
              }
              return (
                <div
                  key={dataItem.item + index}
                  className="data-insight-data-visual-row-circles"
                >
                  <Link
                    className="data-insight-data-label-circles"
                    // in future, we will route to the result-data-page associated
                    // with this drug or disease
                    to={`/demo/1PYFFA`}
                    // to=""
                    // onClick={() => {
                    //   setResultPageScrollTop(true);
                    // }}
                    onClick={handleDrugOrDiseaseLinkClick}
                  >
                    {resultPageIdReferenceData[dataItem.item]}
                  </Link>
                  <div
                    key={dataItem.item + index}
                    className="data-insight-data-visualization-circles"
                  >
                    {fullCirclesArray.map((circle, circleIndex) => (
                      <div
                        key={dataItem.item + circleIndex}
                        className="data-visualization-circle-full"
                      ></div>
                    ))}
                    {emptyCirclesArray.map((circle, circleIndex) => (
                      <div
                        key={dataItem.item + circleIndex}
                        className="data-visualization-circle-empty"
                      ></div>
                    ))}
                  </div>
                  {/* for each data row, we also want to create a label with the respective 
                    numerator and denominator for the rating that appears when the user
                    either hovers over or clicks on the data visualization - see the functionality
                    on the data-insight-data-visualization-circles above */}
                  <p
                    key={dataItem + index}
                    className="data-insight-data-visualization-number-label"
                  >
                    {/* {dataItem.calculatedDataPointAvg} /{" "}
                      {insightObj.dataDenominator} */}
                    {/* TEMPORARY FOR MVP */}
                    {dataItem.calculatedDataPointAvg * 10}
                    {"%"}
                  </p>
                </div>
              );
            })
          ) : (
            <div className="data-insight-data-number">
              {insightObj.dataNumber}
            </div>
          )}
        </div>
      </div>
      <div className="data-insight-more-details-container">
        <button
          className="data-insight-more-details-btn"
          type="button"
          onClick={handleMoreDetailsBtnClick}
        >
          More details
        </button>
      </div>
    </div>
  ) : null;
};

export default DataInsightCard;
