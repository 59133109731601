import React, { useState, useEffect } from "react";

import "../general.css";
import "./insights-filter-modal.component.css";

const InsightsFilterModal = (props) => {
  const {
    showInsightsFilterModal,
    setShowInsightsFilterModal,
    blurBackground,
    insightsFilter,
    nextInsightsFilter,
    setNextInsightsFilter,
    setInsightsFilter,
    selectedResultName,
    drugDiseaseObj,
    allDrugsOrDiseasesObj,
  } = props;

  // initialize a const to an array of all the drugs/disease ids that are data points
  // in the insights for this respective result-data-page component
  // to be used to create the alphabetized dropdown menu options for insights-filter-drug-or-disease-select
  // select element below
  const allDrugsOrDiseasesIds = Object.keys(allDrugsOrDiseasesObj).sort(
    (a, b) =>
      allDrugsOrDiseasesObj[a].name.localeCompare(allDrugsOrDiseasesObj[b].name)
  );

  // EFFECT
  // blur the background when this component mounts only if
  // the showInsightsFilterModal property in props is true
  useEffect(() => {
    if (showInsightsFilterModal === true) blurBackground("blurOn");
  }, [showInsightsFilterModal, blurBackground]);

  // HANLDER FUNCTION
  // to handle the close button of the insights-filter-modal
  // close the insights-filter-modal window
  // and remove the background blur
  const handleInsightsFilterModalClose = () => {
    setShowInsightsFilterModal(false);
    blurBackground("blurOff");
  };

  // TODAY'S DATE
  // set today's date and format as a string that we can pass
  // in to the insights-filter-end-date max property
  // const dateObj = new Date();
  // const todayDate = `${dateObj.getFullYear()}-${
  //   dateObj.getMonth() + 1 < 10
  //     ? `0${dateObj.getMonth() + 1}`
  //     : `${dateObj.getMonth() + 1}`
  // }-${dateObj.getDate()}`;

  // HANDLER FUNCTION
  // handle the changes in the date range filter input
  // const handleDateRangeChange = (e) => {
  //   // captue whether the date input is the beginning or end date input
  //   const dateInputType = e.target.id;
  //   // depending on whether it's the beginning or end date input that
  //   // the user is manipulating, update the min or max property, respectively,
  //   // of the other input

  //   // if the user manipulates the beginning date input
  //   if (dateInputType === "insights-filter-beginning-date") {
  //     const endDateInput = document.querySelector("#insights-filter-end-date");
  //     // update the minimum value of the end date to the new beginning date
  //     endDateInput.min = e.target.value;
  //     // capture the previous end date value
  //     const prevEndDate = nextInsightsFilter.date.end;
  //     // update the nextInsightsFilter state with the new beginning date
  //     setNextInsightsFilter({
  //       ...prevInsightsFilter,
  //       date: { beginning: e.target.value, end: prevEndDate },
  //     });
  //   }
  //   // if the user manipulates the end date input
  //   if (dateInputType === "insights-filter-end-date") {
  //     const beginningDateInput = document.querySelector(
  //       "#insights-filter-beginning-date"
  //     );
  //     // update the max value of the beginning date input
  //     beginningDateInput.max = e.target.value;
  //     // captue the previous beginning date value
  //     const prevBeginningDate = nextInsightsFilter.date.beginning;
  //     // update the nextInsightsFilter state with the new end date
  //     setNextInsightsFilter({
  //       ...prevInsightsFilter,
  //       date: { beginning: prevBeginningDate, end: e.target.value },
  //     });
  //   }
  // };

  // HANDLER FUNCTION
  // handle the changes in the drug-or-disease-select dropdown menu
  const handleDrugOrDiseaseSelect = (e) => {
    setNextInsightsFilter((prevInsightsFilter) => {
      const newInsightsFilter = { ...prevInsightsFilter };
      newInsightsFilter.drugOrDiseaseId = e.target.value;
      return newInsightsFilter;
    });
  };

  // ******************************
  // *** SHOW CLINICIAN TYPE WARNING STATE***
  // ******************************
  const [showClinicianTypeWarning, setShowClinicianTypeWarning] =
    useState(false);

  // HANDLER FUNCTION
  // handle the changes in the clinician-type checkbox inputs
  const handleClinicanTypeCheckbox = (e) => {
    // initialize a const to the value coming from the checkbox
    const selectedClinicianType = e.target.value;
    // check the current insightsFilter to see if the current filter value
    // for the selected clinicianType is true or false and setNextInsightsFilter
    // accordingly
    if (nextInsightsFilter.clinicianType[selectedClinicianType] === false) {
      setNextInsightsFilter((prevNextInsightsFilter) => {
        // make a shallow copy of the previous nextInsightsFilter state
        const newNextInsightsFilter = { ...prevNextInsightsFilter };
        // then for the selected clinicianType for this checkmark, reassign the value to true
        newNextInsightsFilter.clinicianType[selectedClinicianType] = true;
        // return the newNextInsightsFilter out of this callback to
        // set the nextInsightsFilter state in result-data-page component to this obj
        return newNextInsightsFilter;
      });
    }
    // and take the same respective actions if the current filter value is true
    if (nextInsightsFilter.clinicianType[selectedClinicianType] === true) {
      setNextInsightsFilter((prevNextInsightsFilter) => {
        const newNextInsightsFilter = { ...prevNextInsightsFilter };
        newNextInsightsFilter.clinicianType[selectedClinicianType] = false;
        return newNextInsightsFilter;
      });
    }
    // if the showClinicianTypeWarning is true, then we know that ALL the clinicianType checkboxes
    // were just empty
    if (showClinicianTypeWarning === true) {
      // in this case, we know that a click on any checkbox now means that there is at least 1
      // clinicanType about to be included in the insightsFilter as soon as the user presses the
      // insights-filter-submit-btn, so we can now set showClinicianTypeWarning back to false
      setShowClinicianTypeWarning(false);
    }
  };

  // HANDLER FUNCTION
  // to handle clicks on the insights-filter-submit-btn
  // this function will update the insightsFilter state in result-data-page component
  // to include the new values inputted by the user in the insights-filter-modal component
  const handleFilterInsightsBtnClick = () => {
    setInsightsFilter((prevInsightsFilter) => {
      // create a shallow copy of the previous insightsFilter state
      // which will be the newInsightsFilter obj which will become the new
      // insightsFilter state
      const newInsightsFilter = { ...prevInsightsFilter };
      // utilize Object.assign to copy the relevant updated properties to the
      // newInsightsFilter obj
      Object.assign(newInsightsFilter, nextInsightsFilter);
      // return the newInsightsFilter out of this callback so that we
      // set the insightsFilter state in result-date-page component to this
      // new obj
      return newInsightsFilter;
    });

    // now, check if the user has unchecked ALL of the clinician types, eg all clinicanType properties
    // in the recently updated insightsFilter all have values of 'false'
    // (we need to have at least 1 clinician type included in the filters, otherwise no data will appear)
    // if this is the case, then return out of the function so that pressing the insights-filter-submit-btn
    // does nothing
    const nextActiveClinicianFilters = Object.values(
      insightsFilter.clinicianType
    ).filter((filterBoolean) => {
      return filterBoolean === true ? filterBoolean : null;
    });

    if (nextActiveClinicianFilters.length === 0) {
      setShowClinicianTypeWarning(true);
      return;
    }
    // finally, if the last clinicanType check did not return us out of hte function, we can
    // close the modal and remove the background blur
    handleInsightsFilterModalClose();
  };

  return showInsightsFilterModal === true ? (
    <div className="insights-filter-modal-container">
      <button
        className="insights-filter-modal-close-btn"
        type="button"
        onClick={handleInsightsFilterModalClose}
      >
        <img
          className="insights-filter-modal-close-btn-icon"
          src={require("../img/close.png")}
          alt="modal close button"
        />
      </button>
      <h1 className="insights-filter-modal-header">
        Filter{" "}
        <span className="insights-filter-modal-header-page-name">
          {selectedResultName
            .split(" ")
            .map((word) => word[0].toUpperCase() + word.slice(1))
            .join(" ")}
        </span>{" "}
        Data and Insights
      </h1>
      <h2 className="insights-filter-modal-subhead">
        Only show me data and insights that match these parameters:
      </h2>
      <div className="insights-filter-modal-all-filters-container grid grid--2-cols">
        {/* COMMENTING OUT THE DATE FILTERS UNTIL WE CAN IMPLEMENT FUNCTIONALITY
        OF  */}
        {/* <div className="insights-filter-modal-filter-container">
          <h2 className="insights-filter-modal-filter-name">Date</h2>
          <div className="insights-filter-modal-filter-inputs">
            From{" "}
            <input
              type={"date"}
              id="insights-filter-beginning-date"
              max={""}
              onChange={handleDateRangeChange}
            />{" "}
            to{" "}
            <input
              type={"date"}
              id="insights-filter-end-date"
              min={""}
              max={todayDate}
              onChange={handleDateRangeChange}
            />
          </div>
        </div> */}
        <div className="insights-filter-modal-filter-container">
          <h3 className="insights-filter-modal-filter-name">
            {drugDiseaseObj.type === "disease" ? "Drug" : "Disease"}
          </h3>
          {/* <div className="insights-filter-modal-filter-inputs"> */}
          <select
            className="insights-filter-drug-or-disease-select"
            onChange={handleDrugOrDiseaseSelect}
            defaultValue={
              insightsFilter.drugOrDiseaseId !== ""
                ? insightsFilter.drugOrDiseaseId
                : ""
            }
          >
            <option
              className="insights-filter-drug-or-disease-select-option"
              value={""}
            >
              ALL
            </option>
            {/* List all the drug or disease filter options based on whether the
            result-data-page component is rendering a disease or drug page */}
            {allDrugsOrDiseasesIds
              .filter((drugOrDiseaseId) =>
                drugDiseaseObj.type === "disease"
                  ? allDrugsOrDiseasesObj[drugOrDiseaseId].type === "drug"
                  : allDrugsOrDiseasesObj[drugOrDiseaseId].type === "disease"
              )
              .map((drugOrDiseaseId) => {
                return (
                  <option
                    key={drugOrDiseaseId}
                    className="insights-filter-drug-or-disease-select-option"
                    value={drugOrDiseaseId}
                  >
                    {allDrugsOrDiseasesObj[drugOrDiseaseId].name}
                  </option>
                );
              })}
          </select>
          {/* </div> */}
        </div>
        <div className="insights-filter-modal-filter-container">
          <h3 className="insights-filter-modal-filter-name">
            Include data from these clinician types:
          </h3>
          {/* <div className="insights-filter-modal-filter-inputs"> */}
          <div className="insights-filter-clinicican-type-checkboxes-container">
            <div className="insights-filter-clinician-type-checkbox-row">
              <input
                className="insights-filter-clinician-type-checkbox"
                type={"checkbox"}
                value="physician"
                onChange={handleClinicanTypeCheckbox}
                defaultChecked={insightsFilter.clinicianType.physician === true}
              />
              <p className="insights-filter-clinician-type-label">Physicians</p>
            </div>
            <div className="insights-filter-clinician-type-checkbox-row">
              <input
                className="insights-filter-clinician-type-checkbox"
                type={"checkbox"}
                value="pharmacist"
                onChange={handleClinicanTypeCheckbox}
                defaultChecked={
                  insightsFilter.clinicianType.pharmacist === true
                }
              />
              <p className="insights-filter-clinician-type-label">
                Pharmacists
              </p>
            </div>
            <div className="insights-filter-clinician-type-checkbox-row">
              <input
                className="insights-filter-clinician-type-checkbox"
                type={"checkbox"}
                value="nursePractitioner"
                onChange={handleClinicanTypeCheckbox}
                defaultChecked={
                  insightsFilter.clinicianType.nursePractitioner === true
                }
              />
              <p className="insights-filter-clinician-type-label">
                Nurse Practitioners
              </p>
            </div>
            <div className="insights-filter-clinician-type-checkbox-row">
              <input
                className="insights-filter-clinician-type-checkbox"
                type={"checkbox"}
                value="physicianAssistant"
                onChange={handleClinicanTypeCheckbox}
                defaultChecked={
                  insightsFilter.clinicianType.physicianAssistant === true
                }
              />

              <p className="insights-filter-clinician-type-label">
                Physician Assistants
              </p>
            </div>
          </div>
        </div>
        {/* </div> */}
      </div>
      <p
        className={
          showClinicianTypeWarning === true
            ? "insights-filter-modal-clinican-types-filter-warning"
            : "insights-filter-modal-clinican-types-filter-warning modal-filter-warning-transparent"
        }
      >
        You must include at least 1 clinician type in your selected filters
      </p>
      <button
        className="insights-filter-submit-btn"
        type="button"
        onClick={handleFilterInsightsBtnClick}
      >
        Filter Insights
      </button>
    </div>
  ) : (
    ""
  );
};

export default InsightsFilterModal;
