import React, { useState, useEffect } from "react";
import { Link, Outlet } from "react-router-dom";

import GlobalNavSearchBar from "../global-nav-search-bar/global-nav-search-bar.component";
import Footer from "../footer/footer.component";

import "./nav.component.css";
import "../general.css";

const Nav = (props) => {
  const {
    searchTerm,
    updateSearchTerm,
    searchResults,
    globalNavSearchBarShown,
    navOpen,
    setNavOpen,
    blurBackground,
    showDisclaimerModal,
    setShowDisclaimerModal,
    availableSurveys,
    setScrollWaitList,
    setShowWaitListDemoBtn,
  } = props;

  // **************************************
  // SCROLL DEPTH STATE
  // **************************************
  // this state keeps track of the user's scroll depth
  // as part of the handleNavCloseAndLinkBtnClick functionality so that we can
  // return the user to the same position on the page they were viewing
  // before the clicked the nav/hamburger button
  const [scrollDepth, setScrollDepth] = useState(null);

  // EFFECT
  // if the scrollDepth state is not null,
  // then scroll the user down to the previous scrollDepth
  // that they were at prior to clicking on the nav/hamburger button
  useEffect(() => {
    if (scrollDepth !== null) {
      window.scroll(0, scrollDepth);
      return function () {
        setScrollDepth(null);
      };
    }
  });

  // HANDLER FUNCTION
  // handle clicks on the nav button / 'hamburger' menu icon
  // 2 main functions:
  const handleNavMenuClick = () => {
    // 1. capture the scroll depth so that when user closes the hidden-nav
    // we can scroll back to the point in the page that the user was
    // just viewing
    const scrollDepth = window.scrollY;
    // set the scrollDepth state to that scroll depth
    setScrollDepth(scrollDepth);

    // 2. Add the background blur using blurBackground function from App.js
    blurBackground("blurOn");
    // and set the navOpen state in App.js to true which will cause the hidden-nav
    // menu to slide in from the right upon re-render
    setNavOpen(true);
  };

  // HANDLER FUNCTION
  // handle clicks on the hidden-nav-close-btn
  const handleNavCloseAndLinkBtnClick = (e) => {
    // we want to check if this click came from either a nav-link or nav-text-link element
    // because if that's the case,
    const navLinkBtn =
      e.target.closest(".nav-link") !== null
        ? e.target.closest(".nav-link")
        : e.target.closest(".nav-text-link");
    // we want to make sure we set the scrollDepth to null
    // so that when the user arrives on the linked page, they will not get scrolled down
    // to the previous depth of the previous page
    if (navLinkBtn !== null) setScrollDepth(null);

    // remove the background blur
    blurBackground("blurOff");
    // and set the navOpen state in App.js to false
    setNavOpen(false);
  };

  // HANDLER FUNCTION
  // handle clicks on the disclaimer-modal-close-btn
  const handleDisclaimerModalCloseBtn = () => {
    // set the showDisclaimerModal state to false so that the
    // window will be hidden upon re-render
    setShowDisclaimerModal(false);
    // and then remove the background blur
    blurBackground("blurOff");
  };

  return (
    <div className="full-app-container">
      <div
        className={
          showDisclaimerModal === true
            ? "disclaimer-modal"
            : "disclaimer-modal disclaimer-modal-hidden"
        }
      >
        <button
          className="disclaimer-modal-close-btn"
          type="button"
          onClick={handleDisclaimerModalCloseBtn}
        >
          <img
            className="disclaimer-modal-close-btn-icon"
            src={require("../img/close.png")}
            alt="disclaimer modal close button"
          ></img>
        </button>
        <p className="disclaimer-modal-text">
          Welcome to the demo version of Orca! All content currently within the
          demo are examples of the data and insights our platform will generate
          when it is completed.
          <br />
          <br /> This demo is initially focused on Hepatitis C, but we will soon
          be expanding into other infectious diseases and other disease areas.
        </p>
      </div>
      <header className="app-header">
        <Link className="logo-lockup-link" to="">
          <img
            src={require("../img/orca-logo.png")}
            className="nav-logo"
            alt="Orca logo"
          />
        </Link>
        <GlobalNavSearchBar
          searchTerm={searchTerm}
          updateSearchTerm={updateSearchTerm}
          searchResults={searchResults}
          globalNavSearchBarShown={globalNavSearchBarShown}
          setScrollWaitList={setScrollWaitList}
          setShowWaitListDemoBtn={setShowWaitListDemoBtn}
        />
        <nav className="main-nav">
          <button
            className="nav-menu-btn"
            type="button"
            onClick={handleNavMenuClick}
          >
            <img
              src={require("../img/nav-hamburger-menu.png")}
              className="nav-menu-btn-icon"
              alt="hamburger menu button"
            />
          </button>
        </nav>
      </header>
      <nav className={navOpen === true ? "hidden-nav nav-open" : "hidden-nav"}>
        <button
          className="hidden-nav-close-btn"
          type="button"
          onClick={handleNavCloseAndLinkBtnClick}
        >
          <img
            className="hidden-nav-close-btn-icon"
            src={require("../img/close.png")}
            alt="menu close button"
          ></img>
        </button>
        <div className="nav-user-welcome-container">
          <h3 className="nav-user-welcome-message">Hi, Dr. Smith!</h3>
          <img
            className="nav-user-welcome-icon"
            src={require("../img/doctor-profile.png")}
            alt="doctor profile"
          ></img>
        </div>
        <div className="nav-all-sections-container">
          <div className="nav-trending-topics-container nav-section-container">
            <h3 className="nav-subhead">Trending Topics</h3>
            <div className="nav-trending-topics-links-container nav-section-links-container">
              <Link
                className="nav-link"
                to="/demo/1PYFFA"
                onClick={handleNavCloseAndLinkBtnClick}
              >
                <h4 className="nav-link-title">Hepatitis C</h4>
                <p className="nav-link-description">
                  Preferred DAA for HCV patients with decompensated cirrhosis
                </p>
              </Link>
              <Link
                className="nav-link"
                to="/demo/1PYFFA"
                onClick={handleNavCloseAndLinkBtnClick}
              >
                <h4 className="nav-link-title">Hepatitis C</h4>
                <p className="nav-link-description">
                  3rd-line treament after failing multiple DAA regimens
                </p>
              </Link>
            </div>
          </div>
          <div className="nav-survey-opportunities-container nav-section-container">
            <h3 className="nav-subhead">Survey Opportunities</h3>
            <div className="nav-surveys-links-container nav-section-links-container">
              <Link
                className="nav-link"
                to="/demo/surveys"
                onClick={handleNavCloseAndLinkBtnClick}
              >
                <h4 className="nav-link-title">Available Surveys</h4>
                <p className="nav-link-description">
                  You have {availableSurveys.length} surveys available to
                  complete!
                </p>
              </Link>
            </div>
          </div>
          <div className="nav-section-container">
            <Link
              className="nav-text-link"
              to={"/"}
              onClick={handleNavCloseAndLinkBtnClick}
            >
              Exit demo
            </Link>
          </div>
          {/* <div className="nav-section-container">
            <Link
              className="nav-text-link"
              to={"/contact"}
              onClick={handleNavCloseAndLinkBtnClick}
            >
              Contribute Data
            </Link>
          </div> */}
        </div>
      </nav>
      <main className="app-outlet-container">
        <Outlet />
      </main>
      <Footer />
    </div>
  );
};

export default Nav;
